export const formatMoney = (num, compact, length = 6) => {
    const formatter = new Intl.NumberFormat(
        "en-US",
        compact ? (`${num}`.length > length ? { notation: "compact" } : {}) : {}
    );
    return formatter.format(num);
};

export const getFee = (currency, feeObject = {}) => {
    const key = Object.keys(feeObject)?.find(
        (key) => key.startsWith(currency) && key.includes("TransferFee")
    );
    return feeObject[key];
};