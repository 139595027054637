import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import currencys from "../../asset/images/GB.svg";
import pay from "../../asset/images/Sendpay.svg";
import Loader from "../../component/loader";
import currencyPairCheck from "../../utilities/currencyPairCheck";
import { DEFAULT_RATE, SEND_MONEY_STEP_TWO, SHOW_COUNTRY_FLAG } from "constants/global";

const FxSendMoney = ({
    selectedCurrency,
    amount,
    setAmount,
    setshowModal,
    filterWaltetBalance,
    fee,
    loader,
    setSendMoneyStep,
    fail,
    convertedAmount,
    rate,
    loadingTransferFee,
}) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    return (
        <div className="my-12">
            <h2 className="mb-5 text-2xl font-medium text-main-dark">Send Money</h2>

            <div className="flex flex-col gap-8">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "100%",
                    }}
                >
                    <div
                        className=""
                        style={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "column",
                            borderRadius: "8px",
                            background: "#fff",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "8px 16px",
                            }}
                        >
                            <div
                                style={{
                                    color: "#667085",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "16.2px",
                                }}
                            >
                                SEND
                            </div>
                            <div
                                style={{
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    letterSpacing: "2%",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    color: "#101828",
                                    position: "relative",
                                }}
                            >
                                {" "}
                                <img
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                    }}
                                    src={SHOW_COUNTRY_FLAG[state?.default] || currencys}
                                    alt=""
                                />
                                {state?.default}
                            </div>
                        </div>
                        <div className="input_sign_code has-[:focus]:bg-[#EAECF0]">
                            <div className="relative">
                                <div className="flex items-center">
                                    <span className="mr-2 text-base balance_number text-gray-10">
                                        {currencyPairCheck(state?.default)}
                                    </span>

                                    <input
                                        type="number"
                                        className="w-full focus:bg-[#EAECF0] !shadow-none"
                                        placeholder="0"
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                        value={amount}
                                    />
                                </div>
                            </div>
                            <span className="balance_number">
                                Balance: {currencyPairCheck(state?.default)}
                                {parseFloat(filterWaltetBalance()?.[0]?.balance).toLocaleString()}
                            </span>
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            src={pay}
                            alt=""
                            height={40}
                            style={{
                                position: "absolute",
                                bottom: "0px",
                                top: "0px",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                            }}
                        />
                    </div>

                    <div className="flex flex-col items-center w-full gap-2 bg-white rounded-lg">
                        <div className="flex items-center justify-between w-full px-3 py-2 gap-x-2">
                            <div
                                style={{
                                    color: "#667085",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "16.2px",
                                }}
                            >
                                RECEIVE
                            </div>
                            <div
                                style={{
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    background: "#fff",
                                    letterSpacing: "2%",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    color: "#101828",
                                    position: "relative",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setshowModal(true);
                                }}
                            >
                                <img
                                    className="size-[20px]"
                                    src={SHOW_COUNTRY_FLAG[selectedCurrency] || currencys}
                                    alt="currency country flag"
                                />
                                {selectedCurrency}
                                <span
                                    className="material-icons"
                                    style={{
                                        color: "#475467",
                                    }}
                                >
                                    expand_more
                                </span>
                            </div>
                        </div>
                        <div className="w-full p-3 bg-white input_sign_code">
                            <div className="relative">
                                <div type="number" className="input_form_number">
                                    <span className="mr-2 text-base text-gray-10">
                                        {currencyPairCheck(selectedCurrency)}
                                    </span>
                                    {convertedAmount}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        padding: "16px",
                        borderRadius: "8px",
                        background: "#fff",
                    }}
                >
                    <div className="flex items-center justify-between w-full gap-2">
                        <div className="flex items-center gap-5">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "19.6px",
                                        fontWeight: "500",
                                        letterSpacing: "-1%",
                                        color: "#667085",
                                    }}
                                >
                                    Fee
                                </span>

                                <span
                                    style={{
                                        color: "#344054",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {/* {currencyPairCheck(selectedCurrency)}
                                    {parseFloat(amount * parseFloat(fee)).toFixed(3)} */}
                                    {loadingTransferFee ? "Loading..." : fee}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "19.6px",
                                        fontWeight: "500",
                                        letterSpacing: "-1%",
                                        color: "#667085",
                                    }}
                                >
                                    Rate
                                </span>

                                <span
                                    style={{
                                        color: "#344054",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {currencyPairCheck(state?.default)}1 ={" "}
                                    {currencyPairCheck(selectedCurrency)}
                                    {/* {parseFloat(
                                        state?.default === pairs.baseCurrency
                                            ? pairs.quoteCurrencyRate
                                            : pairs.baseCurrencyRate
                                    ) === NaN
                                        ? 0
                                        : parseFloat(
                                              state?.default === pairs.baseCurrency
                                                  ? pairs.quoteCurrencyRate
                                                  : pairs.baseCurrencyRate
                                          ).toFixed(6)} */}
                                    {rate || rate === 0 ? rate.toFixed(6) : DEFAULT_RATE}
                                </span>
                            </div>{" "}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    lineHeight: "19.6px",
                                    fontWeight: "500",
                                    letterSpacing: "-1%",
                                    color: "#667085",
                                }}
                            >
                                Arrive
                            </span>

                            <span
                                style={{
                                    color: "#344054",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                }}
                            >
                                In Minutes
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={() => {
                            navigate(-1);
                        }}
                        className={`flex px-[24px] py-[12px] h-[40px] mr-2 text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-[#1D2939]`}
                    >
                        Back
                    </button>

                    <button
                        disabled={fail}
                        className={`flex px-[24px] py-[12px] h-[40px] text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-white
                              ${fail ? "!bg-[#EBE4FF]" : "!bg-[#6F00FF]"}
                            `}
                        onClick={() => setSendMoneyStep(SEND_MONEY_STEP_TWO)}
                        // onClick={() => goHere()}
                        type="button"
                    >
                        {loader === true ? <Loader data={loader} /> : "Continue"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FxSendMoney;
