import { AddIcon, CheckCircleIcon, CheckIcon, CloseIcon, LinkIcon } from "asset/icons";
import CurrencyToggle from "component/currencyToggle";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { formatMoney } from "utilities/formatMoney";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loader from "component/loader";
import SupportDocs from "component/form/supportDocs";
import AssignSupplier from "./component/assignSupplier";
import OrderItemRow from "./component/orderItemRow";
import {
    BuyerSuccessModal,
    CreateLinkSuccessModal,
    ExternalSuccessModal,
} from "./component/successModals";
import Toggler from "component/toggler";
import Preview from "./preview";
import { Margin, usePDF } from "react-to-pdf";
import { errorAlert } from "utilities/alerts";
import currencyPairCheck from "utilities/currencyPairCheck";
import Dropdown from "component/form/dropDown";
import useGetRequest from "hooks/useGetRequest";
import Loading from "component/loading";
import Input from "component/form/Input";
import { businessToUse } from "flex/utils";

const CreatePurchaseOrder = () => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const typesTitle = { inbound: "buyer", outbound: "supplier" };
    const searchParamsOrderId = searchParams.get("orderId");
    const editOrder = searchParams.get("edit");
    const [fetchOrder, order, orderLoading] = useGetRequest(searchParamsOrderId ?? false);
    const { poId } = useParams();
    const { business, currency: _selectedCurrency, users: user } = useSelector((state) => state);
    const selectedCurrency = searchParamsOrderId ? order?.currency : _selectedCurrency;
    const [previewPO, setPreviewPO] = useState(false);
    const [createLinkId, setCreateLinkId] = useState();
    const [createLink, setCreateLink] = useState(true);
    const [type, setType] = useState(typesTitle[tab] ?? "buyer");
    const [allowTax, setAllowTax] = useState(true);
    const [selectedSupplier, setSelectedSupplier] = useState();
    const { toPDF, previewRef } = usePDF({
        page: { margin: Margin.LARGE },
        filename: `Purchase-Order-${business.name}`,
    });
    const [addDocs, setAddDocs] = useState(false);
    const [createOrderLoading, setCreateOrderLoading] = useState(false);
    const [buyerSuccess, setBuyerSuccess] = useState(false);
    const [externalOrderSuccess, setExternalOrderSuccess] = useState(false);
    const [page, setPage] = useState(poId || searchParamsOrderId ? 1 : 0);
    const [file, setFile] = useState();
    const [conversionRate, setConversionRate] = useState(0);
    const continueBtnRef = useRef();
    const submitBtnRef = useRef();
    const previewParentRef = useRef();
    const defaultOrderItem = {
        itemName: "",
        quantity: 0,
        pricePerUnit: 0,
        id: Date.now() + "",
    };
    const [orderItems, setOrderItems] = useState([{ ...defaultOrderItem }]);
    const [paymentMethod, setPaymentMethod] = useState();
    const [paymentTerms, setPaymentTerms] = useState("");
    const [splitPaymentPercentage, setSplitPaymentPercentage] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState();
    const [note, setNote] = useState("");
    const currency = currencyPairCheck(selectedCurrency);
    const dispatch = useDispatch();

    const subtotal =
        orderItems.reduce((prev, curr) => prev + curr.pricePerUnit * curr.quantity, 0) || 0;
    const surCharge = subtotal * 0.01;
    const addTax = useMemo(
        () =>
            allowTax
                ? Math.min(
                      subtotal * 0.075,
                      selectedCurrency === "NGN" ? 10000 : 10000 / conversionRate
                  )
                : 0,
        [selectedCurrency, conversionRate, subtotal, allowTax]
    );
    const total = subtotal + surCharge + addTax;

    const fetchConversionRate = async () => {
        try {
            const res = await axios.post("/v1/wallets/currency/single_pair", {
                baseCurrency: "NGN",
                quoteCurrency: selectedCurrency,
            });
            setConversionRate(res.data.data.baseCurrencyRate);
        } catch (error) {
            console.log(error?.message);
        }
    };

    const allowPaymentMethod = type !== "supplier" && !poId;
    const splitTerm = `${splitPaymentPercentage[0]}% on confirmation, ${splitPaymentPercentage[1]}% on completion`;
    const formData = {
        deliveryDate,
        paymentMethod: allowPaymentMethod ? paymentMethod : "",
        paymentTerms: paymentTerms.startsWith("Split") ? splitTerm : paymentTerms,
        currency: selectedCurrency,
        note,
        orderItems: orderItems.map(({ id, ...rest }) => rest),
        paymentOne: `${splitPaymentPercentage[0] || 0}`,
        paymentTwo: `${splitPaymentPercentage[1] || 0}`,
        surCharge,
        taxAmount: addTax,
        tax: allowTax,
    };

    const createInternalOrder = async () => {
        try {
            setCreateOrderLoading(true);
            const reqData = {
                ...formData,
                flexUser: selectedSupplier._id,
                flexUserType: type === "buyer" ? "supplier" : "buyer",
            };
            const poRequest = await axios.post(
                `/v1/flex/order`,
                editOrder ? { ...reqData, orderId: searchParamsOrderId } : reqData
            );

            if (addDocs) {
                const formData = new FormData();
                formData.append("file", file, file.name);
                formData.append("orderId", poRequest.data.data._id);
                await axios.post(`/v1/flex/order/purchaseOrder`, formData);
            }
            setBuyerSuccess(true);
        } catch (error) {
            errorAlert(error);
        } finally {
            setCreateOrderLoading(false);
        }
    };
    const createLinkOrder = async () => {
        try {
            setCreateOrderLoading(true);
            const reqData = {
                flexUser: selectedSupplier._id,
                flexUserType: type,
            };
            const poRequest = await axios.post(`/v1/flex/order`, {
                ...reqData,
                orderId: searchParamsOrderId,
            });
            setCreateLinkId(poRequest.data.data._id);
        } catch (error) {
            errorAlert(error);
        } finally {
            setCreateOrderLoading(false);
        }
    };

    const createExternalOrder = async () => {
        try {
            setCreateOrderLoading(true);
            await axios.post(`/v1/flex/order/buyer`, {
                headers: {
                    "x-business-id": business?._id,
                },
                body: { ...formData, orderId: poId },
            });
            setExternalOrderSuccess(true);
        } catch (error) {
            errorAlert(error);
        } finally {
            setCreateOrderLoading(false);
        }
    };

    const buyerSection = page && type === "buyer";
    const supplierSection = page && type === "supplier";
    const hideForm = createLink && supplierSection;

    const createOrder = (evt) => {
        evt.preventDefault();
        if (poId) return createExternalOrder();
        if (hideForm) return createLinkOrder();
        createInternalOrder();
    };

    const updateListItem = (id, key, value) => {
        const findItem = (item) => item.id === id;
        setOrderItems((prev) => {
            const oldArr = [...prev];
            const newItem = { ...oldArr.find(findItem), [key]: value };
            const oldItemIdx = oldArr.findIndex(findItem);
            oldArr[oldItemIdx] = newItem;
            return oldArr;
        });
    };

    const deleteListItem = (id) => {
        setOrderItems((prev) => {
            const oldArr = [...prev];
            const itemIdx = oldArr.findIndex((item) => item.id === id);
            oldArr.splice(itemIdx, 1);
            return oldArr;
        });
    };

    const handleNavBtnClick = () => {
        if (!page) return continueBtnRef.current?.click();
        if (previewPO) return setPreviewPO(false);
        submitBtnRef.current?.click();
    };

    const updatePaymentTermsPercentage = (idx, val) => {
        setSplitPaymentPercentage((prv) => {
            const prev = [...prv];
            prev[idx] = val;
            return prev;
        });
    };

    useEffect(() => {
        searchParamsOrderId && business?._id && fetchOrder(`/v1/flex/order/${searchParamsOrderId}`);
    }, [business?._id, searchParamsOrderId]);

    useEffect(() => {
        if (order) {
            const bizInfo = businessToUse(order, business, tab);
            dispatch({ type: "CURRENCY", payload: order.currency });
            localStorage.setItem("default", order.currency);
            setSelectedSupplier({
                name: bizInfo.bizName,
                _id: bizInfo.id,
                personalName: bizInfo.name,
            });
            setDeliveryDate(order.deliveryDate);
            setPaymentMethod(order.paymentMethod);
            setPaymentTerms(order.paymentTerms);
            setSplitPaymentPercentage([order?.paymentOne, order?.paymentTwo]);
            setAllowTax(order?.tax ?? true);
            setOrderItems(order.orderItems.map((item) => ({ id: crypto.randomUUID(), ...item })));
            setNote(order?.note);
            if (order?.document?.length) {
                setAddDocs(true);
                setFile(order.document[0]);
            }
        }
    }, [order, business, tab]);

    useEffect(() => {
        page && selectedCurrency !== "NGN" && fetchConversionRate();
    }, [selectedCurrency, page]);

    console.log(order, "the order");

    return (
        <>
            {orderLoading && (
                <div className="fixed left-0 top-0 h-screen w-screen z-[1000000] opacity-65">
                    <Loading />
                </div>
            )}

            <div className="bg-page-bg">
                <nav className="sticky top-0 z-40 bg-inherit max-md:bg-white h-20 !m-0 !px-6 md:!px-8 !py-0 flex items-center navbar-gradient">
                    {!poId && (
                        <Link to={-1} className="flex items-center">
                            <CloseIcon className="*:stroke-main-grey size-[25px] mr-2 -translate-y-[3px]" />
                            <h2 className="text-base font-medium text-main-dark">
                                Create purchase order
                            </h2>
                        </Link>
                    )}
                    <button
                        className="!bg-secondary text-[#FBFAFF] rounded-lg px-6 !py-2.5 font-[450] ml-auto max-md:hidden"
                        onClick={handleNavBtnClick}
                        disabled={orderLoading}
                    >
                        {editOrder
                            ? "Save changes"
                            : page
                            ? hideForm
                                ? "Finish"
                                : "Create order"
                            : "Continue"}
                    </button>
                </nav>

                <div
                    className={`${
                        type ? "pl-6 md:pl-9 xl:pl-[6.3rem]" : "px-6 md:px-9 xl:px-[6.3rem]"
                    } !mt-5 md:mt-10 flex ${orderLoading && "!pointer-events-none"}`}
                >
                    {!poId && (
                        <div className="hidden md:flex gap-4 min-w-[177px]">
                            <div className="flex flex-col items-center">
                                <span className="!bg-secondary rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                                    1
                                </span>
                                <span className="!bg-[#C0CAD8] w-[2px] h-5" />
                                <span className="!bg-[#98A2B3] rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                                    2
                                </span>
                            </div>
                            <div className="flex flex-col !gap-5">
                                <p className={`font-medium !text-main`}>Choose your role</p>
                                <p className={`font-medium !text-secondary`}>Fill order details</p>
                            </div>
                        </div>
                    )}

                    {!page ? (
                        <div className="!pt-[11px] w-full md:w-[450px] 820:w-[500px] 950:w-[594px] max-md:pr-6 md:ml-[10vw] 1200:ml-[15vw] md:mr-auto flex flex-col">
                            <h3 className="font-[450] text-2xl text-main-dark">Choose your role</h3>
                            <p className="text-sm text-main-grey">Are you buying or selling?</p>
                            {[
                                {
                                    title: `I’m the buyer`,
                                    text: `I want to purchase goods from a supplier`,
                                    type: "buyer",
                                },
                                {
                                    title: `I’m the supplier`,
                                    text: `I want to sell goods to a customer`,
                                    type: "supplier",
                                },
                            ].map(({ text, title, type: _type }, idx) => (
                                <div
                                    className={`flex gap-2.5 rounded-lg bg-white !p-6 cursor-pointer !border transition-colors duration-200 ${
                                        idx ? "!mb-8" : "!mt-8 !mb-6 "
                                    } ${type === _type ? "!border-secondary" : "!border-white"}`}
                                    key={text}
                                    onClick={() => setType(_type)}
                                >
                                    {type === _type ? (
                                        <CheckCircleIcon className="translate-y-1 size-5" />
                                    ) : (
                                        <CheckCircleIcon className="size-5 translate-y-1 *:fill-[#DBDFE5]" />
                                    )}
                                    <p
                                        className={`font-[450] !text-lg leading-6 ${
                                            type === _type ? "text-main-dark" : "text-main-grey"
                                        }`}
                                    >
                                        {title}
                                        <span className="block text-sm font-normal text-main-grey">
                                            {text}
                                        </span>
                                    </p>
                                </div>
                            ))}

                            <button
                                className="!bg-secondary text-[#FBFAFF] rounded-lg !px-6 !py-[11px] md:!py-[9px] font-[450] ml-auto"
                                onClick={() => setPage(1)}
                                ref={continueBtnRef}
                            >
                                Continue
                            </button>
                        </div>
                    ) : (
                        <>
                            <div
                                className={`flex flex-1 !pt-[11px] ${
                                    createOrderLoading && "pointer-events-none"
                                }`}
                            >
                                <form
                                    onSubmit={createOrder}
                                    className={`w-full 950:w-[594px] ${
                                        poId
                                            ? "ml-auto mr-[86px]"
                                            : "max-md:ml-0 max-900:ml-[7vw] max-xl:ml-[10vw] xl:ml-[6vw] 1400:ml-[12vw] !mr-6 xl:!mr-8"
                                    }`}
                                >
                                    <h3 className="font-[450] text-2xl text-main-dark">
                                        Fill order details
                                    </h3>
                                    <p className="text-sm text-main-grey">
                                        Provide details of this purchase order
                                    </p>

                                    {supplierSection && (
                                        <div className="bg-[#F2F4F7] h-9 grid grid-cols-2 !mb-5 !mt-14 rounded-lg !p-1 !gap-2 *:font-medium *:text-sm">
                                            {["Create with link", "Create manually"].map(
                                                (text, idx) => (
                                                    <button
                                                        className={` ${
                                                            !!idx === createLink
                                                                ? "text-main-grey"
                                                                : "text-main-dark bg-white shadow-[0px_0px_8px_0px_#6F00FF0A,0px_0px_7px_0px_#1414140D]"
                                                        }`}
                                                        type="button"
                                                        onClick={() => setCreateLink(!idx)}
                                                        key={text}
                                                    >
                                                        {text}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    )}

                                    {!hideForm && (
                                        <CurrencyToggler
                                            businessId={business?._id}
                                            className={`xl:hidden !mb-5 md:!mb-4 !mt-7 md:!mt-10`}
                                        />
                                    )}

                                    <div
                                        className={`relative h-max ${
                                            buyerSection ? "xl:mt-12" : "xl:!mt-[21px]"
                                        }`}
                                        ref={previewParentRef}
                                    >
                                        {!poId && (
                                            <AssignSupplier
                                                business={business}
                                                selectedSupplier={selectedSupplier}
                                                setSelectedSupplier={setSelectedSupplier}
                                                type={type}
                                            />
                                        )}

                                        {hideForm ? (
                                            <div className="bg-[#DCFDDB] rounded-lg !p-4 flex items-center gap-2.5 !border !border-[#11A811] !mt-6">
                                                <LinkIcon />
                                                <p className="text-main-dark font-[450]">
                                                    We’ll create a link for your buyer to complete
                                                    <span className="block text-sm font-normal text-main-grey">
                                                        They can fill out and send you the purchase
                                                        order based your agreed terms
                                                    </span>
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                <Input
                                                    type="date"
                                                    placeholder="Due date"
                                                    className="!text-base !my-6"
                                                    value={deliveryDate}
                                                    onChange={setDeliveryDate}
                                                    required
                                                />

                                                {allowPaymentMethod && (
                                                    <Dropdown
                                                        value={paymentMethod}
                                                        label="Payment method"
                                                        placeholder="Select payment method"
                                                        options={[
                                                            "Automatic debit",
                                                            "Manual payment",
                                                        ].map((method) => ({
                                                            label: method,
                                                            value: method,
                                                        }))}
                                                        onChange={(value) =>
                                                            setPaymentMethod(value)
                                                        }
                                                    />
                                                )}

                                                <Dropdown
                                                    value={paymentTerms}
                                                    label="Payment terms"
                                                    placeholder="Select payment terms"
                                                    options={[
                                                        "Split payment",
                                                        "Full payment on completion",
                                                    ].map((term) => ({
                                                        label: term,
                                                        value: term,
                                                    }))}
                                                    onChange={(value) => setPaymentTerms(value)}
                                                />

                                                {paymentTerms.startsWith("Split") && (
                                                    <div className="bg-[#F6F8FA] !p-4 flex gap-4 relative">
                                                        <PercentageInput
                                                            percent={splitPaymentPercentage[0]}
                                                            setPercent={(val) =>
                                                                updatePaymentTermsPercentage(0, val)
                                                            }
                                                            max={
                                                                100 -
                                                                (splitPaymentPercentage[1] ?? 0)
                                                            }
                                                            placeholder={"confirmation"}
                                                        />
                                                        <PercentageInput
                                                            percent={splitPaymentPercentage[1]}
                                                            setPercent={(val) =>
                                                                updatePaymentTermsPercentage(1, val)
                                                            }
                                                            max={
                                                                100 -
                                                                (splitPaymentPercentage[0] ?? 0)
                                                            }
                                                            placeholder={"completion"}
                                                        />

                                                        {splitPaymentPercentage[0] +
                                                            splitPaymentPercentage[1] !==
                                                            100 && (
                                                            <input
                                                                className="absolute bottom-0 opacity-0 pointer-events-none left-4"
                                                                pattern="100"
                                                                defaultValue={20}
                                                                title="Payment term must total exactly 100"
                                                                required
                                                            />
                                                        )}
                                                    </div>
                                                )}

                                                {orderItems.map(
                                                    (
                                                        { itemName, pricePerUnit, quantity, id },
                                                        idx
                                                    ) => (
                                                        <OrderItemRow
                                                            itemName={itemName}
                                                            pricePerUnit={pricePerUnit}
                                                            quantity={quantity}
                                                            id={id}
                                                            updateItem={updateListItem}
                                                            key={id}
                                                            deleteItem={() => deleteListItem(id)}
                                                            currency={currencyPairCheck(
                                                                selectedCurrency
                                                            )}
                                                            showDelete={!!idx && !previewPO}
                                                        />
                                                    )
                                                )}

                                                <div className="flex items-center !my-6 font-[450] *:cursor-pointer">
                                                    <span
                                                        className="flex items-center gap-2 !text-secondary"
                                                        onClick={() =>
                                                            setOrderItems((prev) => [
                                                                ...prev,
                                                                {
                                                                    ...defaultOrderItem,
                                                                    id: Date.now() + "",
                                                                },
                                                            ])
                                                        }
                                                    >
                                                        <AddIcon /> Add another item
                                                    </span>
                                                    {/* <span className="text-[#1D2939] !ml-5 !mr-2">
                                                        Upload items from file
                                                    </span>
                                                    <HelpCircleIcon /> */}
                                                </div>

                                                <>
                                                    <div className="border-b-[1.3px] border-dashed !mb-4" />

                                                    <p className="flex items-center !gap-4 text-main-grey justify-end">
                                                        Subtotal:{" "}
                                                        <span className="text-main-dark font-[450]">
                                                            {currency}{" "}
                                                            {formatMoney(subtotal.toFixed(2))}
                                                        </span>
                                                    </p>
                                                    <p className="flex items-center text-main-grey justify-end !my-4">
                                                        Surcharge:{" "}
                                                        <span className="text-main-dark font-[450] !ml-4">
                                                            {currency}{" "}
                                                            {formatMoney(surCharge.toFixed(2))}
                                                        </span>
                                                        {/* <InfoCircleIcon className="!ml-2" /> */}
                                                    </p>
                                                    <p className="flex items-center !gap-4 text-main-grey justify-end">
                                                        <div
                                                            className={`!size-4 cursor-pointer rounded bg-white !border !translate-x-2 ${
                                                                allowTax &&
                                                                "!border-secondary grid place-items-center"
                                                            }`}
                                                            onClick={() =>
                                                                setAllowTax((prev) => !prev)
                                                            }
                                                        >
                                                            {allowTax && <CheckIcon />}
                                                        </div>
                                                        Tax (7.5%):{" "}
                                                        <span className="text-main-dark font-[450]">
                                                            {currency}{" "}
                                                            {formatMoney(addTax.toFixed(2))}
                                                        </span>
                                                    </p>

                                                    <div className="border-b-[1.3px] border-dashed !my-[18px]" />

                                                    <p className="flex items-center font-[450] !text-lg !gap-4 text-main-grey justify-end">
                                                        Total:{" "}
                                                        <span className="text-main-dark">
                                                            {currency}{" "}
                                                            {formatMoney(total.toFixed(2))}
                                                        </span>
                                                    </p>
                                                </>

                                                <SupportDocs
                                                    addDocs={addDocs}
                                                    setAddDocs={setAddDocs}
                                                    file={file}
                                                    setFile={setFile}
                                                    showDelete={!previewPO}
                                                />

                                                <>
                                                    <p className="font-medium text-sm text-main-dark !mb-2 !mt-5">
                                                        Notes
                                                    </p>
                                                    <textarea
                                                        value={note}
                                                        className="bg-[#ECEFF3] h-[83px] resize-none rounded-lg w-full !py-2 !px-4 placeholder:font-normal placeholder:text-sm placeholder:text-main-grey !shadow-[inset_0_0_0_1000px_#ECEFF3]"
                                                        placeholder="Add a note..."
                                                        onChange={(evt) =>
                                                            setNote(evt.target.value)
                                                        }
                                                    />
                                                </>

                                                <Preview
                                                    currency={currency}
                                                    addTax={addTax.toFixed(2)}
                                                    dueDate={dayjs(deliveryDate).format(
                                                        "MMM D, YYYY"
                                                    )}
                                                    note={note}
                                                    orderItems={orderItems}
                                                    paymentTerms={paymentTerms}
                                                    splitTerm={splitTerm.replace(/undefined/g, 0)}
                                                    subtotal={subtotal.toFixed(2)}
                                                    supplier={selectedSupplier}
                                                    surCharge={surCharge.toFixed(2)}
                                                    total={total.toFixed(2)}
                                                    user={{
                                                        bizName: business?.name,
                                                        bizImg:
                                                            business?.businessImage?.url ??
                                                            user?.profileImage?.url,
                                                        fullName: `${user?.firstName ?? ""} ${
                                                            user?.lastName ?? ""
                                                        }`,
                                                    }}
                                                    ref={previewRef}
                                                    preview={previewPO}
                                                />
                                            </>
                                        )}
                                    </div>

                                    {!hideForm && (
                                        <PreviewToggle
                                            previewPO={previewPO}
                                            setPreviewPO={setPreviewPO}
                                            className={`xl:hidden`}
                                        />
                                    )}
                                    <div
                                        className={`flex justify-end gap-4 h-[48px] *:font-[450] !mb-14 ${
                                            hideForm ? "!mt-7 sm:!mt-14" : "!mt-7 sm:!mt-10"
                                        }`}
                                        onClick={() => setPreviewPO(false)}
                                    >
                                        {!poId && (
                                            <button
                                                className="!py-2 !px-4 text-main-dark"
                                                type="button"
                                                onClick={() => setPage((prev) => prev - 1)}
                                            >
                                                Back
                                            </button>
                                        )}

                                        <button
                                            className="!bg-secondary !py-2 !px-6 text-[#FBFAFF] rounded-lg"
                                            ref={submitBtnRef}
                                            type={previewPO ? "button" : "submit"}
                                        >
                                            {createOrderLoading ? (
                                                <Loader />
                                            ) : editOrder ? (
                                                "Save changes"
                                            ) : hideForm ? (
                                                "Finish"
                                            ) : (
                                                "Create order"
                                            )}
                                        </button>
                                    </div>
                                </form>

                                {/* Right side */}
                                {!hideForm && (
                                    <div className="w-full max-xl:hidden md:w-[335px] !p-6">
                                        <CurrencyToggler businessId={business?._id} />
                                        <PreviewToggle
                                            previewPO={previewPO}
                                            setPreviewPO={setPreviewPO}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            <BuyerSuccessModal open={buyerSuccess} downloadPdf={() => toPDF()} type={type} />
            <CreateLinkSuccessModal poId={createLinkId} />
            <ExternalSuccessModal open={externalOrderSuccess} />
        </>
    );
};

const PercentageInput = ({ placeholder, percent, setPercent, max }) => (
    <div
        className={`h-[56px] bg-[#EAECF0] rounded-lg w-full !px-4 flex items-center font-medium text-[15px] ${
            percent ? "text-[#475467]" : "text-main-grey "
        }`}
    >
        <input
            className={`bg-inherit !text-lg w-[34px] !shadow-[inset_0_0_0_1000px_#EAECF0] mr-auto font-normal ${
                percent ? "!text-main-dark" : "!text-main-grey"
            }`}
            required
            placeholder="0"
            maxLength={`${max}`.length}
            value={percent ?? ""}
            pattern="[0-9]*"
            onChange={(evt) => {
                const val = +evt.target.value;
                !isNaN(val) && val <= max && setPercent(val);
            }}
        />
        % on {placeholder}
    </div>
);

const CurrencyToggler = ({ businessId, className }) => (
    <div
        className={`font-[450] flex items-center justify-between bg-white !p-3 rounded-xl text-[#1F2122] ${className}`}
    >
        <p className="">Currency</p>
        <CurrencyToggle className="rounded-[1px]" businessId={businessId} />
    </div>
);

const PreviewToggle = ({ previewPO, setPreviewPO, className }) => (
    <p
        className={`!mt-3 font-[450] flex items-center justify-between bg-white !p-3 rounded-xl text-[#1F2122] ${className}`}
    >
        Preview purchase order <Toggler setToggle={setPreviewPO} toggle={previewPO} />
    </p>
);

export default CreatePurchaseOrder;
