import React from "react";
import SuccessImage from "../../asset/images/greenSuccess.svg";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon } from "asset/icons";
import currencyPairCheck from "utilities/currencyPairCheck";

const SuccessfulTrxnModal = ({ showSuccessModal, amount, selectedBeneficiary }) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/dashboard");
    };

    const isNairaTrxn = state?.default === "NGN";
    return (
        <Modal
            show={showSuccessModal}
            contentClassName="!rounded-xl w-full max-w-[383px] mx-auto"
            centered
        >
            <Modal.Header className="p-2">
                <div onClick={() => goBack()} className="cursor-pointer">
                    <CloseIcon className="!size-6 text-gray-200" />
                </div>
            </Modal.Header>
            <Modal.Body className="flex flex-col items-center justify-start space-y-1">
                <img src={SuccessImage} alt="Success Icon" className="size-10" />

                <h5 className="text-base font-semibold">
                    {isNairaTrxn ? "Funds sent" : "Transfer Requested"}
                </h5>
                <p className="mb-2 text-sm font-light text-center text-main-grey">
                    You sent{" "}
                    <strong>
                        {currencyPairCheck(state?.default)}
                        {amount}
                    </strong>{" "}
                    to <strong>{selectedBeneficiary?.name}</strong>. You’ll be notified once your
                    transaction is completed.
                </p>

                <div className="flex flex-col items-center w-8/12 my-2 space-y-3 gap-x-3">
                    <button
                        className={`flex px-4 py-2 w-full text-base rounded-lg items-center justify-center border-none outline-none !text-secondary !bg-secondary-10`}
                        type="button"
                        onClick={goBack}
                    >
                        Done
                    </button>
                    <button
                        className={`flex px-2 w-full py-2 text-base rounded-lg items-center justify-center border-none outline-none text-white !bg-secondary`}
                        type="button"
                        onClick={goBack}
                    >
                        Track transaction
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessfulTrxnModal;
