import { CautionIcon, CloseIcon } from "asset/icons";
import React from "react";

const NotificationModal = ({ title, description, onClose, actions }) => {
    return (
        <div className="fixed inset-0 z-50 bg-[#1D29394D] flex justify-center items-center">
            <div className="bg-white rounded-2xl shadow-xl max-w-xl w-full relative">
                {/* Close Icon */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition"
                >
                    <CloseIcon className="size-7" />
                </button>

                <div className="flex gap-3  border-b px-6 items-center">
                    <CautionIcon className="text-[#F79009] size-32" />
                    <div className="!pt-6">
                        {/* Modal Header */}
                        <div className="flex items-center">
                            <h3 className="text-2xl font-semibold text-[#101828]">{title}</h3>
                        </div>

                        {/* Modal Content */}
                        <div className="text-normal text-[#475467] mb-6">{description}</div>
                    </div>
                </div>

                {/* Modal Actions */}
                <div className="flex justify-between py-3 px-6">
                    {actions?.map((action, index) => (
                        <button
                            key={index}
                            onClick={action.onClick}
                            className={`px-4 py-2 rounded-md text-sm font-medium transition ${
                                action.style === "primary"
                                    ? "bg-[#6F00FF] text-white hover:bg-[#5500CC] py-2 rounded-2xl"
                                    : "!text-[#6B7280] !text-base !font-normal hover:bg-[#E4E7EC]"
                            }`}
                        >
                            {action.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NotificationModal;
