import { ArrowFilledIcon, ArrowIcon, CheckCircleIcon, CheckIcon } from "asset/icons";
import Input from "component/form/Input";
import dayjs from "dayjs";
import useDebounce from "hooks/useDebounce";
import { useState } from "react";

const FilterBtn = ({
    statusTypes,
    startDate,
    endDate,
    setStatusTypes,
    setStartDate,
    setEndDate,
}) => {
    const [openFilter, setOpenFilter] = useState(false);
    const [filterType, setFilterType] = useState("");
    const hasFilter = !!statusTypes.length || startDate || endDate;
    const subDropdownCLass = `absolute top-[112%] ${
        hasFilter ? "max-900:top-[450%]" : "max-900:top-[418%]"
    } bg-white rounded-xl w-[190px] !py-1.5 z-20 shadow-dropdown2 transition-all duration-200`;

    const setDate = useDebounce((dateString, startDate = true) => {
        if (startDate) {
            setStartDate(dateString);
        } else {
            setEndDate(dateString);
        }
    }, 1000);

    const clearFilters = () => {
        setFilterType("");
        setStatusTypes([]);
        setStartDate("");
        setEndDate("");
        setOpenFilter(false);
    };

    return (
        <button
            className="flex items-center h-10 !pl-3 375:!pl-6 pr-2.5 375:pr-4 bg-[#EAECF0] rounded-lg text-[#475467] font-[450] !gap-2.5 relative"
            onClick={() =>
                setOpenFilter((prev) => {
                    const newVal = !prev;
                    if (!newVal && !hasFilter) setFilterType("");
                    return newVal;
                })
            }
        >
            {hasFilter && <CheckCircleIcon className="size-[17px] -ml-1 -mr-[2px]" />}
            Filter <ArrowIcon className="size-4 *:stroke-main-grey" />
            <div
                className={`absolute top-[112%] right-0 bg-white rounded-xl w-[190px] !py-1.5 z-20 shadow-dropdown2 transition-all duration-200 ${
                    openFilter
                        ? "translate-y-0 opacity-100 visible"
                        : "translate-y-[30%] opacity-0 invisible"
                }`}
                onClick={(evt) => evt.stopPropagation()}
            >
                {["Date range", "Status"].map((item) => (
                    <span
                        className="flex justify-between items-center text-main !py-2 !pl-4 !pr-2"
                        key={item}
                        onClick={() => setFilterType(item.toLowerCase())}
                    >
                        {item} <ArrowFilledIcon className="*:fill-[#292D32] -rotate-90 size-7" />
                    </span>
                ))}

                <Clear
                    text="Clear all filters"
                    onClick={clearFilters}
                    className={`${!hasFilter && "hidden pointer-events-none !mt-0"}`}
                />
            </div>
            <div
                className={`${subDropdownCLass} right-0 900:-right-[193px] xl:right-[193px] ${
                    filterType.includes("status") && openFilter
                        ? "translate-y-0 opacity-100 visible"
                        : "translate-y-[30%] opacity-0 invisible"
                }`}
                onClick={(evt) => evt.stopPropagation()}
            >
                {["Pending", "Confirmed", "In transit", "Rejected"].map((item) => (
                    <span
                        className="flex justify-between items-center text-[#475467] !py-2 !pl-4 !pr-3.5"
                        key={item}
                        onClick={() =>
                            setStatusTypes((prev) =>
                                prev.includes(item)
                                    ? [...prev].filter((status) => status !== item)
                                    : [...prev, item]
                            )
                        }
                    >
                        {item}
                        <span
                            className={`!size-[18px] cursor-pointer rounded !border transition-all ${
                                statusTypes.includes(item)
                                    ? "!border-secondary grid place-items-center bg-[#F4F0FF]"
                                    : "!border-[#D0D5DD]"
                            }`}
                        >
                            {statusTypes.includes(item) && <CheckIcon className="size-3.5" />}
                        </span>
                    </span>
                ))}

                <Clear
                    text="Clear filter"
                    onClick={() => setStatusTypes([])}
                    className={`${!statusTypes.length && "opacity-0 pointer-events-none !mt-0"}`}
                />
            </div>
            <div
                className={`${subDropdownCLass} !px-4 !py-2.5 !w-[220px] right-0 900:-right-[223px] xl:right-[193px] ${
                    filterType.includes("date") && openFilter
                        ? "translate-y-0 opacity-100 visible"
                        : "translate-y-[30%] opacity-0 invisible"
                }`}
                onClick={(evt) => evt.stopPropagation()}
            >
                <Input
                    type="date"
                    onChange={setDate}
                    value={startDate}
                    className={`rounded-md bg-[#F2F4F7] ${startDate && "!text-main"}`}
                    label="Start date"
                    labelClassName="!mb-1 !mt-1"
                    placeholder="Select start date"
                    min=""
                />

                <Input
                    type="date"
                    label="End date"
                    labelClassName="!mb-1 !mt-4"
                    onChange={(dateString) => setDate(dateString, false)}
                    value={endDate}
                    className={`rounded-md bg-[#F2F4F7] ${endDate && "!text-main"}`}
                    placeholder="Select end date"
                    min={dayjs(startDate).hour(24).format("YYYY-MM-DD")}
                />

                <Clear
                    className={`mt-[22px] translate-x-1 ${
                        !startDate && !endDate && "opacity-0 pointer-events-none !mt-0"
                    }`}
                    text="Clear filter"
                    onClick={() => {
                        setStartDate("");
                        setEndDate("");
                    }}
                />
            </div>
        </button>
    );
};

const Clear = ({ text, onClick, className }) => (
    <p
        className={`!text-secondary text-sm cursor-pointer font-normal w-max ml-auto mr-[12px] mt-[14px] mb-[4px] ${className}`}
        onClick={onClick}
    >
        {text}
    </p>
);

export default FilterBtn;
