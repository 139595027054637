export const businessToUse = (order, business, type) => {
    if (type === "inbound") {
        return {
            name: "",
            bizName: order?.outbound?.businessName,
            email: order?.outbound?.email,
            id: order?.outbound?.businessId,
        };
    } else if (type === "outbound") {
        return {
            name: "",
            bizName: order?.inbound?.businessName,
            email: order?.inbound?.email,
            id: order?.inbound?.businessId,
        };
    } else {
        return order?.inbound?.businessId === business?._id
            ? {
                  type: order?.flexUserType ?? "supplier",
                  createdByCurrBiz: order?.business === business?._id ? true : false,
                  name: "",
                  bizName: order?.outbound?.businessName,
                  email: order?.outbound?.email,
                  id: order?.outbound?.businessId,
              }
            : {
                  type: order?.flexUserType === "supplier" ? "buyer" : "supplier",
                  createdByCurrBiz: order?.business === business?._id ? true : false,
                  name: "",
                  bizName: order?.inbound?.businessName,
                  email: order?.inbound?.email,
                  id: order?.inbound?.businessId,
              };
    }
};
