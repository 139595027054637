import NGNCurrency from "../asset/images/Currency.svg";
import EUCurrency from "../asset/images/EU.svg";
import US from "../asset/images/US.png";
import GBPCurrency from "../asset/images/GB.svg";
export const EMAIL_REGEX = /^\w+(\+[\w]+)?([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const SEND_MONEY = "SEND_MONEY";
export const SUMMARY = "SUMMARY";
export const SEND_MONEY_STEP_TWO = "SEND_MONEY_STEP_TWO";

export const DEFAULT_RATE = "0.000000";

export const SHOW_COUNTRY_FLAG = {
    NGN: NGNCurrency,
    EUR: EUCurrency,
    USD: US,
    GBP: GBPCurrency,
};

export const ADD_ITEM_DETAILS = "ADD_ITEM_DETAILS";
export const ADD_SHIPMENT_DETAILS = "ADD_SHIPMENT_DETAILS";
export const ADD_PAYMENT_INFO = "ADD_PAYMENT_INFO";
export const ADD_RECEIVER_INFO = "ADD_RECEIVER_INFO";
export const SHIPMENT_SUMMARY = "SHIPMENT_SUMMARY";