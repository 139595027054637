import generateId from "utilities/generateId";

/**
 * @description This table use case is for paginated table display
 * @param {Object} props
 * @param {{title:string, width:string}[]} props.titles - Array of title objects, each with a title and width property.
 * @param {string} [props.className]
 * @param {string} [props.wrapperClassName]
 * @param {{columns:{value:any, className:string}[], onRowClick:MouseEvent, rowId:string, className:string}[]} props.rows - Array of rows objects that displays individual rows of the table data.
 */
const Table = ({ titles, wrapperClassName, className, rows }) => {
    const cols = titles.map(({ width }) => width).join(" ");
    return (
        <div className={`overflow-x-auto [scrollbar-width:none] ${wrapperClassName}`}>
            <table className={`w-max ${className}`}>
                <thead className="bg-grey-bg">
                    <tr
                        className={`w-full [&>*]:px-5 [&>*]:py-3 [&>*:first-of-type]:pl-7 [&>*:last-of-type]:pr-0 text-[15px] [&>*]:font-semibold grid`}
                        style={{ gridTemplateColumns: cols }}
                    >
                        {titles.map(({ title }, idx) => (
                            <th key={idx + title + idx}>
                                <p className={`!text-main text-xs font-medium`}>{title}</p>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className={`block bg-white`}>
                    {rows?.map(({ columns, onRowClick, rowId, className }, idx) => (
                        <tr
                            className={`text-sm [&>*]:p-5 w-full transition-colors duration-300 cursor-pointer hover:bg-main-grey/10 [&>*:first-of-type]:pl-7 [&>*:last-of-type]:pr-0 grid ${
                                idx && "border-t"
                            } ${className}`}
                            key={rowId}
                            style={{ gridTemplateColumns: cols }}
                            onClick={onRowClick}
                        >
                            {columns.map(({ value, className }) => (
                                <td className={`${className}`} key={generateId()}>
                                    {value}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
