import React from "react";
import { Link } from "react-router-dom";

const Shpment = () => {
    return (
        <>
            <div className="flex justify-between pt-3 px-5 items-center">
                <div>Shipments</div>
                <Link
                    to="/flex/shipments/create"
                    className="bg-[#6F00FF] rounded-lg text-sm text-white py-2 px-3"
                >
                    Create Shipment
                </Link>
            </div>
        </>
    );
};

export default Shpment;
