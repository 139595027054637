import React from "react";
import edit from "../../asset/images/edit-2.svg";

const SelectBeneficiaryBox = ({ selectedBeneficiary, setShowBeneficiary }) => {
    return (
        <div className="flex items-center justify-between px-3 py-2 bg-white rounded-lg cursor-pointer">
            <div className="">
                <p className="text-[#101828] text-base">
                    {selectedBeneficiary?.name || selectedBeneficiary?.accountName}
                </p>
                <p className="text-[#667085]/70 flex items-center gap-x-1 text-sm font-normal">
                    {selectedBeneficiary?.accountNumber}{" "}
                    {selectedBeneficiary?.bankName && (
                        <span className="bg-gray-400 rounded-full size-1"></span>
                    )}{" "}
                    {selectedBeneficiary?.bankName}
                </p>
            </div>

            <img src={edit} alt="Edit Icon" onClick={() => setShowBeneficiary(true)} />
        </div>
    );
};

export default SelectBeneficiaryBox;
