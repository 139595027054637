import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import BeneficiaryModal from "./modals/BeneficiaryModal";
import BanksModal from "./modals/BanksModal";
import axios from "axios";
import useGetRequest from "hooks/useGetRequest";
import { useSelector } from "react-redux";
import SendMoney from "./_components/SendMoney";
import SendMoneySummary from "./_components/SendMoneySummary";
import { useMutation, useQuery } from "react-query";
import {
    getTransactionFee,
    handleFetchAllBanks,
    handleGetBeneficiary,
    handleTransferFunds,
} from "api/fetchers";
import { getFee } from "utilities/formatMoney";
import currencyPairCheck from "utilities/currencyPairCheck";
import { useQueryClient } from "react-query";
import { SEND_MONEY, SUMMARY } from "constants/global";
import Swal from "sweetalert2";

export const INVOICE = "INVOICE";
export const SUPPORTING_DOCUMENT = "SUPPORTING_DOCUMENT";

const FxTransfer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loader] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [amount, setAmount] = useState("");
    const [fail, setFail] = useState(false);
    const [message, setMessage] = useState("");
    // const [beneficiaries, setBeneficiaries] = useState([]);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [showBank, setShowBank] = useState(false);
    const [fetchWallets, wallets] = useGetRequest();
    const { business } = useSelector((state) => state);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [supportingFile, setSupportingFile] = useState(null);
    const [sendMoneyStep, setSendMoneyStep] = useState(SEND_MONEY);
    const [summaryMemo, setSummaryMemo] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const queryClient = useQueryClient();

    const currentBalance = wallets?.find(({ currency }) => currency === state?.default);

    const { data: bankList, isLoading: isLoadingBankList } = useQuery({
        queryKey: [state?.default],
        queryFn: () => handleFetchAllBanks(state?.default),
        staleTime: Infinity,
    });

    const { data: transferFee } = useQuery({
        queryKey: ["transactionFee"],
        queryFn: () => getTransactionFee(),
        staleTime: Infinity,
    });

    const { data: beneficiaries } = useQuery({
        queryKey: ["beneficiary", state?.default],
        queryFn: () => handleGetBeneficiary(state?.default),
    });

    const handleTransfer = useMutation({
        mutationFn: async (transferData) => {
            const isNairaTransfer = currencyPairCheck(state?.default) === "₦";
            const isPoundsTransfer = currencyPairCheck(state?.default) === "£";
            const isDollarTransfer = currencyPairCheck(state?.default) === "$";
            const isEuroTransfer = currencyPairCheck(state?.default) === "€";

            const formData = new FormData();

            // Append all transfer data to FormData
            formData.append("amount", `${transferData.amount}`);
            if (!isNairaTransfer) {
                formData.append("currency", transferData.currency);
                formData.append("BankName", transferData.bankName);
                formData.append("accountNumber", transferData.accountNumber);
                formData.append("AccountName", transferData.name || transferData.accountName);
                formData.append("recipientType", transferData.recipientType || "");
            } else {
                formData.append("bankname", transferData.bankName);
                formData.append("accountnumber", transferData.accountNumber);
                formData.append("accountname", transferData.accountName);
                formData.append("sesid", "Transfer");
                formData.append("accountcode", transferData.name);

                // Only append files if they exist
                if (transferData.invoice) {
                    formData.append("invoice", transferData.invoice);
                }
                if (transferData.supportDocument) {
                    formData.append("supportDocument", transferData.supportDocument);
                }
            }
            if (isEuroTransfer || isPoundsTransfer) {
                formData.append("SortCode", transferData.sortCode);
                formData.append("IBAN", transferData.IBAN);
            }
            if (isDollarTransfer) {
                formData.append("RoutingNumber", transferData.routingNumber);
                formData.append("SWIFT", transferData.SWIFT);
            }
            formData.append("naration", transferData.memo || "Payment");

            return await handleTransferFunds(isNairaTransfer, formData);
        },
        onSuccess: (response) => {
            // Invalidate relevant queries to refetch updated data
            queryClient.invalidateQueries(["wallets"]);
            queryClient.invalidateQueries(["transactions"]);

            // Navigate to success page or reset form
            // navigate("/transfer/success", {
            //     state: {
            //         amount,
            //         currency: state?.default,
            //         beneficiary: selectedBeneficiary,
            //     },
            // });
        },
        onError: (error) => {
            // Show error notification with specific message
            console.error(error?.response?.data?.message);

            console.error("Transfer Error:", error);
        },
    });

    // Usage in component:
    const handleSubmit = async () => {
        try {
            const response = await handleTransfer.mutateAsync({
                amount,
                currency: state?.default,
                invoice: invoiceFile,
                supportDocument: supportingFile,
                memo: summaryMemo,
                bankName: selectedBeneficiary?.bankName,
                accountNumber: selectedBeneficiary?.accountNumber,
                accountName: selectedBeneficiary?.name || selectedBeneficiary?.accountName,
            });
            if (!response?.error) {
                setShowSuccessModal(true);
            } else {
                Swal.fire({
                    title: "Error !",
                    text: "Something went wrong",
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "rgb(111, 0, 255)",
                });
            }
        } catch (error) {
            console.log({ error });
            Swal.fire({
                title: "Error !",
                text: "Something went wrong",
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "rgb(111, 0, 255)",
            });
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleFileChange = (event, name) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && name === INVOICE) {
            setInvoiceFile(selectedFile);
        }
        if (selectedFile && name === SUPPORTING_DOCUMENT) {
            setSupportingFile(selectedFile);
        }
    };

    const handleSetStep = (step) => {
        setSendMoneyStep(step);
    };

    useEffect(() => {
        if (business?._id) {
            fetchWallets("/v1/wallets/accounts", {
                headers: {
                    "x-business-id": business?._id,
                },
            });
        }
    }, [business?._id]);

    useEffect(() => {
        if (amount !== "") {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [setIsDisable, amount]);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (fail) {
                setFail(false);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (amount) {
            if (parseFloat(amount) <= parseFloat(state.balance || currentBalance?.balance)) {
                setFail(false);
                setMessage("");
            } else {
                setFail(true);
                setMessage("Invalid Amount");
            }
        }
    }, [amount, state.balance]);

    const submitHandler = async (step) => {
        if (parseFloat(amount) > 0) {
            if (sendMoneyStep === SEND_MONEY) {
                handleSetStep(step);
            }
            handleSetStep(step);
        } else {
            setFail(true);
            setMessage("Invalid Amount");
        }
    };

    // useEffect(() => {
    //     const getBeneficiaries = async () => {
    //         await axios
    //             .get("/v1/beneficiary")
    //             .then((res) => {
    //                 setBeneficiaries(res?.data);
    //             })
    //             .catch((e) => {
    //                 console.log(e);
    //             });
    //     };

    //     getBeneficiaries();
    // }, []);

    return (
        <div className="h-100 w-100 ">
            <div
                className="sticky top-0 z-[1000] bg-[#F5F8FA]"
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Same currency transfer</title>
                </Helmet>

                <SerHeader header={"Same currency transfer"} width={25} />

                <BeneficiaryModal
                    show={showBeneficiary}
                    setShow={setShowBeneficiary}
                    setSelectedBeneficiary={(item) => setSelectedBeneficiary(item)}
                    selectedBeneficiary={selectedBeneficiary}
                    setShowBank={setShowBank}
                    beneficiaries={beneficiaries ?? []}
                />
                <BanksModal
                    show={showBank}
                    setShow={setShowBank}
                    bankList={bankList?.data}
                    isLoadingBankList={isLoadingBankList}
                    setSelectedBeneficiary={setSelectedBeneficiary}
                />
            </div>
            <div
                style={{
                    height: "100%",
                    maxWidth: "2000px",
                    margin: "0px auto",
                    width: "100%",
                }}
            >
                <div
                    className=""
                    style={{
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {sendMoneyStep === SEND_MONEY && (
                            <SendMoney
                                fail={fail}
                                message={message}
                                sendMoneyStep={sendMoneyStep}
                                state={state}
                                handleBack={handleBack}
                                handleSetStep={handleSetStep}
                                submitHandler={submitHandler}
                                loader={loader}
                                currentBalance={currentBalance}
                                selectedBeneficiary={selectedBeneficiary}
                                supportingFile={supportingFile}
                                handleFileChange={handleFileChange}
                                isDisable={isDisable || fail}
                                setAmount={setAmount}
                                setShowBeneficiary={setShowBeneficiary}
                                invoiceFile={invoiceFile}
                                amount={amount}
                                summaryMemo={summaryMemo}
                                setSelectedBeneficiary={setSelectedBeneficiary}
                                setSummaryMemo={(e) => setSummaryMemo(e.target.value)}
                                transferFee={
                                    currencyPairCheck(state?.default) +
                                    getFee(state?.default, transferFee?.data)
                                }
                            />
                        )}
                        {sendMoneyStep === SUMMARY && (
                            <SendMoneySummary
                                fail={fail}
                                message={message}
                                sendMoneyStep={sendMoneyStep}
                                state={state}
                                handleBack={handleBack}
                                handleSetStep={handleSetStep}
                                submitHandler={submitHandler}
                                loader={loader}
                                currentBalance={currentBalance}
                                selectedBeneficiary={selectedBeneficiary}
                                supportingFile={supportingFile}
                                handleFileChange={handleFileChange}
                                isDisable={isDisable || fail}
                                setAmount={setAmount}
                                setShowBeneficiary={setShowBeneficiary}
                                invoiceFile={invoiceFile}
                                amount={amount}
                                summaryMemo={summaryMemo}
                                setSummaryMemo={(e) => setSummaryMemo(e.target.value)}
                                transferFee={
                                    currencyPairCheck(state?.default) +
                                    getFee(state?.default, transferFee?.data)
                                }
                                showSuccessModal={showSuccessModal}
                                setShowSuccessModal={setShowSuccessModal}
                                callBack={handleSubmit}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FxTransfer;
