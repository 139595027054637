import {
    AddIcon,
    CheckCircleIcon,
    CloseIcon,
    EditUnderlineIcon,
    ProfileFilledIcon,
    ProfileIcon,
    SearchIcon,
} from "asset/icons";
import AddNetwork from "component/addNetwork";
import ComponentLoader from "component/componentloader";
import useGetRequest from "hooks/useGetRequest";
import { Fragment, useEffect, useRef, useState } from "react";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";
import capitalizeWords from "utilities/capitalizeWords";
import getInitials from "utilities/getInitials";

const AssignSupplier = ({ type, selectedSupplier, setSelectedSupplier, business }) => {
    const [fetchSuppliers, supps, suppliersLoading] = useGetRequest();
    const listRef = useRef();
    const [selectPopUp, setSelectPopUp] = useState(false);
    const [addNetworkModal, setAddNetworkModal] = useState(false);
    const [searchString, setSearchString] = useState("");
    const assignText = type === "buyer" ? "supplier" : "buyer";

    const nameToUse = (supplier) =>
        supplier.business._id === business?._id
            ? supplier.businessName.toLowerCase()
            : supplier.business.name.toLowerCase();

    const suppliers = supps
        ?.filter((supplier) => nameToUse(supplier).includes(searchString.toLowerCase()))
        .sort((a, b) => nameToUse(a).localeCompare(nameToUse(b)));

    const alphabets = [
        ...new Set(suppliers?.map((supp) => nameToUse(supp).charAt(0).toUpperCase())),
    ];

    const handleFetchSuppliers = (businessId) => {
        fetchSuppliers("/v1/flex/users", { headers: { "x-business-id": businessId } });
    };

    useEffect(() => {
        !supps && selectPopUp && handleFetchSuppliers(business?._id);
        // fetchSuppliers("/v1/flex/users", {
        //     headers: {
        //         "x-business-id": business?._id,
        //     },
        // });

        selectPopUp && listRef?.current?.scroll({ top: 0 });
        !selectPopUp && setAddNetworkModal(false);
    }, [selectPopUp, business?._id, supps]);

    return (
        <>
            <p className="mb-2 text-sm font-medium text-main-dark">Assign {assignText}</p>
            <div
                className="bg-white rounded-lg !p-4 flex items-center relative cursor-pointer"
                onClick={(evt) => setSelectPopUp((prev) => !prev)}
            >
                <input
                    required
                    defaultValue={selectedSupplier?._id}
                    className="absolute top-0 left-0 h-full -z-30"
                />

                {selectedSupplier ? (
                    <ProfileFilledIcon className="rounded-full mr-1.5 -ml-1 -my-0.5" />
                ) : (
                    <ProfileIcon className="bg-[#F9F5FF] rounded-full mr-2 p-[5px] size-8" />
                )}
                <span className="font-[450] mr-auto text-main-dark">
                    {selectedSupplier ? (
                        capitalizeWords(selectedSupplier.name.toLowerCase())
                    ) : (
                        <>Choose a {assignText} </>
                    )}
                </span>

                {selectedSupplier ? <EditUnderlineIcon className="size-[22px]" /> : <AddIcon />}

                {/* Dropdown */}
                <div
                    className={`bg-page-bg rounded-xl absolute cursor-auto !py-4 !pb-0 left-0 top-[110%] w-full shadow-[-16px_-16px_16px_-4px_#10182808,16px_16px_32px_-4px_#10182814] !transition-all !duration-[400ms] ${
                        selectPopUp
                            ? "translate-y-0 opacity-100 visible z-20"
                            : "translate-y-[30%] opacity-0 invisible -z-50"
                    }`}
                    onClick={(evt) => evt.stopPropagation()}
                >
                    <CloseIcon
                        className="!size-6 cursor-pointer !ml-4"
                        onClick={() => setSelectPopUp(false)}
                    />

                    {suppliersLoading ? (
                        <ComponentLoader free className="mx-auto mt-16 mb-24" />
                    ) : (
                        <>
                            <div className="flex items-center font-[450] !text-lg text-main-dark !mt-6 !mb-5 !px-4">
                                Choose a {assignText}
                                <button
                                    className="flex items-center rounded-lg text-[15px] ml-auto text-[#5E01D6] !py-2 !pl-4 !pr-5 gap-2 bg-[#EBE4FF]"
                                    type="button"
                                    onClick={() => setAddNetworkModal(true)}
                                >
                                    <AddIcon className="size-6" /> Add New
                                </button>
                            </div>
                            <>
                                <p className="font-medium text-sm !mb-1 !mx-4">
                                    Your Tradevu Network
                                </p>
                                <div className="rounded-lg h-[56px] !px-4 bg-[#EAECF0] flex items-center !mx-4">
                                    <SearchIcon className="*:stroke-[#667085]" />
                                    <input
                                        className="w-full !ml-3 placeholder:font-normal bg-inherit placeholder:!text-[#667085] !shadow-[inset_0_0_0_1000px_#EAECF0]"
                                        placeholder="Search by name"
                                        onChange={(evt) => setSearchString(evt.target.value)}
                                    />
                                </div>
                            </>
                            <div
                                className={`max-h-[309px] mt-3 !px-4 ${
                                    addNetworkModal ? "overflow-y-hidden" : "overflow-y-auto"
                                }`}
                                ref={listRef}
                            >
                                {suppliers?.length ? (
                                    <>
                                        {alphabets.map((letter, letterIdx) => (
                                            <Fragment key={letter}>
                                                <p
                                                    className={`font-medium text-xs text-[#667085] ${
                                                        letterIdx && "!mt-[15px]"
                                                    } !mb-2`}
                                                >
                                                    {letter}
                                                </p>
                                                <div
                                                    className={`rounded-lg !px-2 bg-white flex flex-col gap-2.5 !py-2.5 ${
                                                        letterIdx === alphabets.length - 1 &&
                                                        "!pb-[22px]"
                                                    }`}
                                                >
                                                    {suppliers
                                                        .filter((supp) =>
                                                            nameToUse(supp).startsWith(
                                                                letter.toLowerCase()
                                                            )
                                                        )
                                                        .map((supplier) => (
                                                            <div
                                                                className={`flex items-center gap-2 !py-1.5 cursor-pointer rounded-lg`}
                                                                onClick={() => {
                                                                    setSelectedSupplier({
                                                                        name: nameToUse(supplier),
                                                                        _id: supplier._id,
                                                                        personalName: `${
                                                                            supplier.firstName ?? ""
                                                                        } ${
                                                                            supplier.lastName ?? ""
                                                                        }`,
                                                                    });
                                                                    setSelectPopUp(false);
                                                                }}
                                                                key={supplier._id}
                                                            >
                                                                <span
                                                                    className={`!size-10 bg-[#F6F6F6] rounded-full text-[#344054] font-[450] grid place-items-center ${
                                                                        getInitials(
                                                                            nameToUse(supplier)
                                                                        ).length > 3 &&
                                                                        "text-[13px]"
                                                                    }`}
                                                                >
                                                                    {getInitials(
                                                                        nameToUse(supplier)
                                                                    )}
                                                                </span>
                                                                <p className="text-main-dark font-[450]">
                                                                    {capitalizeWords(
                                                                        nameToUse(supplier) ?? ""
                                                                    )}
                                                                </p>

                                                                {selectedSupplier?._id ===
                                                                    supplier._id && (
                                                                    <CheckCircleIcon className="ml-auto mr-3 size-6" />
                                                                )}
                                                            </div>
                                                        ))}
                                                </div>
                                            </Fragment>
                                        ))}
                                    </>
                                ) : (
                                    <span className="block text-center !pt-10 !pb-14">
                                        No {capitalizeFirstLetter(assignText)} available
                                    </span>
                                )}
                            </div>
                        </>
                    )}
                </div>
                {/* End Dropdown */}
            </div>

            <AddNetwork
                refetchUser={() => handleFetchSuppliers(business?._id)}
                show={addNetworkModal}
                close={() => setAddNetworkModal(false)}
            />
        </>
    );
};

export default AssignSupplier;
