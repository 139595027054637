const groupByFirstLetter = (array) => {
    let resultObj = {};

    for (let i = 0; i < array.length; i++) {
        let currentObj = array[i];
        let currentWord = currentObj.name;
        let firstChar = currentWord[0].toLowerCase();
        let innerArr = [];
        if (resultObj[firstChar] === undefined) {
            innerArr.push(currentObj);
            resultObj[firstChar] = innerArr;
        } else {
            resultObj[firstChar].push(currentObj);
        }
    }
    return resultObj;
};

export default groupByFirstLetter;
