import React, { useState } from "react";
import boat from "../../../asset/images/boat.svg";
import truck from "../../../asset/icons/truck.svg";
import Dropdown from "component/form/dropDown";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AddIcon, LocationOutlineIcon, UserIcon } from "asset/icons";
import Input from "component/form/Input";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import SupportDocs from "component/form/supportDocs";
import { ADD_ITEM_DETAILS, ADD_PAYMENT_INFO } from "constants/global";

const AddShipmentDetails = ({
    submitFirstForm,
    goToStep,
    selectShippingMethod,
    setSelectShippingMethod,
    shippingLegs,
    setShippingLegs,
}) => {
    // Add a new shipping leg
    const addShippingLeg = () => {
        setShippingLegs([
            ...shippingLegs,
            {
                id: Date.now(),
                departurePort: "",
                destinationPort: "",
                shippingLine: "",
                clearingAgent: "",
                dropOffDate: "",
                isClearingAgent: false,
            },
        ]);
    };

    // Update a specific shipping leg's state
    const updateShippingLeg = (id, field, value) => {
        setShippingLegs(
            shippingLegs.map((leg) => (leg.id === id ? { ...leg, [field]: value } : leg))
        );
    };

    // Remove a shipping leg
    const removeShippingLeg = (id) => {
        setShippingLegs(shippingLegs.filter((leg) => leg.id !== id));
    };

    return (
        <div className="ml-48 w-[610px]">
            <h3 className="text-main-dark font-[450] text-2xl !mb-6">Add shipping details</h3>
            {!selectShippingMethod ? (
                <Dropdown
                    value={selectShippingMethod}
                    name="selectShippingMethod"
                    options={["Sea freight", "Truck"].map((option) => ({
                        label: option,
                        value: option,
                    }))}
                    onChange={(e) => {
                        setSelectShippingMethod(e);
                    }}
                    label="Select shipping method"
                    labelClassName="!text-[#475467]"
                    placeholder="Select shipping method"
                />
            ) : !shippingLegs.length ? (
                <>
                    <Dropdown
                        value={selectShippingMethod}
                        name="selectShippingMethod"
                        options={["Sea freight", "Truck"].map((option) => ({
                            label: option,
                            value: option,
                        }))}
                        onChange={(e) => {
                            setSelectShippingMethod(e);
                        }}
                        label="Select shipping method"
                        labelClassName="!text-[#475467]"
                        placeholder="Select shipping method"
                    />
                    <button className="!text-secondary" onClick={addShippingLeg}>
                        + Add shipping leg
                    </button>
                </>
            ) : (
                <>
                    {shippingLegs.map((leg, index) => (
                        <ShippingLeg
                            key={leg.id}
                            leg={leg}
                            index={index + 1}
                            selectShippingMethod={selectShippingMethod}
                            updateShippingLeg={updateShippingLeg}
                            removeShippingLeg={removeShippingLeg}
                        />
                    ))}
                    <button className="!text-secondary" onClick={addShippingLeg}>
                        + Add shipping leg
                    </button>
                </>
            )}
            <div className="flex !gap-9 justify-end !mt-6">
                <button
                    onClick={() => goToStep(ADD_ITEM_DETAILS)}
                    type="button"
                    className="button text-[#1D2939] font-[450] !py-2 !px-6"
                >
                    Back
                </button>
                <button
                    onClick={() => submitFirstForm(ADD_PAYMENT_INFO)}
                    className={`!py-2 !px-6 text-white rounded-lg font-[450] ${
                        true ? "bg-[#D9CDFF]" : "bg-secondary"
                    }`}
                    type="submit"
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

const ShippingLeg = ({
    leg,
    index,
    updateShippingLeg,
    removeShippingLeg,
    selectShippingMethod,
}) => {
    const [showMore, setShowMore] = useState(false);
    const [addDocs, setAddDocs] = useState(false);
    const [file, setFile] = useState();
    return (
        <div className="my-3">
            {/* Shipping Leg Header */}
            <div className="flex items-center justify-between p-3 bg-white rounded-md cursor-pointer">
                <div
                    onClick={() => setShowMore((c) => !c)}
                    className="flex items-center justify-start w-full gap-2 m-0"
                >
                    <div
                        className={`flex items-center justify-center p-2 rounded-full size-10 bg-gray-100`}
                    >
                        <img
                            src={selectShippingMethod === "Truck" ? truck : boat}
                            alt="Boat | Truck Icon"
                            className={"size-6"}
                        />
                    </div>{" "}
                    <span className="flex items-center gap-2 text-bold">
                        Leg {index} : {selectShippingMethod}{" "}
                        {showMore ? (
                            <FaChevronDown size={14} className="font-light text-gray-500" />
                        ) : (
                            <FaChevronUp size={14} className="font-light text-gray-500" />
                        )}
                    </span>
                </div>
                <span
                    className="text-xs font-semibold cursor-pointer !text-secondary"
                    onClick={() => setShowMore((c) => !c)}
                >
                    {showMore ? "Hide" : "View"}
                </span>
            </div>

            {showMore && (
                <div className="pt-4 pl-3 ml-3 border-l">
                    <div className="flex flex-col gap-[8px] w-full my-3">
                        <p className="text-[14px] leading-[19.6px] font-medium	text-gray-10">
                            Shipment Route
                        </p>
                        <div className="p-3 has-[:focus]:bg-[#EAECF0] bg-gray-30 rounded-md">
                            <div className="relative">
                                <div className="flex items-center">
                                    <span className="mr-2 text-base balance_number text-gray-10">
                                        <LocationOutlineIcon />
                                    </span>

                                    <input
                                        type="text"
                                        value={leg.departurePort}
                                        className="w-full focus:bg-[#EAECF0] !shadow-none"
                                        onChange={(e) =>
                                            updateShippingLeg(
                                                leg.id,
                                                "departurePort",
                                                e.target.value
                                            )
                                        }
                                        placeholder={
                                            selectShippingMethod === "Truck"
                                                ? "Pick-up point"
                                                : "Departure Port"
                                        }
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-3 has-[:focus]:bg-[#EAECF0] bg-gray-30 mt-2 rounded-md">
                            <div className="relative">
                                <div className="flex items-center">
                                    <span className="mr-2 text-base balance_number text-gray-10">
                                        <LocationOutlineIcon />
                                    </span>

                                    <input
                                        type="text"
                                        value={leg.destinationPort}
                                        className="w-full focus:bg-[#EAECF0] !shadow-none"
                                        onChange={(e) =>
                                            updateShippingLeg(
                                                leg.id,
                                                "destinationPort",
                                                e.target.value
                                            )
                                        }
                                        placeholder={
                                            selectShippingMethod === "Truck"
                                                ? "Drop-off point"
                                                : "Destination Port"
                                        }
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px] w-full my-3">
                        <Input
                            label="Add Shipping Line"
                            placeholder="Enter shipping Line"
                            name="shippingLine"
                            value={leg.shippingLine}
                            onChange={(e) => updateShippingLeg(leg.id, "shippingLine", e)}
                        />
                    </div>

                    <div className="flex flex-col gap-[8px] w-full my-3">
                        <label
                            htmlFor=""
                            className="text-[14px] leading-[19.6px] font-medium	text-gray-10"
                        >
                            Assign Clearing Agent
                        </label>
                        <div className="p-[5px] h-[56px] bg-white rounded-[8px]">
                            <div className="hover:bg-[#BFA6FF]/30 duration-200 transition cursor-pointer flex w-full items-center h-full rounded-[8px]">
                                <div
                                    className={`flex items-center justify-center p-2 rounded-full size-10 bg-gray-100`}
                                >
                                    <UserIcon className="text-base" />
                                </div>
                                <div className="w-full flex justify-between items-center text-md pl-[8px]">
                                    <p className="text-main-dark">Add Clearing Agent</p>
                                    <div className="w-[30px] h-full flex items-center justify-center">
                                        <AddIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            htmlFor=""
                            className="text-[14px] leading-[19.6px] font-medium mt-2 text-gray-10"
                        >
                            <FormCheckInput /> I am the Clearing Agent
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px] w-full my-3">
                        <Input
                            type="date"
                            label="Drop Off Date"
                            placeholder="01-03-2024"
                            name="dropOffDate"
                            value={leg.dropOffDate}
                            onChange={(e) => updateShippingLeg(leg.id, "dropOffDate", e)}
                        />
                    </div>

                    <SupportDocs
                        addDocs={addDocs}
                        setAddDocs={setAddDocs}
                        file={file}
                        setFile={setFile}
                        showDelete={true}
                    />

                    <button
                        className="!text-secondary mt-2"
                        onClick={() => removeShippingLeg(leg.id)}
                    >
                        - Remove Shipping Leg
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddShipmentDetails;
