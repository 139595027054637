import { CheckCircleIcon, CloseIcon, EditUnderlineIcon } from "asset/icons";
import { useEffect, useState } from "react";
import getInitials from "utilities/getInitials";
import capitalizeWords from "utilities/capitalizeWords";
import useGetRequest from "hooks/useGetRequest";
import { errorAlert } from "utilities/alerts";
import axios from "axios";
import { Link } from "react-router-dom";
import SuccessModal from "./component/successModal";
import AddManual from "./addManual";
import { Bars } from "react-loader-spinner";
import DropdownCheckItem from "./component/dropdownCheckItem";
import BusinessNature from "./component/businessNature";
import UserSearch from "./component/userSearch";
import { countrycodelist } from "constants/countryList";
import Dropdown from "component/form/dropDown";

// Todo
// Responsiveness

const AddNetwork = ({ show, close, className, link, refetchUser }) => {
    const [searchUsers, usersRes, searchLoading] = useGetRequest(false);
    const [fetchNGNBanks, NGNBanks, NGNBanksLoading] = useGetRequest(false);
    const [type, setType] = useState("existing");
    const [selectedUser, setSelectedUser] = useState();
    const [networkType, setNetworkType] = useState([]);
    const [businessNature, setBusinessNature] = useState();
    const [addNewNatureOfBiz, setAddNewNatureOfBiz] = useState(false);
    const [page, setPage] = useState(0);
    const [addLoading, setAddLoading] = useState(false);
    const [userAdded, setUserAdded] = useState(null);
    const [manualFormData, setManualFormData] = useState({});
    const [manualUser, setManualUser] = useState();
    const [locations, setLocations] = useState([{ id: "23f3f3" }]);
    const initialCountry = countrycodelist.find(({ title }) => title === "Nigeria");
    const initialPaymentInfo = {
        currency: initialCountry.currency,
        bankName: "",
        accountName: "",
        accountNumber: "",
        bankCode: "",
        id: "sdf3f",
        edit: true,
        country: initialCountry,
    };
    const [paymentInfos, setPaymentInfos] = useState([initialPaymentInfo]);

    const networkTypeOptions = [
        {
            key: "3rd party logistics provider",
            children: ["Clearing agent", "Trucker"],
        },
        "Buyer",
        "Supplier",
        "Other",
    ];
    const nestedKeys = networkTypeOptions
        .filter((type) => typeof type !== "string")
        .map(({ key }) => key);
    const filteredNetworkType = networkType.filter((type) => !nestedKeys.includes(type));

    const updateNetworkType = (item) => {
        if (networkType.includes(item)) {
            setNetworkType((prv) => {
                const prev = [...prv];
                prev.splice(prev.indexOf(item), 1);
                return prev;
            });
        } else {
            setNetworkType((prev) => [...prev, item]);
        }
    };

    const addNetworkParentKey = (item) => {
        if (
            item.children.every((child) => networkType.includes(child)) &&
            !networkType.includes(item.key)
        ) {
            setNetworkType((prev) => [...prev, item.key]);
        }
    };

    const addToNetworkExisting = async () => {
        await axios.post("/v1/flex/user", {
            flexUserType: filteredNetworkType,
            businessNature,
            firstName: selectedUser.user.firstName,
            lastName: selectedUser.user.lastName,
            phoneNumber: selectedUser.user.phoneNumber,
            email: selectedUser.user.email,
            businessName: selectedUser.name,
            businessAddress: selectedUser.address,
            receiverBusiness: selectedUser._id,
        });
        setUserAdded({
            title: "Great! Your request has been sent",
            text: "Your request to add a Tradevu user has been sent and you will be notified once accepted.",
            link: link || "/flex/home/network",
        });
    };

    const addToNetworkManual = async () => {
        setUserAdded({
            title: `Great! ${capitalizeWords(
                manualFormData?.businessName ?? "The business"
            )} has been added to your network`,
            text: (
                <>
                    They will be notified via email shortly. You can see invited networks{" "}
                    <Link className="!text-[#6B7280] font-bold" to={""}>
                        here
                    </Link>
                </>
            ),
            link: link || "/flex/home/network",
        });
    };

    const addToNetwork = async () => {
        try {
            setAddLoading(true);
            if (type.includes("existing")) {
                await addToNetworkExisting();
            } else {
                await addToNetworkManual();
            }
            console.log("refetchUser , will be fired");
            refetchUser();
        } catch (error) {
            errorAlert(error);
        } finally {
            setAddLoading(false);
        }
    };

    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setBusinessNature(null);
                setAddNewNatureOfBiz(false);
                setSelectedUser(null);
                setNetworkType([]);
                setPage(0);
                setType("existing");
                setUserAdded(false);
                setManualFormData({});
                setPaymentInfos([initialPaymentInfo]);
                setLocations(["one"]);
                setManualUser(null);
            }, 400);
        }
    }, [show]);

    return (
        <>
            <div
                className={`h-screen w-screen bg-[#1D29394D] left-0 top-0 z-[2500] grid place-items-center !px-6 transition-all ${
                    show ? "fixed" : "hidden"
                } `}
            >
                {userAdded ? (
                    <SuccessModal
                        title={userAdded.title}
                        text={userAdded.text}
                        close={() => close(manualUser)}
                        show={show}
                        link={userAdded.link}
                    />
                ) : (
                    <div
                        className={`bg-white rounded-2xl w-[560px] relative transition-all duration-300 cursor-default z-30 max-h-[892px] ${
                            show
                                ? "translate-y-0 opacity-100 visible"
                                : "translate-y-[30%] opacity-0 invisible"
                        } shadow-[0px_8px_5px_0px_#00000014,0px_20px_13px_0px_#00000008] ${className}`}
                        onClick={(evt) => evt.stopPropagation()}
                    >
                        <CloseIcon
                            className={`size-[23px] absolute right-5 top-[17px] cursor-pointer rounded-full pointer-events-auto ${
                                (searchLoading || addLoading) && "!pointer-events-none"
                            }`}
                            onClick={close}
                        />
                        <h3 className="text-main-dark font-semibold text-xl text-left !pt-[18px] !pb-4 !px-6">
                            Add to Network
                            <span className="block font-normal text-sm text-main-grey !mt-1.5">
                                Invite a business to your network on Tradevu below
                            </span>
                        </h3>

                        {!page ? (
                            <>
                                <p className="font-[450] text-left px-6 text-main-dark !mb-[5px]">
                                    How do you want to add?
                                </p>
                                {[
                                    {
                                        title: "Existing Tradevu user",
                                        text: "Easily invite existing users by searching their business name",
                                    },
                                    {
                                        title: "Manually",
                                        text: "Manually add new businesses to your network by entering their details.",
                                    },
                                ].map(({ text, title }, idx) => (
                                    <div
                                        className={`flex !px-3.5 !py-4 rounded-[10px] !border !mx-6 pointer-events-auto cursor-pointer transition-colors duration-200 ${
                                            idx && "!mt-4"
                                        } ${
                                            !title.toLowerCase().includes(type)
                                                ? "!border-[#E7E9FB]"
                                                : "!border-secondary"
                                        }`}
                                        key={title}
                                        onClick={() => setType(title.toLocaleLowerCase())}
                                    >
                                        {!title.toLowerCase().includes(type) ? (
                                            <div className="!border !border-[#C0CAD8] rounded-full size-4 translate-y-1.5 !mr-2" />
                                        ) : (
                                            <CheckCircleIcon className="size-5 translate-y-1 !mr-1" />
                                        )}
                                        <p className="text-main-dark font-[450] text-left">
                                            {title}
                                            <span className="block text-sm font-normal text-main-grey">
                                                {text}
                                            </span>
                                        </p>
                                    </div>
                                ))}
                                <div className="flex justify-end items-center !border-t !mt-6 !border-[#E7E9FB] !px-6 !py-4 *:pointer-events-auto *:!py-2 *:!px-6 *:rounded-lg">
                                    <button
                                        className="text-[#475467] font-[450]"
                                        type="button"
                                        onClick={close}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-[#FBFAFF] !bg-secondary"
                                        type="button"
                                        onClick={() => setPage(1)}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                {type.includes("existing") ? (
                                    <>
                                        {page === 1 ? (
                                            <UserSearch
                                                selectedUser={selectedUser}
                                                users={usersRes}
                                                searchLoading={searchLoading}
                                                searchUsers={searchUsers}
                                                selectUser={(user) => {
                                                    setSelectedUser(user);
                                                    setPage(2);
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <div className="!px-6">
                                                    <p
                                                        className="rounded-lg !py-2.5 !border !border-[#E7E9FB] flex items-center !mt-4 !px-3 font-[450] text-main-dark pointer-events-auto cursor-pointer"
                                                        onClick={() => setPage(1)}
                                                    >
                                                        <span
                                                            className={`!size-8 bg-[#F6F6F6] rounded-full text-[#475467] font-medium text-xs grid place-items-center overflow-hidden !mr-2 ${
                                                                getInitials(selectedUser.name)
                                                                    .length > 3 && "!text-[10px]"
                                                            }`}
                                                        >
                                                            {getInitials(selectedUser.name)}
                                                        </span>
                                                        {capitalizeWords(
                                                            selectedUser.name.toLowerCase()
                                                        )}
                                                        <EditUnderlineIcon className="ml-auto !size-5 *:stroke-[#6F00FF]" />
                                                    </p>

                                                    <Dropdown
                                                        placeholder="Select network type"
                                                        label="Network type"
                                                        options={networkTypeOptions.map((item) => {
                                                            if (typeof item === "object") {
                                                                return {
                                                                    label: (
                                                                        <>
                                                                            <DropdownCheckItem
                                                                                checked={
                                                                                    networkType.includes(
                                                                                        item.key
                                                                                    ) ||
                                                                                    item.children.every(
                                                                                        (child) =>
                                                                                            networkType.includes(
                                                                                                child
                                                                                            )
                                                                                    )
                                                                                }
                                                                                text={item.key}
                                                                                onClick={() => {
                                                                                    networkType.includes(
                                                                                        item.key
                                                                                    ) &&
                                                                                        item.children.every(
                                                                                            (
                                                                                                child
                                                                                            ) =>
                                                                                                networkType.includes(
                                                                                                    child
                                                                                                )
                                                                                        ) &&
                                                                                        updateNetworkType(
                                                                                            item.key
                                                                                        );
                                                                                    item.children.forEach(
                                                                                        (child) =>
                                                                                            updateNetworkType(
                                                                                                child
                                                                                            )
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <>
                                                                                {item.children.map(
                                                                                    (child) => (
                                                                                        <DropdownCheckItem
                                                                                            checked={networkType.includes(
                                                                                                child
                                                                                            )}
                                                                                            text={
                                                                                                child
                                                                                            }
                                                                                            onClick={() => {
                                                                                                updateNetworkType(
                                                                                                    child
                                                                                                );
                                                                                                addNetworkParentKey(
                                                                                                    item
                                                                                                );
                                                                                            }}
                                                                                            className={
                                                                                                "!pl-10"
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        </>
                                                                    ),
                                                                    value: item.key,
                                                                    className:
                                                                        "!p-0 flex gap-0 flex-col",
                                                                };
                                                            } else {
                                                                return {
                                                                    label: (
                                                                        <DropdownCheckItem
                                                                            checked={networkType.includes(
                                                                                item
                                                                            )}
                                                                            text={item}
                                                                            onClick={() =>
                                                                                updateNetworkType(
                                                                                    item
                                                                                )
                                                                            }
                                                                        />
                                                                    ),
                                                                    value: item,
                                                                    className: "!p-0",
                                                                };
                                                            }
                                                        })}
                                                        value={
                                                            filteredNetworkType.length > 1
                                                                ? filteredNetworkType
                                                                      .join(",")
                                                                      .replace(/,/g, ", ")
                                                                : filteredNetworkType
                                                        }
                                                        labelClassName="text-left !text-[#475467]"
                                                        className="!p-0 pointer-events-auto"
                                                    />

                                                    <BusinessNature
                                                        addNewNatureOfBiz={addNewNatureOfBiz}
                                                        businessNature={businessNature}
                                                        setAddNewNatureOfBiz={setAddNewNatureOfBiz}
                                                        setBusinessNature={setBusinessNature}
                                                    />
                                                </div>
                                                <div className="flex justify-end items-center !border-t !mt-6 !border-[#E7E9FB] !px-6 !py-4 *:pointer-events-auto *:rounded-lg">
                                                    <button
                                                        className="text-[#475467] font-[450] !py-2 !px-6"
                                                        type="button"
                                                        onClick={close}
                                                        disabled={addLoading}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className={`text-[#FBFAFF] ${
                                                            addLoading
                                                                ? "!py-3 !px-[68px]"
                                                                : "!py-2 !px-6"
                                                        } ${
                                                            filteredNetworkType.length &&
                                                            selectedUser &&
                                                            businessNature
                                                                ? "!bg-secondary"
                                                                : "!bg-[#D9CDFF] pointer-events-none"
                                                        }`}
                                                        type="button"
                                                        onClick={addToNetwork}
                                                        disabled={addLoading}
                                                    >
                                                        {addLoading ? (
                                                            <Bars
                                                                height="20"
                                                                width="20"
                                                                color="#fff"
                                                                ariaLabel="bars-loading"
                                                            />
                                                        ) : (
                                                            "Add to network"
                                                        )}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <AddManual
                                        {...{
                                            close,
                                            page,
                                            setPage,
                                            addNewNatureOfBiz,
                                            businessNature,
                                            setAddNewNatureOfBiz,
                                            setBusinessNature,
                                            manualFormData,
                                            setManualFormData,
                                            networkType,
                                            setNetworkType,
                                            paymentInfos,
                                            setPaymentInfos,
                                            fetchNGNBanks,
                                            NGNBanks,
                                            NGNBanksLoading,
                                            locations,
                                            setLocations,
                                            addToNetwork,
                                            manualUserId: manualUser?._id,
                                            setManualUser,
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default AddNetwork;
