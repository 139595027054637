import { CloseIcon } from "asset/icons";
import FormSteps from "component/form/formSteps";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddItemDetails from "../components/AddItemDetails";
import AddShipmentDetails from "../components/AddShipmentDetails";
import AddPaymentInfo from "../components/AddPaymentInfo";
import AddReceiverInfo from "../components/AddReceiverInfo";
import ShipmentSummary from "../components/ShipmentSummary";
import {
    ADD_ITEM_DETAILS,
    ADD_PAYMENT_INFO,
    ADD_RECEIVER_INFO,
    ADD_SHIPMENT_DETAILS,
    SHIPMENT_SUMMARY,
} from "constants/global";

const CreateShipment = () => {
    const [shippingDetailsStep, setShippingDetailsStep] = useState(ADD_ITEM_DETAILS);
    const [addDocs, setAddDocs] = useState(false);
    const [file, setFile] = useState();
    const [firstStepDetails, setFirstStepDetails] = useState({
        itemName: "",
        containerType: "",
        loadType: "",
        packageType: "",
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        description: "",
    });
    const [selectShippingMethod, setSelectShippingMethod] = useState(null);
    const [shippingLegs, setShippingLegs] = useState([
        {
            id: Date.now(),
            departurePort: "",
            destinationPort: "",
            shippingLine: "",
            clearingAgent: "",
            dropOffDate: "",
            isClearingAgent: false,
        },
    ]);

    const handleFirstFormChange = ({ name, value }) => {
        setFirstStepDetails((prev) => ({ ...prev, [name]: value }));
    };

    const submitFirstForm = (step) => {
        console.log({ ...firstStepDetails, file });
        goToStep(step);
    };

    const goToStep = (step) => {
        setShippingDetailsStep(step);
    };

    return (
        <>
            <nav className="h-[72px] !m-0 !px-6 !py-0 flex items-center navbar-gradient">
                <Link to={-1}>
                    <CloseIcon className="*:stroke-[#1D2939] size-[25px] mr-2 -translate-y-[3px]" />
                </Link>

                <h2 className="font-[450] !text-lg text-main-dark !ml-3.5 mr-auto">
                    Create Shipment
                </h2>

                <button className="!text-secondary !mr-8">Save as Draft</button>
                <button
                    className={`text-[#FBFAFF] rounded-lg !px-6 !py-2 font-[450] ${
                        true ? "pointer-events-none !bg-[#D9CDFF]" : "!bg-secondary"
                    }`}
                    onClick={() => {}}
                >
                    Book Shipment
                </button>
            </nav>

            <div className="flex !px-[70px] !pt-11 !mb-20">
                <FormSteps
                    setShippingDetailsStep={setShippingDetailsStep}
                    currentStep={shippingDetailsStep}
                />
                {shippingDetailsStep === ADD_ITEM_DETAILS && (
                    <AddItemDetails
                        firstStepDetails={firstStepDetails}
                        handleFirstFormChange={handleFirstFormChange}
                        addDocs={addDocs}
                        setAddDocs={setAddDocs}
                        file={file}
                        setFile={setFile}
                        submitFirstForm={submitFirstForm}
                    />
                )}
                {shippingDetailsStep === ADD_SHIPMENT_DETAILS && (
                    <AddShipmentDetails
                        submitFirstForm={submitFirstForm}
                        goToStep={goToStep}
                        shippingLegs={shippingLegs}
                        setShippingLegs={setShippingLegs}
                        selectShippingMethod={selectShippingMethod}
                        setSelectShippingMethod={setSelectShippingMethod}
                    />
                )}
                {shippingDetailsStep === ADD_PAYMENT_INFO && (
                    <AddPaymentInfo submitFirstForm={submitFirstForm} goToStep={goToStep} />
                )}
                {shippingDetailsStep === ADD_RECEIVER_INFO && (
                    <AddReceiverInfo submitFirstForm={submitFirstForm} goToStep={goToStep} />
                )}
                {shippingDetailsStep === SHIPMENT_SUMMARY && (
                    <ShipmentSummary goToStep={goToStep} firstStepDetails={firstStepDetails} />
                )}
                {/* <form onSubmit={(evt) => evt.preventDefault()} className="ml-48 w-[610px]">
                    <h3 className="text-main-dark font-[450] text-2xl !mb-6">Add item details</h3>

                    <Input label="Name your shipment" placeholder="TSH-8239" />

                    <div className="grid grid-cols-2 !gap-3.5">
                        <div>
                            <Dropdown
                                // value=""
                                options={[
                                    "20 Foot",
                                    "40 Foot",
                                    "40-Foot High Cube",
                                    "45-Foot High Cube",
                                ].map((option) => ({ label: option, value: option }))}
                                onChange={(value) => ""}
                                label="Container Type"
                                labelClassName="!text-[#475467]"
                                placeholder="Choose container type"
                            />
                        </div>
                        <div>
                            <Dropdown
                                // value=""
                                options={[
                                    "Full Container Load (FCL)",
                                    "Less Container Load (LCL)",
                                    "Full Truckload (FTL)",
                                    "Less Than Truckload (LTL)",
                                ].map((option) => ({ label: option, value: option }))}
                                onChange={(value) => ""}
                                label="Load Type"
                                labelClassName="!text-[#475467]"
                                placeholder="Choose Load type"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-4 !gap-3.5">
                        <div className="col-span-2">
                            <Dropdown
                                // value=""
                                options={[
                                    "Carton/Box",
                                    "Crate",
                                    "Pallet",
                                    "Bag/Sack",
                                    "Bundle",
                                    "Container/Loose",
                                ].map((option) => ({ label: option, value: option }))}
                                onChange={(value) => ""}
                                label="Package Type"
                                labelClassName="!text-[#475467]"
                                placeholder="Choose package type"
                            />
                        </div>

                        <div>
                            <Input label="Total Weight" placeholder="E.g. 100" unit="Kg" />
                        </div>
                        <div>
                            <Input
                                onChange={(value) => ""}
                                label="Total Quantity"
                                placeholder="No of units"
                            />
                        </div>
                    </div>
                    <>
                        <p className="font-medium text-sm text-left text-[#475467] !mb-2 !mt-5">
                            Package Dimensions (Optional)
                        </p>
                        <div className="flex items-center">
                            <Input placeholder="Length" unit="cm" />
                            <span className="font-medium text-[#475467] text-sm !mx-2.5">X</span>
                            <Input placeholder="Width" unit="cm" />
                            <span className="font-medium text-[#475467] text-sm !mx-2.5">X</span>
                            <Input placeholder="Height" unit="cm" />
                        </div>
                    </>

                    <Input
                        label="Describe Items"
                        type="textarea"
                        placeholder="E.g. salmon fishes, bluetooth speakers"
                    />

                    <div className="!mb-5">
                        <p className="font-medium text-sm text-left text-main-dark !mb-2 !mt-[18px]">
                            Add Purchase Orders Dimensions (Optional)
                        </p>
                        <PurchaseOrdersSelect />
                    </div>

                    <SupportDocs
                        addDocs={addDocs}
                        setAddDocs={setAddDocs}
                        file={file}
                        setFile={setFile}
                        showDelete={!previewShipment}
                    />

                    <div className="flex !gap-9 justify-end !mt-6">
                        <button type="button text-[#1D2939] font-[450] !py-2 !px-6">Back</button>
                        <button
                            className={`!py-2 !px-6 text-white rounded-lg font-[450] ${
                                true ? "bg-[#D9CDFF] pointer-events-none" : "bg-secondary"
                            }`}
                        >
                            Continue
                        </button>
                    </div>
                </form> */}
            </div>
        </>
    );
};

export default CreateShipment;
