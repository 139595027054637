import {
    AddIcon,
    ArrowIcon,
    BoxIcon,
    DocumentIcon,
    EyeCloseIcon,
    EyeIcon,
    LockIcon,
    SendIcon,
} from "asset/icons";
import Navbar from "component/layout/navbar";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NGNFlagImg from "asset/images/Currency.svg";
import GBPFlagImg from "asset/images/GB.svg";
import USFlagImg from "asset/images/US.png";
import EUFlagImg from "asset/images/EU.svg";
import Analytics from "./component/analytics";
import Walkthrough from "./component/walkthrough";
import ComponentLoader from "component/componentloader";
import useGetRequest from "hooks/useGetRequest";
import { useDispatch, useSelector } from "react-redux";
import * as dayjs from "dayjs";
import AmountCheck from "component/amountCheck";
import emptyTransactionsImg from "../asset/images/Empty.svg";
import emptyInvoiceImg from "../asset/images/emptyinvoice.svg";
import capitalizeWords from "utilities/capitalizeWords";
import TransactionDetailsModal from "component/transactiondetailsModal";
import MyVerticallyCenteredModal from "component/switchaccountmodal";
import axios from "axios";
import Divider from "./component/listDivider";
import Transaction from "./component/transaction";
import ListEmpty from "component/listEmpty";
import sortListByDate from "utilities/sortListByDate";
import useScreenSize from "hooks/useScreenSize";
import { saveToStorage } from "utilities/localStorageUtils";
import Rating from "./component/rating";
import currencyPairCheck from "utilities/currencyPairCheck";
import SendMoneyModal from "flex/SendMoneyModal";
import { Helmet } from "react-helmet";

const Dashboard = () => {
    const [registrationCompleted] = useState(true);
    const [tourStep, setTourStep] = useState(0);
    const [takenTour, _setTakenTour] = useState(true);
    const { users: user, business, currency: defaults } = useSelector((state) => state);
    const navigate = useNavigate();
    // const { state } = useLocation();

    const [fetchTransactions, transactions, transactionsLoading, _, __, setTransactionsLoading] =
        useGetRequest();
    const [fetchActiveLoans, activeLoans] = useGetRequest();
    const [fetchRatingData, ratingData, ratingDataLoading] = useGetRequest();
    const [fetchWallets, wallets] = useGetRequest();
    const [fetchOrdersSummary, orderSummary] = useGetRequest();
    const [fetchInvoiceSummary, _invoiceSummary] = useGetRequest();
    const [searchs, setsearch] = useState("");
    const [fetchInvoices, invoices, invoicesLoading, ___, ____, setInvoicesLoading] =
        useGetRequest();
    const [transactionsdetails, settransactiondetails] = useState([]);
    const [datatype, setdatatype] = useState("");
    const [showtransactiondetails, setshowtransactiondetails] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [hideAmounts, setHideAmounts] = useState(false);
    const [showRating, setShowRating] = useState(false);
    const dispatch = useDispatch();
    const [alldatawallet, setalldatawallet] = useState([]);
    const { screenWidth } = useScreenSize();
    const [balance, setbalance] = useState(0);
    const [sendMoneyModal, setSendMoneyModal] = useState(false);

    const setTakenTour = (bool) => {
        saveToStorage("tdv-takenTour", bool);
        _setTakenTour(bool);
    };

    const setdefault = (curr) => dispatch({ type: "CURRENCY", payload: curr });

    const reqConfig = {
        headers: {
            "x-business-id": business?._id,
        },
    };

    useEffect(() => {
        if (business?._id) {
            fetchTransactions("/v1/wallets/transactions", reqConfig);
            fetchInvoices("/v1/invoice/all", reqConfig);
            fetchWallets("/v1/wallets/accounts", reqConfig);
            fetchActiveLoans("/v1/finance/summery", reqConfig, false);
            fetchRatingData("/v1/reviews?version=1", reqConfig, false);
        } else {
            setTimeout(() => {
                setTransactionsLoading(false);
                setInvoicesLoading(false);
            }, 2500);
        }
    }, [business?._id]);

    useEffect(() => {
        if (!ratingDataLoading && !ratingData) {
            setTimeout(() => {
                setShowRating(true);
            }, 3500);
        }
    }, [ratingData, ratingDataLoading]);

    useEffect(() => {
        const fetchwallet = async () => {
            await axios
                .get(`/v1/wallets/account?currency=${defaults}`, reqConfig)

                .then((res) => {
                    // console.log(res);
                    setalldatawallet(res.data.data);
                    setbalance(res.data.data.balance);
                })
                .catch((e) => {
                    //  console.log(e);
                    setbalance(0);
                });
        };
        business?._id && fetchwallet();

        if (business?._id && defaults) {
            fetchOrdersSummary(`/v1/flex/orders/summary?currency=${defaults}`, reqConfig);
            fetchInvoiceSummary(`/v1/invoice/summary?currency=${defaults}`, reqConfig);
        }
    }, [defaults, business?._id]);

    useEffect(() => {
        const checkWallet = async () => {
            if (localStorage.getItem("default") === null) {
                if (business?.country === "Nigeria") {
                    setdefault("NGN");
                    localStorage.setItem("default", "NGN");
                    await axios
                        .get(`v1/wallets/account?currency=NGN`, reqConfig)
                        .then((res) => {
                            // console.log(res);
                            setalldatawallet(res.data.data);
                            setbalance(res.data.data.balance);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else if (business?.country === "United Kingdom") {
                    setdefault("EUR");
                    localStorage.setItem("default", "EUR");
                    await axios
                        .get(`v1/wallets/account?currency=EUR`, reqConfig)
                        .then((res) => {
                            // console.log(res);
                            setalldatawallet(res.data.data);
                            setbalance(res.data.data.balance);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else if (business?.country === "United State") {
                    setdefault("USD");
                    localStorage.setItem("default", "USD");
                    await axios
                        .get(`v1/wallets/account?currency=USD`, reqConfig)
                        .then((res) => {
                            // console.log(res);
                            setalldatawallet(res.data.data);
                            setbalance(res.data.data.balance);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else {
                    setdefault("GBP");
                    localStorage.setItem("default", "GBP");
                    await axios
                        .get(`v1/wallets/account?currency=GBP`, reqConfig)
                        .then((res) => {
                            // console.log(res);
                            setalldatawallet(res.data.data);
                            setbalance(res.data.data.balance);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            } else {
                setdefault(localStorage.getItem("default"));
                await axios
                    .get(
                        `v1/wallets/account?currency=${localStorage.getItem("default")}`,
                        reqConfig
                    )
                    .then((res) => {
                        // console.log(res);
                        setalldatawallet(res.data.data);
                        setbalance(res.data.data.balance);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        };
        business?._id && checkWallet();
    }, [business?.country, business?._id]);

    const transactionsFilteredData = (transactions || [])?.filter(
        (item) =>
            (item.amount && item.amount.toString().includes(searchs)) ||
            (item.TransactionType &&
                item.TransactionType.toLowerCase().includes(searchs.toLowerCase())) ||
            (item.accountName && item.accountName.toLowerCase().includes(searchs.toLowerCase())) ||
            (item.bankName && item.bankName.toLowerCase().includes(searchs.toLowerCase())) ||
            (item.currency && item.currency.toLowerCase().includes(searchs.toLowerCase())) ||
            (item.source && item.source.toLowerCase().includes(searchs.toLowerCase()))
    );

    const resultTransact = sortListByDate(transactionsFilteredData);

    const filteredInvoices = (invoices || [])?.filter(({ currency }) => currency === defaults);
    const resultInvoice = sortListByDate(filteredInvoices);

    const showTransactionforEach = (transaction) => {
        setshowtransactiondetails(true);
        settransactiondetails(transaction);
    };

    const currentBalance = wallets?.find(({ currency }) => currency === defaults);
    const balanceAnalyticsAmount = Number(currentBalance?.balance).toFixed(2);
    const invoiceSummary = _invoiceSummary ? _invoiceSummary[0] : {};
    const unpaidInvoiceAmount = invoiceSummary?.totalAmountOfUnpaidInvoices
        ? Number(invoiceSummary?.totalAmountOfUnpaidInvoices).toFixed(2)
        : 0;

    // Explain to the team why this flow will be poor except if the modal is changed to a page

    // useEffect(() => {
    //     if (state?.backFromFx) {
    //         setSendMoneyModal(true);
    //         // state.backFromFx = false;
    //     }
    // }, [state?.backFromFx]);

    return (
        <>
            <Helmet>
                <title>Tradevu — dashboard</title>
            </Helmet>
            {defaults && (
                <Walkthrough
                    setStep={setTourStep}
                    step={tourStep}
                    takenTour={takenTour}
                    setTakenTour={setTakenTour}
                />
            )}

            {showRating && <Rating closeRating={() => setShowRating(false)} />}
            <Navbar tourStep={tourStep} takenTour={takenTour} />

            {!registrationCompleted && (
                <p className="bg-[#EEF4FF] py-[20px] px-6 sm:px-10 md:px-20 grid 580:flex grid-cols-[16px_1fr] gap-[7px] items-center justify-center text-main-grey text-[15px]">
                    <LockIcon className="size-4 max-580:self-start max-580:translate-y-0.5" />
                    <span className="md:flex">
                        Your account isn’t fully activated.
                        <Link className="text-[#2C53F2] font-medium inline-block mx-1.5">
                            Complete KYC
                        </Link>{" "}
                        to access all benefits.
                    </span>

                    {/* no business yet,  */}
                </p>
            )}

            <div
                className={`flex px-6 sm:px-10 md:px-20 ${
                    !registrationCompleted ? "mt-4" : "mt-10"
                }`}
            >
                <div className="mr-auto">
                    <div className="flex gap-2.5">
                        <h2 className="text-xl font-medium capitalize">
                            Hello
                            {user?.firstName &&
                                " " + capitalizeWords(`${user?.firstName} ${user?.lastName}`)}
                            ,
                        </h2>
                        {hideAmounts ? (
                            <EyeIcon
                                className="cursor-pointer self-end -translate-y-2.5"
                                onClick={() => setHideAmounts((prev) => !prev)}
                            />
                        ) : (
                            <EyeCloseIcon
                                className="cursor-pointer self-end -translate-y-[9.5px]"
                                onClick={() => setHideAmounts((prev) => !prev)}
                            />
                        )}
                    </div>
                    <div className="flex items-center mt-1">
                        <p className="mr-2 text-main-grey max-420:text-sm">
                            Overview of your activity in
                        </p>
                        <button
                            className="flex items-center bg-[#EAECF0] rounded p-1 max-375:text-sm 420:p-2 font-medium tradevu-tour-step-2"
                            onClick={() => {
                                wallets?.map((res) => {
                                    if (res.accountNumber?.length > 0) {
                                        setModalShow(true);
                                    }
                                });
                            }}
                        >
                            <img
                                src={
                                    defaults === "NGN"
                                        ? NGNFlagImg
                                        : defaults === "GBP"
                                        ? GBPFlagImg
                                        : defaults === "USD"
                                        ? USFlagImg
                                        : EUFlagImg
                                }
                                alt={defaults}
                                className={`size-4 mr-1 ${!defaults && "opacity-0"}`}
                            />{" "}
                            {defaults}
                            <ArrowIcon className={`ml-1.5 ${!defaults && "opacity-0"}`} />
                        </button>
                    </div>
                </div>

                {screenWidth >= 800 && (
                    <ButtonActions
                        tourStep={tourStep}
                        disable={!business?._id}
                        defaults={defaults}
                        balance={balance}
                        handleSendMoney={() => {
                            // setSendMoneyModal(!sendMoneyModal);
                            navigate(`/payment/select_transaction`, {
                                state: {
                                    default: defaults,
                                    balance,
                                },
                            });
                        }}
                    />
                )}
            </div>

            <div className="mx-6 sm:mx-10 md:mx-20 mt-6 grid grid-cols-[repeat(4,minmax(290px,1fr))] gap-6 overflow-x-auto [scrollbar-width:none]">
                {/* <div class="absolute inset-y-0 left-0 w-12 bg-gradient-to-r from-black/60  h-full to-transparent pointer-events-none" /> */}
                <Analytics
                    title="Your balance"
                    text={
                        <>
                            <span className="text-2xl inline-block text-main-grey -translate-y-[1px]">
                                {currencyPairCheck(defaults)}
                            </span>
                            <span className="ml-1">
                                {currentBalance?.balance
                                    ? AmountCheck(`${currentBalance?.balance}`.split(".")[0], false)
                                    : "0"}
                            </span>
                        </>
                    }
                    decimal={`${balanceAnalyticsAmount}`.split(".")[1]}
                    subTextValue={currentBalance?.accountNumber || null}
                    subText={
                        defaults === "NGN"
                            ? currentBalance?.bankName
                            : currentBalance?.accountNumber && "Tradevu Wallet"
                    }
                    hideAmount={hideAmounts}
                    fundWallet={true}
                    defaults={defaults}
                />

                <Analytics
                    title="Active orders"
                    subTextValue={
                        <>
                            {currencyPairCheck(defaults)}
                            <span className="ml-1">
                                {orderSummary?.incomingOrders?.totalAmount
                                    ? AmountCheck(orderSummary.incomingOrders.totalAmount)
                                    : "0.00"}
                            </span>
                        </>
                    }
                    text={AmountCheck(orderSummary?.incomingOrders?.count ?? 0, false)}
                    hideAmount={hideAmounts}
                />
                <Analytics
                    title="Active loans"
                    text={
                        AmountCheck(
                            activeLoans?.dueLoans + activeLoans?.processingRequests,
                            false
                        ) || "0"
                    }
                    subTextValue={AmountCheck(activeLoans?.dueLoans, false) ?? 0}
                    hideAmount={hideAmounts}
                />
                <Analytics
                    title="Unpaid invoices"
                    text={`${invoiceSummary?.totalNumberOfUnpaidInvoices ?? 0}`}
                    subTextValue={
                        <>
                            {currencyPairCheck(defaults)}
                            <span className="ml-1">{AmountCheck(unpaidInvoiceAmount)}</span>
                        </>
                    }
                    hideAmount={hideAmounts}
                />
            </div>

            {screenWidth < 800 && (
                <ButtonActions
                    tourStep={tourStep}
                    screenWidth={screenWidth}
                    disable={!business?._id}
                    defaults={defaults}
                    balance={balance}
                    handleSendMoney={() => {
                        // setSendMoneyModal(!sendMoneyModal);
                        navigate(`/payment/select_transaction`, {
                            state: {
                                default: defaults,
                                balance,
                            },
                        });
                    }}
                />
            )}

            <div
                className={`420:px-6 sm:px-10 md:px-20 pb-11 mt-4 grid grid-cols-2 !gap-6 max-820:*:col-span-2 max-420:*:rounded-none`}
            >
                <div className="bg-white rounded-2xl pt-[16px] h-max pb-[24px] border !border-[#E7E9FB] tradevu-tour-step-4">
                    <div className="flex justify-between items-center py-2 mb-3 px-[24px]">
                        <p className="font-medium text-main-grey">Recent transactions</p>
                        <button className="flex items-center bg-[#F2F4F7] rounded p-2 font-medium text-sm opacity-0">
                            Print statement
                            <ArrowIcon className="ml-1.5" />
                        </button>
                    </div>

                    <div className="max-h-[450px] overflow-y-auto px-[24px]">
                        {transactionsLoading ? (
                            <ComponentLoader free className="mx-auto mt-32 mb-44" />
                        ) : (
                            <>
                                {resultTransact.length ? (
                                    <>
                                        {resultTransact
                                            .slice(0, 10)
                                            .map(({ alphabet, record }, idx) => (
                                                <Fragment key={idx + alphabet + idx}>
                                                    <Divider date={alphabet} />
                                                    {record.map((record, recordIdx) => (
                                                        <Fragment key={record._id}>
                                                            {idx && recordIdx ? <Divider /> : null}
                                                            <Transaction
                                                                key={record._id}
                                                                balanceType={
                                                                    record.PaymentType === "credit"
                                                                        ? "+"
                                                                        : record.PaymentType ===
                                                                          "debit"
                                                                        ? "-"
                                                                        : ""
                                                                }
                                                                status={record?.status?.toLowerCase()}
                                                                title={
                                                                    record?.narration ??
                                                                    record?.TransactionType
                                                                }
                                                                time={dayjs(
                                                                    record.createdAt
                                                                ).format("h:mmA")}
                                                                amount={`${currencyPairCheck(
                                                                    record.currency
                                                                )} ${AmountCheck(record.amount)}`}
                                                                balance={`${currencyPairCheck(
                                                                    record.currency
                                                                )} ${AmountCheck(record.balance)}`}
                                                                onClick={() => {
                                                                    setdatatype("Transaction");
                                                                    showTransactionforEach(record);
                                                                }}
                                                            />
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            ))}
                                    </>
                                ) : (
                                    <ListEmpty
                                        title="There are no transactions yet"
                                        text="Start trading with Tradevu to see your recent transactions here"
                                        img={emptyTransactionsImg}
                                    />
                                )}

                                <Link className="text-[#7D14FF] text-center block text-sm mt-4 !hidden">
                                    View full transaction history
                                </Link>
                            </>
                        )}
                    </div>
                </div>

                <div className="bg-white rounded-2xl pt-[16px] h-max pb-[24px] border !border-[#E7E9FB] tradevu-tour-step-5">
                    <div className="flex justify-between items-center py-2 mb-3 px-[24px]">
                        <p className="font-medium text-main-grey">Recent Invoices</p>
                        {/* <p className="rounded-full grid place-items-center size-6 ml-2.5 mr-auto font-medium text-sm bg-[#EAECF0]">
                            40
                        </p> */}

                        <span className="flex items-center text-[15px] text-main-grey opacity-0">
                            Filter
                            <button className="flex items-center bg-[#F2F4F7] rounded p-2 font-medium text-sm ml-2">
                                All
                                <ArrowIcon className="ml-1.5" />
                            </button>
                        </span>
                    </div>

                    <div className="max-h-[450px] overflow-y-auto px-[24px]">
                        {invoicesLoading ? (
                            <ComponentLoader free className="mx-auto mt-32 mb-44" />
                        ) : (
                            <>
                                {resultInvoice.length ? (
                                    <>
                                        {resultInvoice
                                            .slice(0, 10)
                                            .map(({ alphabet, record }, idx) => (
                                                <Fragment key={idx + alphabet + idx}>
                                                    <Divider date={alphabet} />
                                                    {record.map((record, recordIdx) => (
                                                        <Fragment key={record._id}>
                                                            {recordIdx ? <Divider /> : null}
                                                            <Transaction
                                                                title={record.invoiceTitle}
                                                                amount={`${currencyPairCheck(
                                                                    record.currency
                                                                )} ${AmountCheck(record.amount)}`}
                                                                invoiceType
                                                                status={record?.paymentStatus?.toLowerCase()}
                                                                type={`${record.firstName} ${record.lastName}`}
                                                                time={dayjs(
                                                                    record.createdAt
                                                                ).format("h:mmA")}
                                                                onClick={() => {
                                                                    setdatatype("Invoice");
                                                                    showTransactionforEach(record);
                                                                }}
                                                            />
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            ))}
                                    </>
                                ) : (
                                    <ListEmpty
                                        img={emptyInvoiceImg}
                                        title="There are no invoice yet"
                                        text="Start trading with Tradevu to see your recent invoice here"
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <MyVerticallyCenteredModal
                show={modalShow}
                setshow={setModalShow}
                onHide={() => setModalShow(false)}
                wallet={wallets}
                default={defaults}
                setdefault={setdefault}
            />

            <TransactionDetailsModal
                show={showtransactiondetails}
                setshow={setshowtransactiondetails}
                data={transactionsdetails}
                datatype={datatype}
            />

            <SendMoneyModal
                show={sendMoneyModal}
                setShow={setSendMoneyModal}
                defaults={defaults}
                balance={balance}
            />
        </>
    );
};

const ButtonActions = ({ tourStep, screenWidth, disable, defaults, balance, handleSendMoney }) => {
    const [createNew, setCreateNew] = useState(false);
    const navigate = useNavigate();
    const isBelow400 = screenWidth <= 400;

    return (
        <div
            className={`flex ${screenWidth < 800 && "mt-4 mb-9 mr-auto w-max px-6 sm:pl-10"} ${
                isBelow400 && "justify-between !w-full"
            } ${disable && "pointer-events-none"}`}
        >
            <button
                className={`!border !border-[#EBE4FF] bg-[#EBE4FF] self-end text-[#5E01D6] rounded-lg h-10 400:pl-4 max-400:grid max-400:place-items-center 400:pr-6 flex items-center 400:ml-auto max-400:w-1/2 tradevu-tour-step-3`}
                onClick={() => handleSendMoney()}
            >
                {!isBelow400 && <SendIcon className="mr-2 *:stroke-[#5E01D6]" />} Send money
            </button>
            <button
                className="text-white bg-[#5E01D6] self-end rounded-lg h-10 400:pl-4 400:pr-6 flex items-center ml-3 400:ml-4 max-400:w-1/2 relative max-400:grid max-400:place-items-center tradevu-tour-step-3"
                onClick={() => setCreateNew((prev) => !prev)}
            >
                {!isBelow400 && <AddIcon className="[&_*]:!fill-white mr-2" />} Create new
                <div
                    className={`bg-white shadow-dropdown rounded-lg py-2 px-[16px] w-[200px] absolute z-[90] -right-0.5 transition-all duration-300 pointer-events-none ${
                        createNew ? "top-[115%] opacity-100" : "top-[200%] opacity-0 invisible"
                    }`}
                >
                    {/*   { name: "New shipment", Icon: ShipIcon }, */}
                    {[
                        {
                            name: "New invoice",
                            Icon: DocumentIcon,
                            link: "/flex/invoice",
                        },
                        {
                            name: "New order",
                            Icon: BoxIcon,
                            link: "/flex/create-po",
                        },
                    ].map(({ name, Icon, link }, idx) => (
                        <p
                            className={`text-main flex items-center gap-4 h-12 pointer-events-auto ${
                                idx && "border-t"
                            }`}
                            key={name}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                navigate(link);
                            }}
                        >
                            <Icon className="[&_*]:stroke-[1.5]" /> {name}
                        </p>
                    ))}
                </div>
            </button>
        </div>
    );
};

export default Dashboard;
