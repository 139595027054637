const getInitials = (str, len, space) => {
    const newStr = `${str}`
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join(space ? " " : "");

    if (len) {
        return newStr.substring(0, len);
    } else {
        return newStr.substring(0, 2);
    }
};

export default getInitials;
