import React from "react";
import { useNavigate } from "react-router-dom";

const NotificationBar = ({
    message = "",
    actionText,
    actionPath = "",
    icon,
    className = "bg-[#FFF9E6] text-[#475467]",
    actionClassName = "text-[#DB6704]",
}) => {
    const navigate = useNavigate();

    const handleActionClick = () => {
        if (actionPath) {
            navigate(actionPath);
        }
    };

    return (
        <div
            className={`w-full flex items-center justify-center py-3 px-4 rounded-lg shadow-sm ${className}`}
        >
            {icon && <span className={`mr-3 flex-shrink-0 ${actionClassName}`}>{icon}</span>}
            <p className="text-sm font-medium text-center">
                {message}
                {actionText && (
                    <button
                        onClick={handleActionClick}
                        className={`ml-2 font-medium hover:underline ${actionClassName}`}
                    >
                        {actionText}
                    </button>
                )}
            </p>
        </div>
    );
};

export default NotificationBar;
