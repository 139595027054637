import { CheckCircleIcon, CloseIcon, MoreIcon } from "asset/icons";
import axios from "axios";
import ActionDropdown from "component/actionDropdown";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorAlert, successAlert } from "utilities/alerts";
import { Bars } from "react-loader-spinner";
import Input from "component/form/Input";
import currencyPairCheck from "utilities/currencyPairCheck";
import { formatMoney } from "utilities/formatMoney";
import OTPInput from "component/form/OTPInput";
import Dropdown from "component/form/dropDown";
import { currencies } from "constants/currency";
import useGetRequest from "hooks/useGetRequest";
import capitalizeWords from "utilities/capitalizeWords";

const MoreActions = ({
    business,
    order,
    orderId,
    tab,
    className,
    updateOrder,
    dropClassName,
    disableCancel,
    status,
    currency,
    wallets,
    totalAmount,
    isTradevuUser,
}) => {
    const paymentDisables = ["pending", "rejected", "cancelled", "delayed", "decline", "declined"];
    const navigate = useNavigate();
    const [recordPaymentModal, setRecordPaymentModal] = useState(false);
    const [makePaymentModal, setMakeRecordPaymentModal] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type");

    const userIsSupplier = type === "outbound";

    const cancelOrder = async () => {
        try {
            setCancelLoading(true);
            await axios.patch(`/v1/flex/order/${orderId}`, {
                orderStatus: "CANCELLED",
            });
            updateOrder("CANCELLED");
        } catch (error) {
            errorAlert(error);
        } finally {
            setCancelLoading(false);
        }
    };
    const see = paymentDisables.includes(status) && isTradevuUser;

    return (
        <>
            <ActionDropdown
                dropClassName={dropClassName}
                className={`${cancelLoading && "pointer-events-none"}`}
                element={<MoreIcon className={`size-6 cursor-pointer ${className}`} />}
                options={[
                    {
                        label: "Edit order",
                        action: () =>
                            navigate(`/flex/create-po?tab=${tab}&orderId=${orderId}&edit=true`),
                        disabled: status !== "pending",
                    },
                    {
                        label: "Make a payment",
                        action: () => setMakeRecordPaymentModal(true),
                        closeAfterClick: true,
                        disabled:
                            business._id === order?.inbound?.businessId
                                ? true
                                : isTradevuUser
                                ? paymentDisables.includes(status)
                                : !paymentDisables.includes(status),
                    },
                    {
                        label: "Record a payment",
                        action: () => setRecordPaymentModal(true),
                        closeAfterClick: true,
                        disabled: paymentDisables.includes(status),
                    },
                    { label: "Ship this order", disabled: true },
                    { label: "Add to existing shipment", disabled: true },
                    {
                        label: "Duplicate this order",
                        action: () => navigate(`/flex/create-po?tab=${tab}&orderId=${orderId}`),
                    },
                    {
                        label: (
                            <>
                                Cancel order{" "}
                                {cancelLoading && <Bars color="#6F00FF" width={18} height={18} />}
                            </>
                        ),
                        className: "!gap-5",
                        action: () => cancelOrder(),
                        disabled: disableCancel,
                    },
                ]
                    .filter(({ disabled }) => !disabled)
                    .map(({ label, action, closeAfterClick, className }) => ({
                        label,
                        value: label,
                        action,
                        closeAfterClick,
                        className,
                    }))}
            />
            {makePaymentModal && (
                <MakePayment
                    currency={currency}
                    wallets={wallets}
                    orderId={orderId}
                    close={() => setMakeRecordPaymentModal(false)}
                />
            )}

            {recordPaymentModal && (
                <RecordPayment
                    order={order}
                    orderId={orderId}
                    totalAmount={totalAmount}
                    close={() => setRecordPaymentModal(false)}
                />
            )}
        </>
    );
};

const MakePayment = ({ currency, wallets, orderId, close }) => {
    const [step, setStep] = useState(0);
    const [otp, setOTP] = useState("");
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState();
    const currentBalance = wallets?.find(({ currency: curr }) => curr === currency);

    const makePayment = async () => {
        try {
            setLoading(true);
            await axios.post("/v1/users/verify-pin", { pin: otp });
            await axios.post(`/v1/flex/order/makepayment/${orderId}`, { amount });
            successAlert(`${formatMoney(amount)} paid successfully.`);
            close();
        } catch (error) {
            errorAlert(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="fixed left-0 top-0 h-screen w-screen bg-[#1D29394D] z-[5000] grid place-items-center cursor-auto"
            onClick={(evt) => evt.stopPropagation()}
        >
            <div className="bg-page-bg rounded-2xl w-[95%] sm:w-[560px] !pt-1">
                <h3 className="flex justify-between text-main-dark font-semibold text-[23px] !px-6 !py-4 -mb-1.5">
                    {step ? "Confirm" : "Make a"} payment
                    <CloseIcon
                        className={`size-6 cursor-pointer translate-x-1 ${
                            loading && "pointer-events-none *:stroke-main-grey/60"
                        }`}
                        onClick={close}
                    />
                </h3>
                <p className="text-[#6B7280] !px-6 font-[380]">
                    {step
                        ? "Enter your PIN to confirm this payment"
                        : "Pay for this purchase order from your Tradevu account"}
                </p>

                {step ? (
                    <div className="!px-6 mt-9 mb-[25px]">
                        <OTPInput otp={otp} setOTP={setOTP} />
                    </div>
                ) : (
                    <>
                        <div className="flex items-end !gap-2 !px-6 !mt-7">
                            <div className="basis-[150px] *:first-of-type:pointer-events-none">
                                <Input
                                    placeholder="NGN"
                                    value={currency}
                                    require={false}
                                    label="Amount to pay"
                                    labelClassName="!text-[#667085] !mb-1"
                                />
                            </div>

                            <Input
                                onChange={setAmount}
                                type="number"
                                value={amount}
                                placeholder="3,000,000"
                                formatType="integer"
                            />
                        </div>
                        <p className="!px-6 !mt-1.5 font-normal text-[13px] text-main-grey">
                            Current balance:
                            <span className="font-[450] ml-1.5">
                                {currencyPairCheck(currency)} {formatMoney(currentBalance?.balance)}
                            </span>
                        </p>
                    </>
                )}

                <div className="flex justify-end items-center !gap-6 !border-t !border-[#E7E9FB] !py-[18px] !px-6 mt-[21px]">
                    {!!step && (
                        <span
                            className="cursor-pointer text-main-dark/85"
                            onClick={() => setStep(0)}
                        >
                            Go back
                        </span>
                    )}
                    <button
                        className={`bg-[#6F00FF] rounded-lg h-10  ${
                            step ? "w-[101px]" : "w-[154px]"
                        } text-[#FBFAFF] font-[450] disabled:bg-secondary/30`}
                        disabled={(step ? otp.length !== 4 : !amount) || loading}
                        onClick={() => (step ? makePayment() : setStep(1))}
                    >
                        {loading ? (
                            <Bars
                                color="#fff"
                                height={23}
                                width={23}
                                wrapperClass="w-max mx-auto"
                            />
                        ) : step ? (
                            "Submit"
                        ) : (
                            "Make payment"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export const RecordPayment = ({ order, orderId, close, totalAmount, refetchActivity }) => {
    const [inputManually, setInputManually] = useState(false);
    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [currency, setCurrency] = useState("");
    const [amount, setAmount] = useState("");
    const [paymentDate, setPaymentDate] = useState("");
    const [fetchTransactions, transactions, transactionsLoading] = useGetRequest();

    const recordPayment = async () => {
        try {
            setLoading(true);
            await axios.post(`/v1/flex/order/payment/${orderId}`, {
                paymentMethod,
                currency: currency || order?.currency,
                amount,
                paymentDate,
            });
            successAlert("Payment recorded.");
            if (typeof refetchActivity === "function") {
                refetchActivity();
            }
            close();
        } catch (error) {
            errorAlert(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (order?.currency && paymentMethod === "Tradevu transfer") {
            fetchTransactions(`/v1/wallets/transactions?currency=${order.currency}`);
        }
    }, [order?.currency, paymentMethod]);

    return (
        <div
            className="fixed left-0 top-0 h-screen w-screen bg-[#1D29394D] z-[5000] grid place-items-center cursor-auto"
            onClick={(evt) => evt.stopPropagation()}
        >
            <div className="bg-page-bg rounded-2xl w-[95%] sm:w-[560px] !pt-1">
                <h3 className="flex justify-between text-main-dark font-semibold text-[23px] !px-6 !py-4 -mb-1.5">
                    Record a payment
                    <CloseIcon
                        className={`size-6 cursor-pointer translate-x-1 ${
                            loading && "pointer-events-none *:stroke-main-grey/60"
                        }`}
                        onClick={close}
                    />
                </h3>
                <p className="text-[#6B7280] !px-6 font-[380]">
                    Record a payment you've already made for this purchase order
                </p>

                <div className="!px-6 !mt-6">
                    {/* Payment Method Dropdown */}
                    <Dropdown
                        value={paymentMethod}
                        onChange={(method) => {
                            setPaymentMethod(method);
                            setInputManually(false); // Reset manual input for other methods
                        }}
                        options={[
                            "Tradevu transfer",
                            "Bank transfer",
                            "Cash",
                            "Card payment",
                            "Other",
                        ].map((item) => ({ label: item, value: item }))}
                        label="Payment method"
                        labelClassName="!text-[#475467]"
                        placeholder="Select payment method"
                    />

                    {paymentMethod === "Tradevu transfer" && (
                        <>
                            {inputManually ? (
                                <>
                                    {/* Manual Input Fields */}
                                    <p
                                        className="text-[#475467] font-medium text-[12.5px] !mt-1 cursor-pointer"
                                        onClick={() => setInputManually(false)}
                                    >
                                        Choose from recent transactions
                                    </p>
                                    <div className="flex !gap-2 items-end">
                                        <div className="w-[125px]">
                                            <Dropdown
                                                value={order?.currency || "NGN"}
                                                onChange={() => {}}
                                                options={currencies.map(({ title }) => ({
                                                    label: title,
                                                    value: title,
                                                }))}
                                                label="Amount paid"
                                                labelClassName="!text-[#475467]"
                                                placeholder="NGN"
                                                readOnly
                                            />
                                        </div>

                                        <Input
                                            formatType="integer"
                                            type="number"
                                            value={amount}
                                            onChange={setAmount}
                                            max={totalAmount}
                                            placeholder={`Max: ${formatMoney(totalAmount)}`}
                                        />
                                    </div>
                                    <Input
                                        type="date"
                                        onChange={setPaymentDate}
                                        label="Payment date"
                                        labelClassName="!text-[#475467] !mt-5"
                                        min=""
                                        placeholder="Select payment date"
                                        value={paymentDate}
                                    />
                                </>
                            ) : (
                                <>
                                    {/* Recent Transactions Dropdown */}
                                    <p
                                        className="text-[#475467] font-medium text-[12.5px] !mt-1 cursor-pointer"
                                        onClick={() => {
                                            setAmount("");
                                            setCurrency("");
                                            setPaymentDate("");
                                            setInputManually(true);
                                        }}
                                    >
                                        Input manually
                                    </p>
                                    <Dropdown
                                        value={`${
                                            transaction
                                                ? `${currencyPairCheck(
                                                      transaction.currency
                                                  )} ${formatMoney(
                                                      transaction.amount.toFixed(2)
                                                  )} - ${capitalizeWords(
                                                      transaction.accountName
                                                  )} (${transaction.bankName})`
                                                : ""
                                        }`}
                                        options={(transactionsLoading
                                            ? ["loading..."]
                                            : transactions?.length
                                            ? transactions
                                            : ["No transactions"]
                                        ).map((item, idx) => ({
                                            label:
                                                typeof item === "string" ? (
                                                    item
                                                ) : (
                                                    <>
                                                        {!idx && (
                                                            <p
                                                                className="text-main-grey font-medium mt-[15px] cursor-text !mb-2 !px-5"
                                                                onClick={(evt) =>
                                                                    evt.stopPropagation()
                                                                }
                                                            >
                                                                Recent transactions
                                                            </p>
                                                        )}
                                                        <p
                                                            className={`flex relative justify-between text-[#475467] font-medium py-[18px] transition-all ${
                                                                item._id === transaction?._id
                                                                    ? "!pl-5 !pr-12"
                                                                    : "!px-5"
                                                            } ${
                                                                idx && "!border-t !border-[#E7E9FB]"
                                                            }`}
                                                            onClick={() => {
                                                                setTransaction(item);
                                                                setAmount(item.amount.toFixed(2));
                                                                setPaymentDate(
                                                                    item.transactionDate
                                                                );
                                                                setCurrency(item.currency);
                                                            }}
                                                        >
                                                            {currencyPairCheck(item.currency)}{" "}
                                                            {formatMoney(item.amount.toFixed(2))}
                                                            <span>
                                                                to{" "}
                                                                {capitalizeWords(item.accountName)}
                                                                <span className="block ml-auto font-normal text-right">
                                                                    {item.bankName}
                                                                </span>
                                                            </span>
                                                            {item._id === transaction?._id && (
                                                                <CheckCircleIcon className="absolute right-4 top-[40%]" />
                                                            )}
                                                        </p>
                                                    </>
                                                ),
                                            value: item?._id,
                                            disabled: typeof item === "string",
                                            className: typeof item !== "string" && "!block !p-0",
                                            action: () => {},
                                        }))}
                                        label="Recent transactions"
                                        labelClassName="!text-[#475467]"
                                        placeholder="Select recent transfers"
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/* Default Manual Input Fields for Other Payment Methods */}
                    {paymentMethod && paymentMethod !== "Tradevu transfer" && (
                        <>
                            <div className="flex !gap-2 items-end">
                                <div className="w-[125px]">
                                    <Dropdown
                                        value={order?.currency || "..."}
                                        onChange={() => {}}
                                        options={currencies.map(({ title }) => ({
                                            label: title,
                                            value: title,
                                        }))}
                                        label="Amount paid"
                                        labelClassName="!text-[#475467]"
                                        placeholder="NGN"
                                        readOnly
                                    />
                                </div>

                                <Input
                                    formatType="integer"
                                    type="number"
                                    value={amount}
                                    onChange={setAmount}
                                    max={order.totalAmount - order.paidAmount}
                                    placeholder={`Max: ${order.totalAmount - order.paidAmount}`}
                                />
                            </div>
                            <Input
                                type="date"
                                onChange={setPaymentDate}
                                label="Payment date"
                                labelClassName="!text-[#475467] !mt-5"
                                min=""
                                placeholder="Select payment date"
                                value={paymentDate}
                            />
                        </>
                    )}
                </div>

                <div className="flex justify-end items-center !gap-6 !border-t !border-[#E7E9FB] !py-[18px] !px-6 !mt-5">
                    <button
                        className={`bg-[#6F00FF] rounded-lg h-10 w-[115px] text-[#FBFAFF] font-[450] disabled:bg-secondary/30`}
                        disabled={!amount || !paymentMethod || !paymentDate || loading}
                        onClick={recordPayment}
                    >
                        {loading ? (
                            <Bars
                                color="#fff"
                                height={23}
                                width={23}
                                wrapperClass="w-max mx-auto"
                            />
                        ) : (
                            "Continue"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MoreActions;
