import axios from "axios";
import { ENDPOINTS } from "constants/endpoints";

export const handleFetchAllBanks = async (currency) => {
    try {
        const resp = await axios.get(ENDPOINTS.getAllBanks(currency));
        return resp.data;
    } catch (error) {
        return error;
    }
};

export const handleVerifyAccount = async (accountNumber, bankCode) => {
    try {
        const resp = await axios.post(ENDPOINTS.verifyAccount(accountNumber, bankCode), {
            accountNumber,
            bankCode,
        });
        return resp.data;
    } catch (error) {
        return error;
    }
};

// Ask for the update on the transaction fee
export const getTransactionFee = async () => {
    try {
        const response = await axios.get(ENDPOINTS.getTransactionFee);
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleTransferFunds = async (isLocalTransfer, data) => {
    try {
        const response = await axios.post(
            isLocalTransfer ? ENDPOINTS.transferFunds : ENDPOINTS.internationalTransfer,
            data
        );
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleVerifyPin = async (data) => {
    try {
        const response = await axios.post(ENDPOINTS.verifyPin, data);
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleSaveBeneficiary = async (data) => {
    try {
        const response = await axios.post(ENDPOINTS.saveBeneficiary, data);
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetBeneficiary = async (currency) => {
    try {
        const response = await axios.get(ENDPOINTS.getBeneficiary(currency));
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetInvoiceSummary = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getInvoiceSummary, {
            headers: {
                "x-business-id": data.businessId,
            },
            params: {
                currency: data.currency,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetAllInvoice = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getAllInvoice, {
            headers: {
                "x-business-id": data.businessId,
            },
            params: {
                currency: data.currency,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};
export const handleGetAllUsers = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getAllUsers(data?.type), {
            headers: {
                "x-business-id": data.businessId,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetSingleUsers = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getSingleUser(data?.id), {
            headers: {
                "x-business-id": data.businessId,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};