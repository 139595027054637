import { AddIcon, AddProfileIcon, CheckCircleIcon, SearchIcon } from "asset/icons";
import { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "style/main.css";
import "style/rightside.css";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";
import getInitials from "utilities/getInitials";
import groupByFirstLetter from "utilities/groupByFirstLetter";

function BeneficiaryModal({
    show,
    setShow,
    setShowBank,
    beneficiaries,
    setSelectedBeneficiary,
    selectedBeneficiary,
}) {
    const [searchTerm, setSearchTerm] = useState("");

    const closeModal = () => {
        var datashow = show;
        setShow(!datashow);
    };

    let groupedBeneficiaries = groupByFirstLetter(beneficiaries);

    return (
        <div className="w-full h-full modal">
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="border-0"
            >
                <Modal.Body
                    style={{
                        padding: "0px",
                        border: "none",
                    }}
                >
                    <div
                        className="p-[20px] pb-0"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        <div className="flex items-center justify-between mb-3">
                            <p className="text-xl font-semibold">Add recipient details</p>
                            <span
                                className="cursor-pointer material-icons"
                                style={{ color: "#667085" }}
                                onClick={closeModal}
                            >
                                close
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                setShow(false);
                                setShowBank(true);
                            }}
                            className="w-full h-[55px] bg-white rounded-[8px] p-[10px] flex items-center justify-between cursor-pointer mb-2"
                        >
                            <div className="flex items-center">
                                <div className="w-[35px] h-[35px] rounded-full bg-[#EBE4FF] mr-2 flex items-center justify-center">
                                    <AddProfileIcon className="h-[20px] w-auto" />
                                </div>
                                <p className="text-[16px]">Send to new recipient</p>
                            </div>
                            <AddIcon className="h-[20px] w-auto" />
                        </div>

                        <p className="text-[16px] text-[#475467]">Saved beneficiaries</p>
                        <div className="flex items-center bg-[#EAECF0] h-[50px] p-[10px] mb-2 rounded-[8px]">
                            <SearchIcon className="mr-2" />
                            <input
                                placeholder="Search by name, email or business"
                                className="w-full bg-transparent shadow-none"
                                name="searchTerm"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="h-[50vh] w-full overflow-auto p-[20px]">
                        {Object.keys(groupedBeneficiaries)
                            ?.reverse()
                            ?.map((k, i) => {
                                return (
                                    <Fragment key={i}>
                                        <p className="text-sm text-[#475467] uppercase">{k}</p>
                                        <div className="w-full mb-4">
                                            {groupedBeneficiaries[k]
                                                ?.filter((user) =>
                                                    user?.name
                                                        .toLowerCase()
                                                        .includes(searchTerm.toLowerCase())
                                                )
                                                ?.map((b, j) => (
                                                    <div
                                                        key={j}
                                                        onClick={() => {
                                                            setShow(false);
                                                            setSelectedBeneficiary(b);
                                                        }}
                                                        className="first:rounded-t-[10px] cursor-pointer last:rounded-b-[10px] bg-white p-[10px] flex"
                                                    >
                                                        <div className="uppercase w-[50px] h-[50px] mr-2 rounded-full bg-[#f6f6f6] flex items-center justify-center">
                                                            {getInitials(b.name, 2)}
                                                        </div>
                                                        <div className="flex items-center justify-between w-full">
                                                            <div>
                                                                <p className="text-[#101828] text-[16px]">
                                                                    {capitalizeFirstLetter(b.name)}
                                                                </p>
                                                                <p className="text-[#667085] font-light text-sm">
                                                                    {b.bankName} • {b.accountNumber}
                                                                </p>
                                                            </div>

                                                            {selectedBeneficiary?.createdAt ===
                                                                b?.createdAt && <CheckCircleIcon />}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </Fragment>
                                );
                            })}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BeneficiaryModal;
