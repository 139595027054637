import { CloseIcon } from "asset/icons";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import bankIcon from "../asset/images/bankicon.svg";
import grayBankIcon from "../asset/images/grayBankIcon.svg";
import worldIcon from "../asset/images/grayGlobeIcon.svg";
import purpleGlobe from "../asset/images/purpleGlobe.svg";
import { useNavigate } from "react-router-dom";

const SAME_CURRENCY = "SAME-CURRENCY";
const FX_PAYMENT = "FX-PAYMENT";

const sendModeOption = [
    {
        title: "Same Currency Transfer",
        label: "Local payments with the same currency.",
        img: grayBankIcon,
        activeImg: bankIcon,
        mode: SAME_CURRENCY,
    },
    {
        title: "Make FX Payments",
        label: "Pay anyone globally, between currencies.",
        img: worldIcon,
        activeImg: purpleGlobe,
        mode: FX_PAYMENT,
    },
];

const SendMoneyModal = ({ show, setShow, defaults, balance }) => {
    const navigate = useNavigate();
    const [selectMode, setSelectedMode] = useState(null);
    const handleSelectMode = (mode) => {
        setSelectedMode(mode);
    };

    const goToRespectivePage = () => {
        if (selectMode === FX_PAYMENT) {
            navigate(`/payment/crossborder`, {
                state: {
                    default: localStorage.getItem("default"),
                    balance,
                },
            });
        }
        if (selectMode === SAME_CURRENCY) {
            navigate("/foreign/home", {
                state: {
                    default: defaults,
                    balance: balance,
                },
            });
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            fullscreen={true}
            dialogClassName="modal-dialog-override"
            contentClassName="modal-content-override"
        >
            <Modal.Header className="flex items-center justify-start bg-white">
                <div className="flex items-center justify-start bg-white gap-x-5">
                    <span onClick={() => setShow(false)} className="text-base cursor-pointer">
                        <CloseIcon className="overflow-hidden rounded-full cursor-pointer !size-4" />
                    </span>
                    <h3 className="mb-0 text-base font-normal text-[#101828]">Send Money</h3>
                </div>
            </Modal.Header>
            <Modal.Body className="bg-[#f7f8fa] flex items-start justify-center">
                <div className="flex flex-col items-start justify-start w-full p-3 md:w-4/12">
                    <p className="my-4 text-xl font-normal">Choose an option</p>

                    {sendModeOption.map(({ mode, title, label, img, activeImg }) => {
                        const isActive = selectMode === mode;
                        return (
                            <div
                                key={mode}
                                className={`w-full mt-3 mb-1 bg-white !border rounded-xl  p-4 flex items-center gap-x-3 cursor-pointer transition ${
                                    isActive ? "!border-secondary" : ""
                                }`}
                                onClick={() => handleSelectMode(mode)}
                            >
                                <div
                                    className={`flex items-center justify-center p-2 rounded-full size-12 ${
                                        isActive ? "bg-purple-50" : "bg-gray-100"
                                    }`}
                                >
                                    <img
                                        src={isActive ? activeImg : img}
                                        alt="Bank Icon"
                                        className={mode === FX_PAYMENT ? "" : "size-6"}
                                    />
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">{title}</p>
                                    <p className="text-xs font-light text-main-grey">{label}</p>
                                </div>
                            </div>
                        );
                    })}
                    <div className="flex items-center justify-end w-full mt-3">
                        <button
                            disabled={!selectMode}
                            type="submit"
                            onClick={() => goToRespectivePage()}
                            className={`flex items-center justify-center px-4 py-2 mt-3 text-base text-white btn !bg-secondary  ${
                                !selectMode ? "!bg-opacity-50" : "!bg-opacity-100"
                            }`}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SendMoneyModal;
