import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import UserTag from "../../asset/images/user-tag.svg";
import EditPerson from "../../asset/images/editperson.png";
import Trash from "../../asset/images/trash.png";
import ShoppingBag from "../../asset/icons/shopping-bag.svg";
import emptyInvoiceImg from "../../asset/images/emptyinvoice.svg";
// import { useQuery } from "react-query";
// import { handleGetSingleUsers } from "api/fetchers";
import { useSelector } from "react-redux";
import ListEmpty from "component/listEmpty";
import currencyPairCheck from "utilities/currencyPairCheck";
import { formatMoney } from "utilities/formatMoney";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";

const UserNetworkModal = ({ show, setShow, details }) => {
    const business = useSelector((state) => state.business);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("All");
    const tabs = ["All", "Pending", "Declined", "Inbound", "Outbound"];

    // const { data } = useQuery({
    //     queryKey: ["singleUser", details?._id],
    //     queryFn: () => handleGetSingleUsers({ id: details?._id, businessId: business?._id }),
    // });

    const { firstName, lastName, businessName, orders, status } = details ?? {};

    const viewOrder = (orderId) =>
        navigate(`/flex/home/manage-po?orderId=${orderId}&type=${activeTab.toLowerCase()}`);

    const completedType = (order) => {
        const type = order?.inbound?.businessId === business?._id ? "inbound" : "outbound";

        return (
            <span
                className={`text-sm !px-2 rounded-[13px] font-normal ${
                    type === "inbound"
                        ? "bg-[#DCFDDB] text-[#107C10]"
                        : "bg-[#FFEFC6] text-[#B64607]"
                }`}
            >
                {capitalizeFirstLetter(type)}
            </span>
        );
    };

    const filteredList =
        activeTab === "All"
            ? orders
            : activeTab === "Inbound"
            ? orders?.filter((item) => item.inbound?.businessId === business?._id)
            : activeTab === "Outbound"
            ? orders?.filter((item) => item.inbound?.businessId !== business?._id)
            : orders?.filter((item) => {
                  const { status } = item;

                  return status?.toLowerCase() === activeTab?.toLowerCase();
              });

    return (
        <div>
            <Modal
                show={show}
                backdrop="false"
                className="rightsidemodal"
                tabIndex="-1"
                keyboard={false}
                onHide={() => setShow(false)}
            >
                <div className="py-4 pl-4 !pr-10">
                    <div className="gap-3 mb-3 d-flex align-items-center">
                        <span
                            className="cursor-pointer material-icons text-main-grey"
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            arrow_back
                        </span>
                        <span className="text-base font-medium text-main-grey">BACK TO FLEX</span>
                    </div>
                    <h2 className="mt-2 text-3xl font-normal">Partners Details</h2>

                    <div className="w-full px-3 py-3 mt-3 bg-white border-y-[0.5px] border-x-[0.5px]  rounded-lg">
                        {/* User details */}
                        <div className="flex items-center gap-3 mb-3">
                            {/* <img
                                src="https://via.placeholder.com/50"
                                alt="User"
                                className="w-12 h-12 rounded-full"
                            /> */}
                            <div className="intial !size-14">
                                {firstName?.[0]}
                                {lastName?.[0]}
                            </div>
                            <div className="text-left">
                                <p className="font-medium text-black">
                                    {firstName} {lastName}
                                </p>
                                <p className="text-sm font-light text-gray-500">{businessName}</p>
                            </div>
                            <p className="ml-auto text-sm font-light text-gray-400">
                                {orders && orders?.length ? orders?.length : "No order"}
                            </p>
                        </div>

                        {/* Role and Status */}
                        <div className="flex items-center justify-between px-2 py-2 rounded-lg bg-secondary-20">
                            <div className="flex items-center gap-2">
                                <img src={UserTag} alt="User Tag" />
                                <p className="text-sm font-normal !text-secondary">Supplier</p>
                            </div>
                            <p className="text-sm font-normal text-gray-700">{status}</p>
                        </div>
                    </div>

                    <div className="flex gap-3 py-2 my-4 border-t-[0.5px] border-b-[0.5px]">
                        <button className="flex items-center gap-1 text-sm font-normal !text-secondary">
                            <img src={EditPerson} alt="Edit person" /> Edit partner
                        </button>
                        <button className="flex items-center gap-1 text-sm font-normal text-red-10">
                            <img src={Trash} alt="Trash Icon" className="size-4" /> Delete buyer
                        </button>
                    </div>

                    <div>
                        <p className="mb-2 text-sm font-normal">Orders</p>
                        <div className="flex gap-2 pb-2 mb-4">
                            {tabs.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`text-sm px-3 py-1 rounded-2xl ${
                                        activeTab === tab
                                            ? "bg-yellow-10 text-red-20 font-medium"
                                            : "text-gray-600"
                                    }`}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="w-full">
                        {/* <div className="flex items-center gap-2 mb-3">
                            <p className="text-xs text-gray-400">TODAY</p>
                            <div className="border-t-[0.5px] w-full" />
                        </div> */}
                        {filteredList?.length ? (
                            filteredList?.map((order, index) => (
                                <div
                                    key={index}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        viewOrder(order?._id);
                                    }}
                                    className="flex items-center cursor-pointer my-2 justify-between p-2 rounded-md border-[0.5px]"
                                >
                                    <div className="flex items-start gap-2 pb-2">
                                        <div className="flex items-center justify-center p-2 rounded-full bg-[#FFF5ED]">
                                            <img
                                                src={ShoppingBag}
                                                alt="Shopping Bag Icon"
                                                className="size-4"
                                            />
                                        </div>
                                        <div className="">
                                            <p className="text-sm font-normal text-gray-800">
                                                {order?.title} {completedType(order)}
                                            </p>
                                            <p className="mt-1 text-xs font-light text-gray-500">
                                                {dayjs(order?.createdAt).format("D MMM YYYY")} •{" "}
                                                <span className="capitalize">{order?.status}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <p className="text-sm font-normal text-main-grey">
                                            Paid: {currencyPairCheck(order?.currency)}
                                            {formatMoney(order?.paidAmount)}
                                        </p>
                                        <p className="mt-1 text-xs text-main-grey">
                                            Total: {currencyPairCheck(order?.currency)}
                                            {formatMoney(order?.totalAmount)}
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <ListEmpty
                                title={`You don't have any ${
                                    activeTab === "All" ? "" : activeTab.toLowerCase()
                                } orders yet`}
                                text="Track orders between you and your partner here."
                                img={emptyInvoiceImg}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UserNetworkModal;
