import React, { useState } from "react";
import arrow from "../../asset/images/arrowbackright.svg";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import { useMutation } from "react-query";
import { handleVerifyPin } from "api/fetchers";
import Swal from "sweetalert2";

const EnterPinModal = ({ showPinModal, setShowPinModal, setShowSuccessModal, callBack }) => {
    const [otp, setOtp] = useState("");
    const mutation = useMutation({
        mutationFn: () => handleVerifyPin({ pin: otp }),
        onSuccess: async (data) => {
            if (data?.response?.status === 400) {
                Swal.fire({
                    title: "Error !",
                    text: data?.response?.data?.message,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "rgb(111, 0, 255)",
                });
            } else {
                try {
                    await callBack();
                } catch (error) {
                    console.log(error, "=== While verifying pin ===");
                }
            }
        },
        onError: (error) => {
            Swal.fire({
                title: "Error !",
                text: error?.response?.data?.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "rgb(111, 0, 255)",
            });
        },
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (otp.length === 4) {
            mutation.mutateAsync(otp);
        }
    };

    const isLoading = otp.length !== 4;
    return (
        <Modal
            show={showPinModal}
            contentClassName="!rounded-xl w-full max-w-[383px] mx-auto"
            centered
        >
            <Modal.Header className="p-2">
                <div
                    onClick={() => {
                        setShowPinModal();
                        setOtp("");
                    }}
                    className="cursor-pointer"
                >
                    <img src={arrow} alt="Back Arrow" />
                </div>
            </Modal.Header>
            <form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body className="flex flex-col items-center justify-start space-y-1">
                    <h5 className="text-base font-semibold">Enter transaction PIN</h5>
                    <p className="mb-2 text-sm font-light text-main-grey">
                        Enter your 4-digit PIN to confirm transaction.
                    </p>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props) => (
                            <input
                                {...props}
                                className="text-sm mx-2 font-normal rounded-md text-center !bg-red-400 border text-main  !size-10 rounded-ms focus:border-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-100"
                            />
                        )}
                    />
                    <div className="w-8/12 mt-4 mb-3">
                        <button
                            className={`flex px-5 py-3 w-full text-base rounded-lg items-center justify-center border-none outline-none text-white !bg-secondary ${
                                isLoading ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {mutation?.isLoading ? "Verifying..." : "Confirm"}
                        </button>
                    </div>
                </Modal.Body>
            </form>
        </Modal>
    );
};

export default EnterPinModal;
