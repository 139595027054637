import { handleSaveBeneficiary, handleVerifyAccount } from "api/fetchers";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import "style/main.css";
import "style/rightside.css";

function BanksModal({
    show,
    setShow,
    bankList,
    isLoadingBankList,
    setSelectedBeneficiary,
    routingNumber,
    setRoutingNumber,
    recipientType,
    setRecipientType,
    receiverName,
    handleReceiversName,
}) {
    const { state } = useLocation();
    const closeModal = () => {
        setShow(false);
    };
    const [accountNumber, setAccountNumber] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [saveBeneficiary, setSaveBeneficiary] = useState(false);
    const [details, setDetails] = useState(null);

    const {
        data: verifiedAccount,
        isFetching: verifyingAccount,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["accountNumber"],
        queryFn: () => handleVerifyAccount(accountNumber, bankCode?.split("-")[0]),
    });

    const mutation = useMutation({
        mutationKey: "saveBeneficiary",
        mutationFn: (details) => handleSaveBeneficiary(details),
        onSuccess: () => {
            closeModal();
        },
        onError: (error) => {
            console.log(error, "while saving beneficiary");
        },
    });

    useEffect(() => {
        if (state?.default === "NGN") {
            if (accountNumber?.length === 10 && bankCode) {
                if (!accountNumber) {
                    refetch();
                }
            }
        }
    }, [accountNumber, bankCode, state?.default]);

    useEffect(() => {
        if (verifiedAccount?.data) {
            setDetails({
                accountNumber: verifiedAccount?.data?.accountNumber,
                name: verifiedAccount?.data?.accountName,
                currency: state?.default,
                bankName: bankCode?.split("-")[1],
            });
        }
    }, [bankCode, verifiedAccount?.data, state?.default]);

    const canProceed = verifyingAccount || !accountNumber || !bankCode;

    return (
        <div className="w-full h-full modal">
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="border-0"
            >
                <Modal.Body
                    style={{
                        padding: "0px",
                        border: "none",
                    }}
                >
                    <div
                        className="flex flex-col p-[20px] pb-0"
                        style={{
                            gap: "10px",
                        }}
                    >
                        <div className="flex items-center justify-between mb-3">
                            <p className="text-xl font-semibold">New recipient</p>
                            <span
                                className="cursor-pointer material-icons"
                                style={{ color: "#667085" }}
                                onClick={() => {
                                    closeModal();
                                    setAccountNumber("");
                                    setDetails(null);
                                }}
                            >
                                close
                            </span>
                        </div>
                    </div>

                    <div className="p-[20px] border-b border-b-[#E7E9FB]">
                        <select
                            disabled={isLoadingBankList}
                            placeholder={
                                isLoadingBankList ? "Loading Bank List..." : "Account number"
                            }
                            onChange={(e) => setBankCode(e.target.value)}
                            className="bg-white border border-[#E7E9FB] w-full shadow-none h-[55px] rounded-[8px] mb-3 px-[20px]"
                        >
                            <option value="">Select Bank</option>
                            {bankList?.map((item, index) => (
                                <option key={index} value={`${item.code}-${item.name}`}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                        {state?.default !== "NGN" && (
                            <>
                                {state?.default === "USD" && (
                                    <input
                                        placeholder="Routing Number"
                                        name="routingNumber"
                                        value={routingNumber}
                                        onChange={(e) => setRoutingNumber(e?.target?.value)}
                                        className="bg-[#EAECF0] w-full shadow-none h-[50px] rounded-[8px] mb-3 px-[20px]"
                                    />
                                )}
                                <input
                                    placeholder="Account number"
                                    value={accountNumber}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                    className="bg-[#EAECF0] w-full shadow-none h-[50px] rounded-[8px] mb-3 px-[20px]"
                                />
                                <p className="mt-3 mb-2 text-xs font-normal text-main-dark">
                                    Recipient
                                </p>
                                <select
                                    placeholder={"Account number"}
                                    value={recipientType}
                                    onChange={(e) => setRecipientType(e.target.value)}
                                    className="bg-white border border-[#E7E9FB] w-full shadow-none h-[55px] rounded-[8px] mb-3 px-[20px]"
                                >
                                    <option value="Individual">Individual</option>
                                    <option value="Business">Business</option>
                                </select>
                                <input
                                    placeholder="First Name"
                                    value={receiverName?.firstname}
                                    onChange={handleReceiversName}
                                    name={"firstname"}
                                    className="bg-[#EAECF0] w-full shadow-none h-[50px] rounded-[8px] mb-3 px-[20px]"
                                />{" "}
                                <input
                                    placeholder="Last Name"
                                    onChange={handleReceiversName}
                                    value={receiverName?.lastname}
                                    name={"lastname"}
                                    className="bg-[#EAECF0] w-full shadow-none h-[50px] rounded-[8px] mb-3 px-[20px]"
                                />
                            </>
                        )}

                        {state?.default === "NGN" && (
                            <>
                                <input
                                    placeholder="Account number"
                                    value={accountNumber}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                    className="bg-[#EAECF0] w-full shadow-none h-[50px] rounded-[8px] mb-3 px-[20px]"
                                />
                                <input
                                    placeholder="Account name"
                                    disabled={true}
                                    value={details?.name}
                                    className="bg-[#EAECF0] w-full shadow-none h-[50px] rounded-[8px] px-[20px]"
                                />
                            </>
                        )}
                    </div>
                    <div className="flex items-center justify-between px-[20px] py-[15px]">
                        <div className="flex items-center toggle-slider-switch">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={saveBeneficiary}
                                    onChange={() => {
                                        setSaveBeneficiary((prev) => !prev);
                                    }}
                                />
                                <span className="slider round"></span>
                            </label>
                            <p className="text-xs text-[#667085]">Save as beneficiary</p>
                        </div>
                        <button
                            disabled={canProceed || isLoading}
                            className={`flex px-[24px] py-[12px] h-[40px] text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-white bg-[#6F00FF] ${
                                canProceed ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                            onClick={async () => {
                                if (state?.default === "NGN") {
                                    if (details && saveBeneficiary) {
                                        await mutation.mutateAsync({
                                            accountNumber: verifiedAccount?.data?.accountNumber,
                                            name: verifiedAccount?.data?.accountName,
                                            currency: state?.default,
                                            bankName: bankCode?.split("-")[1],
                                        });
                                        setSelectedBeneficiary(verifiedAccount?.data);
                                    } else {
                                        setSelectedBeneficiary(verifiedAccount?.data);
                                        closeModal();
                                    }
                                } else {
                                    setSelectedBeneficiary({
                                        accountNumber,
                                        name:
                                            receiverName?.firstname + " " + receiverName?.lastname,
                                        currency: state?.default,
                                        bankName: bankCode?.split("-")[1],
                                        recipientType,
                                    });
                                    closeModal();
                                }
                            }}
                        >
                            {verifyingAccount ? "Verifying..." : "Continue"}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BanksModal;
