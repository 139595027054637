import React from "react";
import Loader from "../../component/loader";
import FailHandler from "component/failhandler";
import SelectBeneficiaryBox from "fx/_components/SelectbenificiaryBox";
import card from "../../asset/icons/card.svg";
import add from "../../asset/icons/add.svg";
import { Arrow2Icon, DocumentTextIcon, InvoiceIcon, MessageIcon } from "asset/icons";
import { INVOICE, SUPPORTING_DOCUMENT } from "fx/home";
import { SEND_MONEY, SUMMARY } from "constants/global";

const FxSendMoneySecondStep = ({
    fail,
    message,
    setSendMoneyStep,
    loader,
    selectedBeneficiary,
    supportingFile,
    handleFileChange,
    isDisable,
    setShowBeneficiary,
    invoiceFile,
    summaryMemo,
    setSummaryMemo,
    transferFee,
}) => {
    return (
        <div
            className="form-general-layout !mt-[50px]"
            style={{
                position: "relative",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    position: "relative",
                }}
            >
                <FailHandler success={fail} message={message} />

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                >
                    <div>
                        <h6
                            style={{
                                color: "#101828",
                                fontWeight: "500",
                                fontSize: "24px",
                                lineHeight: "32.4px",
                            }}
                        >
                            Send money
                        </h6>
                    </div>
                </div>
                <div className="flex flex-col gap-[24px]">
                    <div className="flex flex-col gap-[8px] w-full">
                        <label
                            htmlFor=""
                            className="text-[14px] leading-[19.6px] font-medium	text-[#101828]"
                        >
                            Recipient details
                        </label>

                        {/* Render the selected beneficiary if beneficiary is selected else render the add beneficiary button */}
                        {selectedBeneficiary ? (
                            <SelectBeneficiaryBox
                                selectedBeneficiary={selectedBeneficiary}
                                setShowBeneficiary={setShowBeneficiary}
                            />
                        ) : (
                            <div
                                onClick={() => setShowBeneficiary(true)}
                                className="p-[5px] h-[56px] bg-white rounded-[8px]"
                            >
                                <div className="hover:bg-[#BFA6FF]/30 duration-200 cursor-pointer flex w-full h-full rounded-[8px]">
                                    <div className="w-[46px] h-full flex items-center justify-center">
                                        <img src={card} alt="" />
                                    </div>
                                    <div className="w-full flex justify-between items-center text-md pl-[8px]">
                                        <p className="text-[#101828]">Add recipient details</p>
                                        <div className="w-[30px] h-full flex items-center justify-center">
                                            <img src={add} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col gap-[8px] w-full">
                        <label
                            htmlFor=""
                            className="text-[14px] leading-[19.6px] font-medium	text-[#101828]"
                        >
                            Add memo
                        </label>
                        <div className="p-[3px] h-[120px] rounded-[8px]">
                            <textarea
                                className="rounded-[8px] p-[16px] w-full h-full outline-none bg-[#EAECF0] resize-none shadow-none"
                                placeholder="Write a note here"
                                value={summaryMemo}
                                onChange={(e) => {
                                    setSummaryMemo(e);
                                }}
                            ></textarea>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px] w-full">
                        <label
                            htmlFor=""
                            className="text-[14px] leading-[19.6px] font-medium	text-[#101828]"
                        >
                            Payment information (optional)
                        </label>
                        <div className="p-[10px] rounded-lg bg-white">
                            <div className="flex h-[45px] cursor-pointer relative">
                                <div className="w-[40px] flex items-center justify-center">
                                    <InvoiceIcon />
                                </div>
                                <div className="w-full pl-[10px] flex justify-between items-center text-[15px]">
                                    <p className="text-[#101828]">Attach invoice</p>
                                    <p className="text-[#667085]/70">
                                        {invoiceFile ? invoiceFile?.name : "No invoice"}
                                    </p>
                                </div>
                                <div className="w-[40px] flex items-center justify-center">
                                    <Arrow2Icon />
                                </div>
                                <input
                                    type="file"
                                    name={INVOICE}
                                    onChange={(e) => handleFileChange(e, e.target.name)}
                                    accept=".pdf"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                />
                            </div>
                            <div className="flex h-[45px] cursor-pointer relative">
                                <div className="h-[45px] w-[40px] flex items-center justify-center">
                                    <DocumentTextIcon />
                                </div>
                                <div className="h-[45px] w-full pl-[10px] flex justify-between items-center text-[15px]">
                                    <p className="text-[#101828]">Add supporting document</p>
                                    <p className="text-[#667085]/70">
                                        {supportingFile ? supportingFile?.name : "No document"}
                                    </p>
                                </div>
                                <div className="h-[45px] w-[40px] flex items-center justify-center">
                                    <Arrow2Icon />
                                </div>
                                <input
                                    type="file"
                                    name={SUPPORTING_DOCUMENT}
                                    onChange={(e) => handleFileChange(e, e.target.name)}
                                    accept=".pdf"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <p className="text-sm mr-1 text-[#101828]">
                                Fee = {transferFee ? transferFee : "Loading.."}
                            </p>
                            <MessageIcon />
                        </div>
                        <div className="flex">
                            <button
                                type="button"
                                onClick={() => setSendMoneyStep(SEND_MONEY)}
                                className={`flex px-[24px] py-[12px] h-[40px] mr-2 text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-[#1D2939]`}
                            >
                                Back
                            </button>
                            <button
                                disabled={isDisable || !selectedBeneficiary}
                                className={`flex px-[24px] py-[12px] h-[40px] text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-white ${
                                    isDisable || !selectedBeneficiary
                                        ? "bg-[#EBE4FF]"
                                        : "bg-[#6F00FF]"
                                }`}
                                onClick={() => setSendMoneyStep(SUMMARY)}
                                type="button"
                            >
                                {loader === true ? <Loader data={loader} /> : "Continue"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FxSendMoneySecondStep;
