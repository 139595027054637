import { CloseIcon } from "asset/icons";
import axios from "axios";
import Dropdown from "component/form/dropDown";
import { useState } from "react";
import { Bars } from "react-loader-spinner";
import { errorAlert } from "utilities/alerts";

const UpdateStatusModal = ({
    currentStatus,
    orderId,
    close,
    refetchActivity,
    paymentStatus,
    paidAmount,
    totalAmount,
    setShowPendingPaymentModal,
    setUpdateStatusModal,
    setOrder,
}) => {
    const [loading, setLoading] = useState(false);

    // Should we allow status update in any order? Or only from top to bottom to avoid issues.
    const orderStatus = [
        { label: "Pending acceptance", value: "PENDING" },
        { label: "Confirmed", value: "CONFIRMED" },
        { label: "Completed", value: "COMPLETED" },
    ];
    const [status, setStatus] = useState(
        orderStatus.find(({ label }) => label.toUpperCase().includes(currentStatus))?.value
    );

    const updateStatus = async () => {
        // Check for pending payments before marking as completed
        if (status === "COMPLETED" && (paymentStatus === "UNPAID" || paidAmount < totalAmount)) {
            // Show NotificationModal if payments are incomplete
            setShowPendingPaymentModal(true);
            close(); // Close the UpdateStatusModal
            return; // Prevent further execution
        } else {
            setUpdateStatusModal(false);
            typeof status === "string" && setOrder((prev) => ({ ...prev, orderStatus: status }));
        }

        try {
            setLoading(status);
            await axios.put(`/v1/flex/order/update/${orderId}`, {
                orderStatus: status,
            });
            await refetchActivity();
            close(status);
        } catch (error) {
            errorAlert(error);
        } finally {
            setLoading(null);
        }
    };

    return (
        <div className="fixed left-0 top-0 h-screen w-screen bg-[#1D29394D] z-[5000] grid place-items-center">
            <div className="bg-page-bg rounded-2xl w-[95%] sm:w-[560px] !pt-1">
                <h3 className="flex justify-between text-main-dark font-semibold text-[23px] !px-6 !py-4 -mb-1.5">
                    Update order status
                    <CloseIcon
                        className={`size-6 cursor-pointer translate-x-1 ${
                            loading && "pointer-events-none *:stroke-main-grey/60"
                        }`}
                        onClick={close}
                    />
                </h3>
                <p className="text-[#6B7280] !px-6 font-[380] text-[15px]">
                    Select the current status of the order
                </p>

                <div className="!px-6 !mt-7">
                    <Dropdown
                        value={status}
                        options={orderStatus.map(({ label, value }) => ({
                            label,
                            value,
                        }))}
                        onChange={setStatus}
                        dropdownClassName="!min-w-full"
                        label="Order status"
                        labelClassName="!text-[#475467]"
                        key={status}
                    />
                </div>

                <div className="flex justify-end items-center !gap-[21px] !border-t !border-[#E7E9FB] !py-[18px] !px-6 !mt-6">
                    <button
                        className="bg-[#6F00FF] rounded-lg h-10 w-[153px] text-[#FBFAFF] font-[450] disabled:bg-secondary/30"
                        disabled={
                            !status || status.toUpperCase().includes(currentStatus) || loading
                        }
                        onClick={updateStatus}
                    >
                        {loading ? (
                            <Bars
                                color="#fff"
                                height={23}
                                width={23}
                                wrapperClass="w-max mx-auto"
                            />
                        ) : (
                            "Update status"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateStatusModal;
