import LoginHeader from "../component/loginheader";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import "../style/remove.css";
import searchIcon from "../asset/images/search-normal.svg";

import ArrowBack from "../component/arrowback";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loaders from "../component/loader";
import { countrycodelist } from "../constants/countryList";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";
import successmark from "../asset/images/Checkbox.svg";
const VerifyEmail = () => {
    const [show, setShow] = useState(false);
    const [countrycode, setCountrycode] = useState("+234");
    const [isChecked, setisChecked] = useState(false);
    const [fname, setfName] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [lname, setlName] = useState("");
    const [phone, setphone] = useState("");
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);
    const [countryflag, setCountryFlag] = useState(null);
    const [search, setSearch] = useState("");
    const [showfirstnamelabe, setshowFirstnamelabel] = useState(false);
    const [showphone, setshowphone] = useState(false);
    const [showlastnamelabel, setshowLastnamelabel] = useState(false);
    const [changecolorforlabelemail, setchangecolorforlabelemail] = useState(false);
    const [success, setsuccess] = useState(false);
    const [fail, setfail] = useState(false);
    const [message, setmessage] = useState("");
    const [changecolorforlabelpassword, setchangecolorforlabelpassword] = useState(false);
    const [changecolorforlabelnumber, setchangecolorforlabelnumber] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        setCountryFlag(countrycodelist[0].image);
    }, []);
    useEffect(() => {
        if (success || fail) {
            let timer = setTimeout(() => {
                setsuccess(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const checkclick = (data, index) => {
        setSelectedIndex(index);
        setCountrycode(data.num);
        setCountryFlag(data.image);
        isChecked ? setisChecked(false) : setisChecked(true);
        setShow(!show);
    };
    const filterCountry = countrycodelist.filter(
        (countrycodelists) =>
            countrycodelists.title.match(search) || countrycodelists.num.match(search)
    );

    const handleverify = async (e) => {
        setLoader(true);
        e.preventDefault();
        const data = {
            firstName: fname,
            lastName: lname,
            phoneNumber: countrycode + "" + phone,
            password: state.password,
            email: state.email,
        };

        const dataemail = {
            email: state.email,
        };
        await axios
            .post("/v1/users/register", data)
            .then(() => {
                axios
                    .post("/v1/users/send-verification-email", dataemail)
                    .then(() => {
                        navigate("/verify", {
                            state: {
                                ...data,
                            },
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                        setLoader(false);
                        setfail(true);
                        setmessage(
                            e.response?.data.message
                                ? e.response.data.message
                                : "An error occured, please try again"
                        );
                    });
            })
            .catch((e) => {
                //  console.log(e);
                setLoader(false);
                setfail(true);
                setmessage(
                    e.response?.data.message ? e.response.data.message : "An error occurred"
                );
            });
    };

    return (
        <div
            className=""
            style={{
                background: "#F6F8FA",
                paddingRight: "0px",
                maxWidth: "2000px",
                width: "100%",
                margin: "0px auto",
            }}
        >
            <Helmet>
                <title>Tradevu — register </title>
            </Helmet>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body style={{ height: "calc(100vh - 100px)" }}>
                    <div className="h-100 ">
                        <h4 style={{ fontSize: "1rem" }}>Choose country code</h4>
                        <div
                            className="form-group position-relative mt-4"
                            style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                padding: "8px 16px",
                                background: "#ECEFF3",
                                borderRadius: "8px",
                            }}
                        >
                            <img src={searchIcon} alt="" width={24} height={24} />
                            <input
                                type="search"
                                className="form-control  border-0"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "19.6px",
                                    color: "#667085",
                                    borderRadius: "8px",
                                    background: "inherit",
                                }}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                placeholder="Search countries"
                            />
                        </div>
                        <div
                            className="card  my-3 border-0 mycardscroll "
                            style={{
                                background: "#ffff",
                                overflow: "auto",
                                height: "calc(100vh - 250px)",
                            }}
                        >
                            {" "}
                            {filterCountry.map((countrycode, index) => (
                                <div className="card-body p-1 " key={index}>
                                    <div
                                        className="p-2  d-flex gap-3 align-items-center"
                                        onClick={() => checkclick(countrycode, index)}
                                        style={{
                                            flexDirection: "row",
                                            flexShrink: "1",
                                            alignItems: "center",
                                            background: selectedIndex === index ? "#F4F0FF" : "",
                                            width: "100%",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <img
                                            src={countrycode.image}
                                            style={{ borderRadius: "100%" }}
                                            alt=""
                                            width={20}
                                        />
                                        <div
                                            className="d-flex justify-content-between w-100"
                                            style={{
                                                overflow: "hidden",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className="d-grid">
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "600px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "2%",
                                                    }}
                                                >
                                                    {" "}
                                                    {countrycode.title}
                                                </span>
                                                <span
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#667085",
                                                        lineHeight: "16.2px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    {" "}
                                                    {countrycode.num}
                                                </span>
                                            </div>
                                            {selectedIndex === index && <img src={successmark} />}
                                        </div>
                                    </div>
                                </div>
                            ))}{" "}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <LoginHeader width={12} />

            <div
                className="p-2  justify-self-center align-items-center d-flex"
                style={{ height: "100vh", maxHeight: "900px" }}
            >
                <div
                    className="form-general-layout"
                    style={{
                        position: "relative",
                    }}
                >
                    <SuccessHodler success={success} message={message} />
                    <FailHandler success={fail} message={message} />
                    <div
                        className="card-body"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "32px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <ArrowBack />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <h3
                                    className=""
                                    style={{
                                        fontSize: "2rem",
                                        fontWeight: "600",
                                        color: "#101828",
                                        padding: "0px",
                                        margin: "0px",
                                    }}
                                >
                                    Tell us a bit about you
                                </h3>
                                <span
                                    style={{
                                        color: "#667085",
                                        fontSize: "1rem",
                                        lineHeight: "24px",
                                    }}
                                >
                                    Enter your personal information
                                </span>
                            </div>
                        </div>

                        <form action="" onSubmit={handleverify} className="my-3">
                            <div
                                className="w-100  position-relative "
                                style={{
                                    borderRadius: "8px",
                                    display: "flex",
                                    height: "56px",
                                    flexDirection: "column",
                                    gap: "4px",
                                    background: "#ECEFF3",
                                    padding: "8px 16px",
                                    justifyContent: "center",
                                }}
                            >
                                {" "}
                                {showfirstnamelabe && (
                                    <label
                                        htmlFor="Email"
                                        className="small_text "
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: changecolorforlabelemail ? "#6F00FF" : "#667085",

                                            lineHeight: "16.2px",
                                        }}
                                    >
                                        First name
                                    </label>
                                )}
                                <input
                                    type="text"
                                    className="w-100 border-0 !shadow-none"
                                    style={{
                                        outline: "none",
                                        borderRadius: "8px",
                                        color: "#667085",
                                        fontSize: "16px",
                                        background: "#ECEFF3",
                                        overflow: "hidden",
                                    }}
                                    value={fname}
                                    required
                                    onKeyPress={() => {
                                        setchangecolorforlabelemail(true);
                                    }}
                                    onBlur={() => {
                                        setchangecolorforlabelemail(false);
                                        setshowFirstnamelabel(false);
                                    }}
                                    onChange={(e) => {
                                        setfName(e.target.value);
                                    }}
                                    onFocus={() => {
                                        setshowFirstnamelabel(true);
                                    }}
                                    placeholder="First name"
                                />
                            </div>
                            <div
                                className="w-100  position-relative mt-3"
                                style={{
                                    borderRadius: "8px",
                                    height: "56px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                    padding: "8px 16px",
                                    justifyContent: "center",
                                    background: "#ECEFF3",
                                }}
                            >
                                {" "}
                                {showlastnamelabel && (
                                    <label
                                        htmlFor="Email"
                                        className="small_text "
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: changecolorforlabelpassword
                                                ? "#6F00FF"
                                                : "#667085",

                                            lineHeight: "16.2px",
                                        }}
                                    >
                                        Last name
                                    </label>
                                )}
                                <input
                                    type="text"
                                    className="w-100 border-0 !shadow-none"
                                    style={{
                                        outline: "none",
                                        borderRadius: "8px",
                                        color: "#667085",
                                        fontSize: "16px",
                                        background: "#ECEFF3",
                                    }}
                                    value={lname}
                                    required
                                    onKeyPress={() => {
                                        setchangecolorforlabelpassword(true);
                                    }}
                                    onBlur={() => {
                                        setchangecolorforlabelpassword(false);
                                        setshowLastnamelabel(false);
                                    }}
                                    onChange={(e) => {
                                        setlName(e.target.value);
                                    }}
                                    onFocus={() => {
                                        setshowLastnamelabel(true);
                                    }}
                                    placeholder="Last name"
                                />
                            </div>
                            <div
                                className="d-flex gap-3  my-3"
                                style={{
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className="form-group !p-2 col-3 position-relative"
                                    style={{
                                        padding: "8px 16px",

                                        display: "flex",
                                        height: "56px",
                                        gap: "8px",
                                        borderRadius: "8px",
                                        background: "#ECEFF3",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={countryflag}
                                        alt=""
                                        className="  bottom-0"
                                        width={18}
                                    />
                                    <div
                                        style={{
                                            background: "#ECEFF3",

                                            fontSize: "1rem",
                                            color: "#101828",
                                        }}
                                        onClick={handleShow}
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                    >
                                        {countrycode}
                                    </div>
                                </div>
                                <div
                                    className=" position-relative "
                                    style={{
                                        borderRadius: "8px",
                                        height: "56px",
                                        width: "100%",
                                        display: "flex",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        gap: "4px",
                                        background: "#ECEFF3",
                                    }}
                                >
                                    {" "}
                                    {showphone && (
                                        <label
                                            htmlFor="Email"
                                            className="small_text "
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: changecolorforlabelnumber
                                                    ? "#6F00FF"
                                                    : "#667085",

                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Phone number
                                        </label>
                                    )}
                                    <input
                                        type="tel"
                                        className="w-100 border-0 !shadow-none"
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: "#ECEFF3",
                                            "::placeholder": {
                                                color: "#888",
                                                letterSpacing: "2px",
                                            },
                                        }}
                                        value={phone}
                                        required
                                        maxLength={10}
                                        onKeyDown={(e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                e.preventDefault();
                                            }
                                            setchangecolorforlabelnumber(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabelnumber(false);
                                            setshowphone(false);
                                        }}
                                        onChange={(e) => {
                                            // Ensure only numeric values and update the state
                                            if (/^\d*$/.test(e.target.value)) {
                                                setphone(e.target.value);
                                            }
                                        }}
                                        onFocus={() => {
                                            setshowphone(true);
                                        }}
                                        placeholder="--- --- ----"
                                    />
                                </div>
                            </div>

                            <button
                                disabled={loader}
                                type="submit"
                                className="btn  mt-5 mr-2 "
                                style={{
                                    background: "#6F00FF",
                                    color: "#fff",
                                    fontSize: "1rem",
                                    maxWidth: "270px",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    padding: "16px 24px 16px 24px",
                                    borderRadius: "8px !important",
                                }}
                            >
                                {" "}
                                {loader === true ? <Loaders data={loader} /> : "Continue"}
                            </button>
                        </form>
                    </div>
                </div>{" "}
            </div>
        </div>
    );
};

export default VerifyEmail;
