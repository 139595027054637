import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import SerHeader from "../component/serviceheader";
import { useLocation } from "react-router-dom";
import "../style/slider.css";
import "../style/main.css";
import FailHandler from "../component/failhandler";
import PayCurrencyModal from "../component/payCurrencyModal";
import axios from "axios";
import currencyPairCheck from "../utilities/currencyPairCheck";
import { useSelector } from "react-redux";
// import { checkBalance } from "../component/checkBalance";
import FxSendMoney from "./crossborder/FxSendMoney";
import FxSendMoneySecondStep from "./crossborder/FxSendMoneySecondStep";
import { INVOICE, SUPPORTING_DOCUMENT } from "fx/home";
import { SEND_MONEY, SEND_MONEY_STEP_TWO, SUMMARY } from "constants/global";
import { getFee } from "utilities/formatMoney";
import { useQuery } from "react-query";
import {
    getTransactionFee,
    handleFetchAllBanks,
    handleGetBeneficiary,
    handleTransferFunds,
} from "api/fetchers";
import BeneficiaryModal from "fx/modals/BeneficiaryModal";
import BanksModal from "fx/modals/BanksModal";
import FxSendMoneySummary from "./crossborder/FxSendMoneySummary";
import Swal from "sweetalert2";

const CrossBorder = () => {
    const { state } = useLocation();
    const [loader] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const selectorWallet = useSelector((state) => state.wallet);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [supportingFile, setSupportingFile] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [sendMoneyStep, setSendMoneyStep] = useState(SEND_MONEY);
    const [amount, setAmount] = useState("");
    const [fail, setFail] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState("GBP");
    const [message, setMessage] = useState("");
    const [pairs, setPairs] = useState([]);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [summaryMemo, setSummaryMemo] = useState("");
    const [showBank, setShowBank] = useState(false);
    const [routingNumber, setRoutingNumber] = useState("");
    const [recipientType, setRecipientType] = useState("Individual");
    const [receiverName, setReceiversName] = useState({ firstname: "", lastname: "" });

    const handleReceiversName = (e) => {
        setReceiversName({ ...receiverName, [e.target.name]: e.target.value });
    };

    function filterWaltetBalance() {
        return selectorWallet?.filter((item) => item.currency === state?.default);
    }

    const { data: transferFee, isLoading: loadingTransferFee } = useQuery({
        queryKey: ["transactionFee", selectedCurrency],
        queryFn: () => getTransactionFee(),
        staleTime: Infinity,
    });

    const { data: beneficiaries } = useQuery({
        queryKey: ["beneficiary", state?.default],
        queryFn: () => handleGetBeneficiary(state?.default),
    });

    const accountBalance = filterWaltetBalance()?.[0]?.balance;

    const { data: bankList, isLoading: isLoadingBankList } = useQuery({
        queryKey: [state?.default],
        queryFn: () => handleFetchAllBanks(state?.default),
        staleTime: Infinity,
    });

    //console.log(filterWaltetBalance()[0].balance);
    // useEffect(() => {
    //     if (fail) {
    //         setTimeout(() => {
    //             setFail(false);
    //         }, 3000);
    //     }
    // });
    useEffect(() => {
        if (amount !== "") {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [setIsDisable, amount]);

    useEffect(() => {
        if (amount) {
            if (parseFloat(amount) <= parseFloat(accountBalance)) {
                setFail(false);
                setMessage("");
            } else {
                setFail(true);
                setMessage("Invalid Amount");
            }
        }
    }, [amount, state?.balance, summaryMemo]);

    useEffect(() => {
        const fetchPairs = async () => {
            await axios
                .post(`/v1/wallets/currency/single_pair`, {
                    baseCurrency: state?.default,
                    quoteCurrency: selectedCurrency,
                })
                .then((res) => {
                    setPairs(res.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchPairs();
    }, [selectedCurrency, state?.default]);

    useEffect(() => {
        let timer = setTimeout(() => {
            setFail(false);
        }, 3000);
        clearTimeout(timer);
    }, []);

    const handleFileChange = (event, name) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && name === INVOICE) {
            setInvoiceFile(selectedFile);
        }
        if (selectedFile && name === SUPPORTING_DOCUMENT) {
            setSupportingFile(selectedFile);
        }
    };

    const rate = parseFloat(
        state?.default === pairs.baseCurrency ? pairs.quoteCurrencyRate : pairs.baseCurrencyRate
    );

    const handleTransferMoney = async () => {
        const formData = new FormData();
        const data = {};

        if (state?.default === "NGN") {
            formData.append("accountnumber", selectedBeneficiary?.accountNumber);
            formData.append("accountname", selectedBeneficiary?.name);
            formData.append("accountcode", null);
            formData.append("amount", amount);
            formData.append("naration", summaryMemo || "Payment");
            formData.append("bankname", selectedBeneficiary?.bankName);
            formData.append("sesid", "090267231202172355257125331033");
            formData.append("invoice", invoiceFile);
            formData.append("supportDocument", supportingFile);
        }
        if (state?.default === "USD") {
            data["BankName"] = selectedBeneficiary?.bankName;
            data["AccountNumber"] = selectedBeneficiary?.accountNumber;
            data["AccountName"] = selectedBeneficiary?.name;
            data["currency"] = selectedBeneficiary?.currency;
            data["recipientType"] = recipientType;
            data["amount"] = amount;
            data["naration"] = summaryMemo || "Payment";
            data["RoutingNumber"] = routingNumber;
            data["SWIFT"] = "NWBKGB2L";
        }
        if (state?.default === "GBP" || state?.default === "EUR") {
            data["BankName"] = selectedBeneficiary?.bankName;
            data["AccountNumber"] = selectedBeneficiary?.accountNumber;
            data["AccountName"] = selectedBeneficiary?.name;
            data["currency"] = selectedBeneficiary?.currency;
            data["recipientType"] = recipientType;
            data["amount"] = amount;
            data["naration"] = summaryMemo || "Payment";
            data["SortCode"] = "12-34-56";
            data["IBAN"] = "GB29NWBK60161331926819";
            data["SWIFT"] = "NWBKGB2L";
            // data["Fee"] = getFee(state?.default, transferFee?.data);
        }
        try {
            const response = await handleTransferFunds(
                state?.default === "NGN",
                state?.default === "NGN" ? formData : data
            );
            if (response?.response?.status === 400) {
                console.log(response, "error");
                throw Error(response?.response?.data?.message);
            } else {
                setShowSuccessModal(true);
            }
        } catch (error) {
            console.log(error, "on transfer attempt");
            Swal.fire({
                title: "Error !",
                text: error,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "rgb(111, 0, 255)",
            });
        }
    };

    const convertedAmount =
        amount > 0
            ? parseFloat(
                  parseFloat(
                      state?.default === pairs.baseCurrency
                          ? pairs.quoteCurrencyRate
                          : pairs.baseCurrencyRate
                  ) * parseFloat(amount)
              ).toFixed(6)
            : "0";

    const transferFeeValue =
        currencyPairCheck(state?.default) + getFee(state?.default, transferFee?.data);

    return (
        <div className="h-100 w-100 ">
            <div
                className="sticky top-0 z-[1000] bg-[#F5F8FA]"
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>FX Payment</title>
                    {/* <title>Cross-border Payment</title> */}
                </Helmet>

                <SerHeader header={"FX Payment"} width={25} />
                {/* <SerHeader header={"Cross-border Payment"} width={25} /> */}
                <PayCurrencyModal
                    show={showModal}
                    setshow={setshowModal}
                    onHide={() => setshowModal(false)}
                    wallet={[]}
                    default={state?.default}
                    setSelectedCurrency={setSelectedCurrency}
                    // setdefault={setdefault}
                    // getWallet={getWallet}
                />

                <BeneficiaryModal
                    show={showBeneficiary}
                    setShow={setShowBeneficiary}
                    setSelectedBeneficiary={(item) => setSelectedBeneficiary(item)}
                    selectedBeneficiary={selectedBeneficiary}
                    setShowBank={setShowBank}
                    beneficiaries={beneficiaries ?? []}
                />
                <BanksModal
                    show={showBank}
                    setShow={setShowBank}
                    bankList={bankList?.data}
                    isLoadingBankList={isLoadingBankList}
                    setSelectedBeneficiary={setSelectedBeneficiary}
                    setRoutingNumber={setRoutingNumber}
                    routingNumber={routingNumber}
                    setRecipientType={setRecipientType}
                    recipientType={recipientType}
                    handleReceiversName={handleReceiversName}
                    receiverName={receiverName}
                />
            </div>
            <div
                style={{
                    height: "100%",
                    maxWidth: "2000px",
                    margin: "0px auto",
                    width: "100%",
                }}
            >
                <div
                    className=""
                    style={{
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <form
                            className="w-full md:w-5/12"
                            style={{
                                position: "relative",
                            }}
                        >
                            <div className="relative flex flex-col gap-8">
                                <FailHandler success={fail} message={message} />

                                {sendMoneyStep === SEND_MONEY && (
                                    <FxSendMoney
                                        filterWaltetBalance={filterWaltetBalance}
                                        setAmount={setAmount}
                                        amount={amount}
                                        pairs={pairs}
                                        loader={loader}
                                        fee={transferFeeValue}
                                        setshowModal={(show) => setshowModal(show)}
                                        sendMoneyStep={sendMoneyStep}
                                        setSendMoneyStep={setSendMoneyStep}
                                        fail={fail || isDisable}
                                        message={message}
                                        convertedAmount={convertedAmount}
                                        rate={rate}
                                        selectedCurrency={selectedCurrency}
                                        loadingTransferFee={loadingTransferFee}
                                    />
                                )}
                                {sendMoneyStep === SEND_MONEY_STEP_TWO && (
                                    <FxSendMoneySecondStep
                                        fail={fail}
                                        message={message}
                                        rate={rate}
                                        sendMoneyStep={sendMoneyStep}
                                        state={state}
                                        loader={loader}
                                        loadingTransferFee={loadingTransferFee}
                                        convertedAmount={convertedAmount}
                                        selectedBeneficiary={selectedBeneficiary}
                                        supportingFile={supportingFile}
                                        handleFileChange={handleFileChange}
                                        isDisable={isDisable || fail}
                                        setAmount={setAmount}
                                        setShowBeneficiary={setShowBeneficiary}
                                        invoiceFile={invoiceFile}
                                        amount={amount}
                                        setSendMoneyStep={setSendMoneyStep}
                                        summaryMemo={summaryMemo}
                                        setSelectedBeneficiary={setSelectedBeneficiary}
                                        setSummaryMemo={(e) => setSummaryMemo(e.target.value)}
                                        transferFee={transferFeeValue}
                                    />
                                )}
                                {sendMoneyStep === SUMMARY && (
                                    <FxSendMoneySummary
                                        convertedAmount={convertedAmount}
                                        fail={fail}
                                        message={message}
                                        rate={rate}
                                        sendMoneyStep={sendMoneyStep}
                                        state={state}
                                        setFail={setFail}
                                        setMessage={setMessage}
                                        handleSetStep={(step) => setSendMoneyStep(step)}
                                        loader={loader}
                                        loadingTransferFee={loadingTransferFee}
                                        selectedCurrency={selectedCurrency}
                                        selectedBeneficiary={selectedBeneficiary}
                                        supportingFile={supportingFile}
                                        handleFileChange={handleFileChange}
                                        isDisable={isDisable || fail}
                                        setAmount={setAmount}
                                        setShowBeneficiary={setShowBeneficiary}
                                        invoiceFile={invoiceFile}
                                        amount={amount}
                                        setSendMoneyStep={setSendMoneyStep}
                                        summaryMemo={summaryMemo}
                                        setSelectedBeneficiary={setSelectedBeneficiary}
                                        setSummaryMemo={(e) => setSummaryMemo(e.target.value)}
                                        transferFee={transferFeeValue}
                                        callBack={handleTransferMoney}
                                        showSuccessModal={showSuccessModal}
                                        setShowSuccessModal={setShowSuccessModal}
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrossBorder;
