import React from "react";
import {
    ADD_RECEIVER_INFO,
    ADD_ITEM_DETAILS,
    SHIPMENT_SUMMARY,
    ADD_PAYMENT_INFO,
} from "constants/global";
import edit from "../../../asset/images/edit-2.svg";

const ShipmentSummary = ({ goToStep, firstStepDetails }) => {
    const {
        itemName,
        containerType,
        loadType,
        packageType,
        quantity,
        weight,
        length,
        width,
        height,
        description,
    } = firstStepDetails;
    return (
        <form onSubmit={(evt) => evt.preventDefault()} className="ml-48 w-[610px]">
            <div>
                <h6
                    style={{
                        color: "#101828",
                        fontWeight: "500",
                        fontSize: "24px",
                        lineHeight: "32.4px",
                    }}
                >
                    Summary
                </h6>
                <p className="text-sm font-light text-[#475467]">
                    Please review your payment details before you continue
                </p>
            </div>

            <div className="flex flex-col w-full gap-2 mt-4">
                <div className="flex items-center justify-between">
                    <label htmlFor="" className="text-sm font-medium text-main-grey">
                        Item Details
                    </label>
                    <div
                        className="flex items-center gap-1 cursor-pointer"
                        onClick={() => {
                            goToStep(ADD_ITEM_DETAILS);
                        }}
                    >
                        <img src={edit} alt="Edit Icon" className="size-4" />
                        <span className="text-xs font-normal cursor-pointer !text-secondary">
                            Edit
                        </span>
                    </div>
                </div>

                <div className="p-3 space-y-4 bg-white rounded-lg cursor-pointer">
                    <div className="flex items-center justify-between w-full">
                        <p className="text-base text-gray-10">Container Type</p>
                        <p className="font-medium text-main-dark">{containerType}</p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal text-gray-10">Load Type</p>
                        <p className="font-medium text-main-dark">{loadType}</p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal text-gray-10">Package Type</p>
                        <p className="font-medium text-main-dark">{packageType}</p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal text-gray-10">Total weight</p>
                        <p className="font-medium text-main-dark">{weight} kg</p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal text-gray-10">Total Quantity</p>
                        <p className="font-medium text-main-dark">{quantity}</p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <p className="text-base font-normal text-gray-10">Package Dimensions</p>
                        <p className="font-medium text-main-dark">
                            {length} cm x {width} cm x {height} cm
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full gap-2 mt-4">
                <div className="flex items-center justify-between">
                    <label htmlFor="" className="text-sm font-medium text-main-grey">
                        Shipping Details
                    </label>
                    <div
                        className="flex items-center gap-1 cursor-pointer"
                        onClick={() => {
                            goToStep(SHIPMENT_SUMMARY);
                        }}
                    >
                        <img src={edit} alt="Edit Icon" className="size-4" />
                        <span className="text-xs font-normal cursor-pointer !text-secondary">
                            Edit
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full gap-2 mt-4">
                <div className="flex items-center justify-between">
                    <label htmlFor="" className="text-sm font-medium text-main-grey">
                        Payment Details
                    </label>
                    <div
                        className="flex items-center gap-1 cursor-pointer"
                        onClick={() => {
                            goToStep(ADD_PAYMENT_INFO);
                        }}
                    >
                        <img src={edit} alt="Edit Icon" className="size-4" />
                        <span className="text-xs font-normal cursor-pointer !text-secondary">
                            Edit
                        </span>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-end mt-10">
                <div className="flex">
                    <button
                        onClick={() => goToStep(ADD_RECEIVER_INFO)}
                        type="button"
                        className={`flex px-[24px] py-[12px] h-[40px] mr-2 text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-[#1D2939]`}
                    >
                        Back
                    </button>
                    <button
                        className={`flex px-[24px] py-[12px] h-[40px] text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-white bg-[#6F00FF]`}
                        type="button"
                    >
                        Book Shipment
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ShipmentSummary;
