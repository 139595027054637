import LoginHeader from "../component/loginheader";
import "../style/index.css";
import { useEffect, useState } from "react";
import ArrowBack from "../component/arrowback";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import Loaders from "../component/loader";
import axios from "axios";
//import { countrycodelist } from "../component/countryList";

import Swal from "sweetalert2";
import { ThreeDots } from "react-loader-spinner";
import CountryModal from "../component/countrymodal";
import AddressModal from "../component/addressModal";
import FailHandler from "../component/failhandler";
import SuccessHodler from "../component/successholder";
import BusinessTypeModal from "../component/businesstypemodal";
import { useSelector } from "react-redux";

const SubmitBusinessDocument = () => {
    const { state } = useLocation();
    const [businessCountry, setBusinessCountry] = useState("Nigeria");
    const [changecolorforlabelhousenumebr, setchangecolorforlabelhousenumber] = useState(false);
    const [rcNumber, setRcNumber] = useState("");
    const [businessName, setBusinesssName] = useState("");
    const [address, setAddress] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showfirstnamelabe, setshowFirstnamelabel] = useState(false);

    const [addresslabel, setaddreesslabel] = useState(false);
    const [countrylabel, setcountrylabel] = useState(false);
    const [changecolorforlabelpassword, setchangecolorforlabelpassword] = useState(false);
    const [changecolorforlabelemail, setchangecolorforlabelemail] = useState(false);
    const [showPasswordLabel, setShowlabelPassword] = useState(false);
    const [changecolorforlabeladdress, setchangecolorforlabeladdress] = useState(false);
    //const business = ["LLC", "SoleProprietorship"];
    const business = [
        {
            type: "LLC",
            title: "CAC registered corporations",
        },
        {
            type: "SoleProprietorship",
            title: "CAC registered businesses or partnerships",
        },
    ];
    const [changecolorforlabellegalname, setchangecolorforlabellegalname] = useState(false);
    const [changecolorfortrade, setchangecolorfortrade] = useState(false);

    // const [changecolorforlabelpassword, setchangecolorforlabelpassword] = useState(false)
    const [showlegalnamelabel, setshowlegalname] = useState(false);
    const [showtradenamelabel, setshowtradename] = useState(false);

    const [showhouselabel, setshowhousenumber] = useState(false);
    const [legalname, setlegalname] = useState("");
    const [tradename, settradename] = useState("");
    const [businessType, setBusinesssType] = useState("LLC");
    const [houseNumber, setHouseNumber] = useState("");
    const [loaderrc, setloaderrc] = useState(false);
    const [showbusinessname, setshowbusinessname] = useState(false);
    const [show, setshow] = useState(false);
    const [show2, setshow2] = useState(false);
    const [fails, setfails] = useState("");
    const [success, setsuccess] = useState("");
    const [message, setmessage] = useState("");

    const [closes, setcloses] = useState(false);
    const [showtype, setshowtype] = useState(false);
    const selector = useSelector((state) => state.users);

    //console.log(state);

    const navigate = useNavigate();
    const getRCDetails = () => {
        const data = {
            RcNumber: rcNumber,
        };
        if (rcNumber.length === 7) {
            setloaderrc(true);

            axios
                .post("/v1/utility/get-business-name", data)
                .then((res) => {
                    // console.log(res);
                    setBusinesssName(res.data.data.company_name);
                    setloaderrc(false);
                    setshowbusinessname(true);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setBusinesssName("");
        }
    };
    useEffect(() => {
        if (fails) {
            let timerr = setTimeout(() => {
                setfails(false);
            }, 3000);

            return () => {
                clearTimeout(timerr);
            };
        }
    });

    const submitDocument = async (e) => {
        e.preventDefault();
        setLoader(true);

        const data = {
            country: businessCountry,
            registrationNumber: rcNumber,
            name: businessName,
            address: address.description,
            businessType: businessType === "LLC" ? "LLC" : "SoleProprietorship",
            placeId: address.place_id,

            email: selector.email,
        };
        const datas = {
            country: businessCountry,
            registrationNumber: houseNumber,
            address: address.description,
            name: tradename,
            legalName: legalname,
            placeId: address.place_id,
            businessType: businessType === "LLC" ? "LLC" : "SoleProprietorship",
            email: selector.email,
        };
        axios
            .post("/v1/business/create", businessCountry === "Nigeria" ? data : datas)
            .then((res) => {
                //console.log(res);
                setsuccess(true);
                setmessage(res.data.message);
                setLoader(false);

                if (businessCountry === "Nigeria") {
                    navigate("/business/address", {
                        state: {
                            ...data,
                            businessId: res.data._id,
                        },
                    });
                } else {
                    navigate("/business/vatnumber", {
                        state: {
                            ...datas,
                            businessId: res.data._id,
                        },
                    });
                }
            })
            .catch((e) => {
                setmessage(
                    e.response?.data.message
                        ? e.response?.data.message
                        : "An error occur, couldn't  Business"
                );
                setfails(true);
                setLoader(false);
            });
    };

    return (
        <div
            className=""
            style={{
                background: "#F6F8FA",

                paddingRight: "0px",
                maxWidth: "2000px",
                width: "100%",
                margin: "0px auto",
            }}
        >
            <Helmet>
                <title>Tradevu — registration</title>
            </Helmet>
            <LoginHeader width={12} />
            <CountryModal
                show={show}
                setselectedcounty={setBusinessCountry}
                setshow={setshow}
                setclose={setcloses}
                closes={closes}
            />
            <AddressModal show={show2} setselectedcounty={setAddress} setshow={setshow2} />
            <BusinessTypeModal
                show={showtype}
                setshow={setshowtype}
                tenure={business}
                settenureday={setBusinesssType}
            />
            <div
                className="p-2 justify-self-center align-items-center d-flex"
                style={{ height: "calc(100% - 200px)" }}
            >
                <div
                    className="form-general-layout"
                    style={{
                        position: "relative",
                    }}
                >
                    <FailHandler success={fails} message={message} />
                    <SuccessHodler success={success} message={message} />
                    <div
                        className="card-body"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "32px",
                        }}
                    >
                        <div
                            className="d-flex justify-content-between "
                            style={{ alignItems: "center" }}
                        >
                            <ArrowBack />
                            <span
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#344054",
                                    cursor: "pointer",
                                    lineHeight: "19.6px",
                                }}
                                onClick={() => {
                                    navigate("/dashboard");
                                }}
                            >
                                Skip
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <h3
                                style={{
                                    fontSize: "32px",
                                    fontWeight: "600",
                                    color: "#101828",
                                    lineHeight: "40px",
                                    margin: "0px",
                                    padding: "0px",
                                }}
                            >
                                Tell us about your business
                            </h3>
                            <span
                                style={{
                                    color: "#667085",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    letterSpacing: "2%",
                                }}
                            >
                                Enter your business information
                            </span>
                        </div>

                        <form
                            onSubmit={submitDocument}
                            className=" d-flex"
                            style={{
                                justifyContent: "space-between",
                                flexDirection: "column",
                                gap: "32px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    className=" position-relative"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px",
                                        height: "56px",
                                        padding: "8px 16px",
                                        background: "#ECEFF3",
                                        justifyContent: "center",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <label
                                        htmlFor="firstname "
                                        className=""
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            color: "#667085",
                                            lineHeight: "16.2px",
                                        }}
                                    >
                                        Business Country
                                    </label>

                                    <input
                                        type="text"
                                        className="w-100 !shadow-none"
                                        aria-describedby="emailHelp"
                                        placeholder="Select Country"
                                        style={{
                                            lineHeight: "24px",
                                            background: "inherit",
                                            border: "none",
                                            outline: "none",
                                        }}
                                        readOnly
                                        value={businessCountry}
                                        onFocus={() => {
                                            setcountrylabel(true);
                                        }}
                                        onClick={() => setshow(true)}
                                    />
                                    <span
                                        className="material-icons"
                                        style={{
                                            position: "absolute",
                                            right: "15px",
                                            color: "#101828",
                                            fontSize: "20px",
                                        }}
                                    >
                                        expand_more
                                    </span>
                                </div>
                                {businessCountry !== "Nigeria" && (
                                    <>
                                        <div
                                            className="w-100    "
                                            style={{
                                                borderRadius: "8px",
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "8px 16px",
                                                gap: "4px",
                                                flexDirection: "column",
                                                background: changecolorforlabellegalname
                                                    ? "#D7DDEA"
                                                    : "#ECEFF3",
                                            }}
                                        >
                                            {showlegalnamelabel && (
                                                <label
                                                    htmlFor="Email"
                                                    className=""
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        color: changecolorforlabellegalname
                                                            ? "#6F00FF"
                                                            : "#667085",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    Legal name
                                                </label>
                                            )}

                                            <input
                                                type="text"
                                                className="w-100 border-0 !shadow-none"
                                                onKeyPress={() => {
                                                    setchangecolorforlabellegalname(true);
                                                }}
                                                onBlur={() => {
                                                    setchangecolorforlabellegalname(false);
                                                    setshowlegalname(false);
                                                }}
                                                style={{
                                                    outline: "none",
                                                    borderRadius: "8px",
                                                    color: "#667085",
                                                    fontSize: "16px",
                                                    background: changecolorforlabellegalname
                                                        ? "#D7DDEA"
                                                        : "#ECEFF3",
                                                }}
                                                value={legalname}
                                                required
                                                onChange={(e) => {
                                                    setlegalname(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setshowlegalname(true);
                                                }}
                                                placeholder="Legal name"
                                            />
                                        </div>
                                        <div
                                            className="w-100"
                                            style={{
                                                borderRadius: "8px",
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                background: changecolorforlabelhousenumebr
                                                    ? "#D7DDEA"
                                                    : "#ECEFF3",
                                                padding: "8px 16px",
                                                gap: "4px",
                                            }}
                                        >
                                            {showhouselabel && (
                                                <label
                                                    htmlFor="Email"
                                                    className=""
                                                    style={{
                                                        fontSize: "12px",

                                                        fontWeight: "500",

                                                        color: changecolorforlabelhousenumebr
                                                            ? "#6F00FF"
                                                            : "#667085",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    House regsitration number
                                                </label>
                                            )}

                                            <input
                                                type="text"
                                                className="w-100 border-0 !shadow-none"
                                                onKeyPress={() => {
                                                    setchangecolorforlabelhousenumber(true);
                                                }}
                                                onBlur={() => {
                                                    setchangecolorforlabelhousenumber(false);
                                                    setshowhousenumber(false);
                                                }}
                                                onKeyDown={(e) => {
                                                    const allowedKeys = [
                                                        "Backspace",
                                                        "Delete",
                                                        "ArrowLeft",
                                                        "ArrowRight",
                                                        "Tab",
                                                    ];
                                                    if (
                                                        !/^[0-9]$/.test(e.key) &&
                                                        !allowedKeys.includes(e.key)
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                style={{
                                                    outline: "none",
                                                    borderRadius: "8px",
                                                    color: "#667085",
                                                    fontSize: "16px",
                                                    background: changecolorforlabelhousenumebr
                                                        ? "#D7DDEA"
                                                        : "#ECEFF3",
                                                }}
                                                value={houseNumber}
                                                required
                                                onChange={(e) => {
                                                    setHouseNumber(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setshowhousenumber(true);
                                                }}
                                                placeholder="House registration number"
                                            />
                                        </div>
                                        <div
                                            className="w-100  position-relative  "
                                            style={{
                                                borderRadius: "8px",
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                gap: "4px",
                                                padding: "8px 16px",
                                                background: changecolorfortrade
                                                    ? "#D7DDEA"
                                                    : "#ECEFF3",
                                            }}
                                        >
                                            {showtradenamelabel && (
                                                <label
                                                    htmlFor="Email"
                                                    className=""
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        color: changecolorfortrade
                                                            ? "#6F00FF"
                                                            : "#667085",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    Trading name
                                                </label>
                                            )}

                                            <input
                                                type="text"
                                                className="w-100 border-0 !shadow-none"
                                                onKeyPress={() => {
                                                    setchangecolorfortrade(true);
                                                }}
                                                onBlur={() => {
                                                    setchangecolorfortrade(false);
                                                    setshowtradename(false);
                                                }}
                                                style={{
                                                    outline: "none",
                                                    borderRadius: "8px",
                                                    color: "#667085",
                                                    fontSize: "16px",
                                                    background: changecolorfortrade
                                                        ? "#D7DDEA"
                                                        : "#ECEFF3",
                                                }}
                                                value={tradename}
                                                required
                                                onChange={(e) => {
                                                    settradename(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setshowtradename(true);
                                                }}
                                                placeholder="Trading name"
                                            />
                                        </div>
                                    </>
                                )}

                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: "56px",
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "4px",
                                        padding: "8px 16px",
                                        flexDirection: "column",
                                        background: changecolorforlabelemail
                                            ? "#D7DDEA"
                                            : "#ECEFF3",
                                    }}
                                    onClick={() => setshowtype(true)}
                                >
                                    {showfirstnamelabe && (
                                        <label
                                            htmlFor="Email"
                                            className=""
                                            style={{
                                                fontSize: "12px",

                                                fontWeight: "500",

                                                color: changecolorforlabelemail
                                                    ? "#6F00FF"
                                                    : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Business Type
                                        </label>
                                    )}
                                    <select
                                        type="text"
                                        className="w-100 border-0 !shadow-none"
                                        onKeyPress={() => {
                                            setchangecolorforlabelemail(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabelemail(false);
                                            setshowFirstnamelabel(false);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorforlabelemail
                                                ? "#D7DDEA"
                                                : "#ECEFF3",
                                        }}
                                        value={businessType}
                                        required
                                        readOnly
                                        placeholder="Business Type"
                                    >
                                        <option value={businessType} readOnly disabled>
                                            {businessType}
                                        </option>
                                    </select>
                                </div>

                                {businessCountry === "Nigeria" && (
                                    <>
                                        <div
                                            className="w-100  position-relative  "
                                            style={{
                                                borderRadius: "8px",
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                gap: "4px",
                                                background: changecolorforlabelpassword
                                                    ? "#D7DDEA"
                                                    : "#ECEFF3",
                                                padding: "8px 16px",
                                            }}
                                        >
                                            {showPasswordLabel && (
                                                <label
                                                    htmlFor="Email"
                                                    className=""
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        color: changecolorforlabelpassword
                                                            ? "#6F00FF"
                                                            : "#667085",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    RC number
                                                </label>
                                            )}

                                            <input
                                                type="number"
                                                className="w-100 border-0 !shadow-none"
                                                onKeyPress={() => {
                                                    setchangecolorforlabelpassword(true);
                                                }}
                                                maxLength={7}
                                                onKeyUp={() => {
                                                    getRCDetails();
                                                }}
                                                onKeyDown={(e) => {
                                                    const allowedKeys = [
                                                        "Backspace",
                                                        "Delete",
                                                        "ArrowLeft",
                                                        "ArrowRight",
                                                        "Tab",
                                                    ];
                                                    if (
                                                        !/^[0-9]$/.test(e.key) &&
                                                        !allowedKeys.includes(e.key)
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onBlur={() => {
                                                    setchangecolorforlabelpassword(false);
                                                    setShowlabelPassword(true);
                                                }}
                                                style={{
                                                    outline: "none",
                                                    borderRadius: "8px",
                                                    color: "#667085",
                                                    fontSize: "16px",
                                                    background: changecolorforlabelpassword
                                                        ? "#D7DDEA"
                                                        : "#ECEFF3",
                                                }}
                                                value={rcNumber}
                                                required
                                                onChange={(e) => {
                                                    setRcNumber(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setShowlabelPassword(true);
                                                }}
                                                placeholder="RC number"
                                            />
                                        </div>

                                        {loaderrc && (
                                            <ThreeDots
                                                height="30"
                                                width="30"
                                                radius="9"
                                                color="#6F00FF"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    marginTop: "-20px",
                                                }}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}

                                        <div
                                            className="w-100  position-relative  "
                                            style={{
                                                borderRadius: "8px",
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "8px 16px",
                                                flexDirection: "column",
                                                background: changecolorforlabelemail
                                                    ? "#D7DDEA"
                                                    : "#ECEFF3",
                                            }}
                                        >
                                            {showfirstnamelabe && (
                                                <label
                                                    htmlFor="Email"
                                                    className=""
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        color: changecolorforlabelemail
                                                            ? "#6F00FF"
                                                            : "#667085",
                                                        lineHeight: "16.2px",
                                                    }}
                                                >
                                                    Business name
                                                </label>
                                            )}

                                            <input
                                                type="text"
                                                className="w-100 border-0 !shadow-none"
                                                onKeyPress={() => {
                                                    setchangecolorforlabelemail(true);
                                                }}
                                                onBlur={() => {
                                                    setchangecolorforlabelemail(false);
                                                    setshowFirstnamelabel(true);
                                                }}
                                                style={{
                                                    outline: "none",
                                                    borderRadius: "8px",
                                                    color: "#667085",
                                                    fontSize: "16px",
                                                    background: changecolorforlabelemail
                                                        ? "#D7DDEA"
                                                        : "#ECEFF3",
                                                }}
                                                value={businessName}
                                                required
                                                // readOnly
                                                onChange={(e) => {
                                                    setBusinesssName(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setshowFirstnamelabel(true);
                                                }}
                                                placeholder="Business name"
                                            />
                                        </div>
                                    </>
                                )}

                                <div
                                    className="w-100  position-relative  "
                                    style={{
                                        borderRadius: "8px",
                                        height: "56px",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        gap: "4px",
                                        background: changecolorforlabeladdress
                                            ? "#D7DDEA"
                                            : "#ECEFF3",
                                        padding: "8px 16px",
                                    }}
                                >
                                    {addresslabel && (
                                        <label
                                            htmlFor="Email"
                                            className=""
                                            style={{
                                                fontSize: "12px",

                                                fontWeight: "500",
                                                color: changecolorforlabeladdress
                                                    ? "#6F00FF"
                                                    : "#667085",
                                                lineHeight: "16.2px",
                                            }}
                                        >
                                            Address
                                        </label>
                                    )}

                                    <input
                                        type="text"
                                        className="w-100 border-0 !shadow-none"
                                        onKeyPress={() => {
                                            setchangecolorforlabeladdress(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabeladdress(false);
                                            setaddreesslabel(true);
                                        }}
                                        style={{
                                            outline: "none",
                                            borderRadius: "8px",
                                            color: "#667085",
                                            fontSize: "16px",
                                            background: changecolorforlabeladdress
                                                ? "#D7DDEA"
                                                : "#ECEFF3",
                                        }}
                                        value={address?.description}
                                        required
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                        }}
                                        readOnly
                                        onFocus={() => {
                                            setaddreesslabel(true);
                                        }}
                                        onClick={() => {
                                            setshow2(true);
                                        }}
                                        placeholder="Address"
                                    />
                                </div>
                                <div className=""></div>

                                <button
                                    disabled={loader}
                                    type="submit"
                                    className="btn  "
                                    style={{
                                        background: "#6F00FF",
                                        color: "#fff",
                                        fontSize: "1rem",
                                        letterSpacing: "2%",
                                        maxWidth: "231px",
                                        width: "100%",
                                        borderRadius: "8px",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                        padding: "16px 24px 16px 24px",
                                        height: "48px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        height: "46px",
                                    }}
                                >
                                    {loader === true ? <Loaders data={loader} /> : "Continue"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>{" "}
        </div>
    );
};

export default SubmitBusinessDocument;
