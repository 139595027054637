import LoginHeader from "../component/loginheader";
import "../style/index.css";
import { useState } from "react";
import ArrowBack from "../component/arrowback";
import Proff from "../asset/images/proof.png";
import Avater from "../asset/images/newavater.png";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import Loaders from "../component/loader";
import successmark from "../asset/images/Checkbox.svg";
import FailHandler from "../component/failhandler";
import SuccessHodler from "../component/successholder";

const ProofofAddress = () => {
  const [fileName, setfileName] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadfinish, setUploadFinish] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [billType, setBillType] = useState("Utility Bill");
  const [isChecked, setisChecked] = useState(false);
  const [selected, setSelected] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fails, setfails] = useState("");
  const [success, setsuccess] = useState("");
  const [message, setmessage] = useState("");
  const { state } = useLocation();

  const navigate = useNavigate();
  //console.log(state);

  const data = [
    {
      name: "Utility Bill",
      id: "1",
    },
    {
      name: "Electricity Bill",
      id: "2",
    },
    {
      name: "Tax Bill",
      id: "3",
    },
  ];
  const handleUpload = (e) => {
    // const files = e.target.files[0]
    setfileName(e.target.files[0]);
    setUploadFinish(true);
  };
  const checkclick = (bill, index) => {
    setSelected(index);
    setBillType(bill.name);
    setModalShow(false);

    isChecked ? setisChecked(false) : setisChecked(true);
  };

  const submitDocument = async (e) => {
    e.preventDefault();
    setLoader(true);
    setUploading(true);

    const formData = new FormData();
    formData.append("file", fileName, fileName.name);
    formData.append("uploadType", billType);

    axios
      .post(
        `/v1/business/upload-proof-of-address/${state.businessId}`,
        formData
      )
      .then(() => {
        setUploadFinish(true);

        navigate(`/business/documents`, {
          state: {
            ...state,
          },
        });
      })
      .catch((e) => {
        //    console.log(e);
        setmessage(
          e.response?.data.message ? e.response?.data.message : "An error occur"
        );
        setfails(true);
        setLoader(false);

        // setLoader(false);
        // navigate(`/business/type/${id}`)
      });
  };

  return (
    <div
      className=""
      style={{
        background: "#F6F8FA",
        paddingRight: "0px",
        maxWidth: "2000px",
        width: "100%",
        margin: "0px auto",
      }}
    >
      <LoginHeader width={45} />
      <Helmet>
        <title>Proof of Address</title>
      </Helmet>

      <div
        className="p-2 justify-self-center align-items-center d-flex"
        style={{ height: "calc(100% - 100px)", background: "#F6F8FA" }}
      >
        <div
          className="form-general-layout"
          style={{
            position: "relative",
          }}
        >
          <FailHandler success={fails} message={message} />
          <SuccessHodler success={success} message={message} />
          <div
            className="card-body"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="d-flex justify-content-between "
              style={{ alignItems: "center" }}
            >
              <ArrowBack />
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#344054",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/dashboard`);
                }}
              >
                Skip
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  lineHeight: "40px",
                  color: "#101828",
                  margin: "0px",
                }}
              >
                Upload proof of address
              </h1>
              <span
                style={{
                  color: "#667085",
                  lineHeight: "24px",
                  fontSize: "16px",
                  letterSpacing: "2%",
                  fontWeight: "500",
                }}
              >
                Select a document type and upload below
              </span>
            </div>

            <form
              onSubmit={submitDocument}
              encType="multipart/form-data"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div
                  className=""
                  style={{
                    position: "relative",
                    borderRadius: "8px",
                  }}
                >
                  <input
                    className="form-control border-0 py-2"
                    onClick={() => {
                      setModalShow(true);
                    }}
                    required
                    value={billType}
                    style={{
                      background: "#ECEFF3",
                      height: "56px",
                      borderRadius: "8px",
                    }}
                  />
                  <span
                    className="material-icons"
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "20px",
                      color: "#101828",
                    }}
                  >
                    expand_more
                  </span>

                  <Modal
                    show={modalShow}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="myBilltype"
                  >
                    <Modal.Header>
                      <span
                        className="material-icons"
                        onClick={() => setModalShow(false)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        close
                      </span>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "1%",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        Select document type
                      </div>
                      <div
                        className="card border-0"
                        style={{ background: "#ffff" }}
                      >
                        {data.map((bill, index) => (
                          <div
                            className="card-body p-0 "
                            key={index}
                            onClick={() => {
                              checkclick(bill, index);
                            }}
                          >
                            <div
                              className="p-2  d-flex gap-3"
                              style={{
                                flexDirection: "row",
                                flexShrink: "1",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="d-flex justify-content-between w-100"
                                style={{
                                  overflow: "hidden",
                                  alignItems: "center",
                                  padding: "6px 8px",
                                  borderRadius: "8px",
                                  background: selected === index && "#F4F0FF",
                                }}
                              >
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      letterSpacing: "2%",
                                      lineHeight: "24px",
                                      color: "#101828",
                                    }}
                                  >
                                    {bill.name}
                                  </span>
                                </div>
                                {selected === index && (
                                  <img src={successmark} alt="" />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <div className="card border-0">
                      <div
                        className="card-body d-flex gap-2"
                        style={{
                          alignItems: "center",
                          height: "56px",
                          padding: "16px",
                          borderRadius: "8px !important",
                          display: "flex",
                          gap: "16px",
                        }}
                      >
                        <img src={Proff.toString()} alt="" width={20} />
                        <span
                          className=""
                          style={{
                            overflow: "hidden",
                            fontSize: "14px",
                            lineHeight: "19.6px",
                            fontWeight: "400",
                            color: "#667085",
                          }}
                        >
                          Your proof of address should clearly show your
                          business name, and address.
                        </span>
                      </div>
                    </div>
                    <div
                      className=" d-flex align-items-center"
                      style={{
                        lineHeight: "19.6px",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#6F00FF",
                      }}
                    >
                      See a sample
                      <span className="material-icons">expand_more</span>
                    </div>
                  </div>
                  {uploading === false && uploadfinish === false && (
                    <div
                      className="card"
                      style={{
                        background: "#ECEFF3",
                        height: "172px",
                        padding: "24px 16px 24px 16px",
                        border: "1px dashed #C0CAD8",
                      }}
                    >
                      <input
                        type="file"
                        name=""
                        id=""
                        style={{
                          width: "100%",
                          position: "relative",
                          zIndex: "2",
                          height: "100%",
                          opacity: "0",
                        }}
                        className="input-field"
                        required
                        onChange={handleUpload}
                      />
                      <div
                        className="card-body"
                        //  onClick={getUploadedFile}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <div
                          className="d-flex justify-content-center"
                          style={{ alignItems: "center" }}
                        >
                          <div className="">
                            <div className="d-flex justify-content-center">
                              <img
                                src={Avater}
                                alt=""
                                width={50}
                                style={{ margin: "0px auto" }}
                              />
                            </div>

                            <div
                              className="text-center"
                              style={{
                                color: "#101828",
                                fontSize: "16px",
                                letterSpacing: "2%",
                                fontWeight: "500",
                                lineHeight: "24px",
                              }}
                            >
                              Drag or Upload file here
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                className=" text-center"
                                style={{
                                  fontSize: "12px",
                                  color: "#667085",
                                  lineHeight: "16.2px",
                                  letterSpacing: "2%",
                                  fontWeight: "400",
                                }}
                              >
                                JPEG, PNG, JPG, PDF
                              </span>
                              <span
                                className="text-center"
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "16.2px",
                                  letterSpacing: "2%",
                                  fontWeight: "400",
                                  color: "#667085",
                                }}
                              >
                                Max. file size: 20.0 MB
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {uploading && uploadfinish === false && (
                    <div className=" mt-2" style={{ background: "#ECEFF3" }}>
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-center"
                          style={{ alignItems: "center" }}
                        >
                          <img src={loader} width={20} alt="" />
                          <span style={{ fontSize: "12px" }}>
                            Uploading....
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {uploadfinish && (
                    <div
                      className=" mt-2 card "
                      style={{
                        background: "#ECEFF3",
                        border: "1px dashed #C0CAD8",
                        padding: "16px",
                      }}
                    >
                      <div className="card-body p-0">
                        <div
                          className="d-flex justify-content-center"
                          style={{ alignItems: "center" }}
                        >
                          <span
                            style={{
                              color: "#6F00FF",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {fileName.name}-
                            {parseFloat(fileName.size / 1025).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <button
                disabled={loader}
                type="submit"
                className="btn   mr-2"
                style={{
                  background: "#6F00FF",
                  color: "#fff",
                  fontSize: "1rem",
                  lineHeight: "24px",
                  fontWeight: "600",
                  maxWidth: "279px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px 24px 16px 24px",
                  marginBottom: "30px",
                }}
              >
                {loader === true ? <Loaders data={loader} /> : "Continue"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProofofAddress;
