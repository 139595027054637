import { Helmet } from "react-helmet";

import SerHeader from "../component/serviceheader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowBack from "../component/arrowback";
import "../style/main.css";
import searchicon from "../asset/images/search-normal.svg";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const InternalTransfer = () => {
    const navigate = useNavigate();
    const [bankbeneficiarys, setbankbeneficiary] = useState([]);
    const { state } = useLocation();
    const [, setselectedbank] = useState([]);
    const [accountNumber, setaccountNumber] = useState("");
    const [search, setsearch] = useState("");
    const [message, setmessage] = useState("");
    const [loadingbank, setloadingbank] = useState(false);
    const [accountName, setaccountName] = useState("");
    const [business, setbusiness] = useState("");

    useEffect(() => {
        let ignore = false;

        const getBeneficiary = async () => {
            await axios
                .get("/v1/beneficiary")
                .then((res) => {
                    //   console.log(res);
                    if (!ignore) setbankbeneficiary(res.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        getBeneficiary();
        return () => {
            ignore = true;
        };
    }, []);
    const getAccountDetails = async () => {
        if (accountNumber.length === 10) {
            setloadingbank(true);
            // console.log("yes");

            await axios
                .get(
                    `/v1/wallets/get_wallet_by_account_number/${accountNumber}?currency=${state.currency}`
                )
                .then((res) => {
                    console.log(res);
                    setloadingbank(false);
                    setaccountName(res.data.data.business.name);
                    setbusiness(res.data.data._id);
                })
                .catch((e) => {
                    console.log(e);
                    setmessage(
                        "Invalid account. Please check the recipient account information and try again."
                    );
                    setloadingbank(false);
                });
        }
    };
    const filtercontant = bankbeneficiarys.filter((countrycodelists) =>
        countrycodelists.name.match(search)
    );

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    width: "100%",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Tradevu transfer</title>
                </Helmet>

                <SerHeader header={"Local transfer"} width={50} />
            </div>
            <div style={{ height: "100%" }}>
                <div
                    className=""
                    style={{
                        maxWidth: "2000px",
                        margin: "0px auto",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",

                            justifyContent: "center",
                        }}
                    >
                        <div className="form-general-layout">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <ArrowBack />
                                <div className="">
                                    <div
                                        style={{
                                            color: "#101828",
                                            fontSize: "24px",
                                            lineHeight: "32.4px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Send funds
                                    </div>
                                    <p
                                        style={{
                                            color: "#667085",
                                            lineHeight: "24px",
                                            letterSpacing: "2%",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Enter recipient details
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <div className="">
                                    <div className="">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "32px",
                                            }}
                                        >
                                            <div className="search-bank-lg-mf">
                                                <div
                                                    className="search-dl-lg"
                                                    style={{
                                                        height: "56px",
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        className="mybene-search"
                                                        placeholder="Tradevu Account Number"
                                                        value={accountNumber}
                                                        onChange={(e) =>
                                                            setaccountNumber(e.target.value)
                                                        }
                                                        onKeyUp={getAccountDetails}
                                                    />
                                                </div>
                                                {message !== "" && (
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            letterSpacing: "2%",
                                                            lineHeight: "19.6px",
                                                            color: "crimson",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {message}
                                                    </span>
                                                )}
                                            </div>
                                            {accountName.length > 0 && (
                                                <div className="search-bank-lg-mf">
                                                    <div
                                                        className="search-dl-lg"
                                                        style={{
                                                            height: "56px",
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="mybene-search"
                                                            placeholder="Account Name"
                                                            value={accountName}
                                                            readOnly
                                                            // onChange={(e) => setaccountNumber(e.target.value)}
                                                            // onKeyUp={getAccountDetails}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {loadingbank && (
                                                <ThreeDots
                                                    height="30"
                                                    width="30"
                                                    radius="9"
                                                    color="#6F00FF"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        display: "flex",
                                                        marginTop: "-20px",
                                                    }}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}

                                            <div className="search-bank-lg-mf">
                                                <label
                                                    htmlFor=""
                                                    className="beneficiary-label"
                                                    style={{
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Saved Beneficiaries{" "}
                                                </label>
                                                <div
                                                    className="search-dl-lg"
                                                    style={{
                                                        height: "56px",
                                                    }}
                                                >
                                                    <img src={searchicon} alt="" />
                                                    <input
                                                        type="search"
                                                        className="mybene-search"
                                                        placeholder="Search beneficiaries"
                                                        value={search}
                                                        onChange={(e) => setsearch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="bene-list">
                                                {filtercontant.map((ben, index) => (
                                                    <div
                                                        className="each-list-ben"
                                                        key={index}
                                                        onClick={() => setselectedbank(ben)}
                                                    >
                                                        <div className="each-name-initial">
                                                            <div className="intial">
                                                                {ben?.name?.slice(0, 2)}
                                                            </div>
                                                            <div className="name-bank">
                                                                <span
                                                                    className="name-link"
                                                                    style={{
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {ben.name}
                                                                </span>
                                                                <span
                                                                    className="bank-link"
                                                                    style={{
                                                                        fontWeight: "400",
                                                                    }}
                                                                >
                                                                    {ben.bankName} .{" "}
                                                                    {ben.accountNumber}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() => {
                                        if (state.currency === "NGN") {
                                            if (accountName.length > 0) {
                                                navigate("/transfer/details", {
                                                    state: {
                                                        accountNumber: accountNumber,
                                                        accountName: accountName,
                                                        currency: state.currency,
                                                        // bankCode: state.bankCode,
                                                        amount: state.amount,
                                                        // sesid: sessid,
                                                        // narration: narration,

                                                        bankName: "Tradevu",
                                                        type: "InlineUsers",
                                                        business: business,
                                                    },
                                                });
                                            }
                                        } else {
                                            if (accountName.length > 0) {
                                                navigate("/transfer/details", {
                                                    state: {
                                                        AccountNumber: accountNumber,
                                                        AccountName: accountName,
                                                        currency: state.currency,
                                                        // bankCode: state.bankCode,
                                                        amount: state.amount,
                                                        // sesid: sessid,
                                                        // narration: narration,

                                                        BankName: "Tradevu",
                                                        type: "InlineUsers",
                                                        business: business,
                                                    },
                                                });
                                            }
                                        }
                                    }}
                                    style={{
                                        maxWidth: "278px",
                                        width: "100%",
                                        background: "#6F00FF",
                                        color: "#fff",
                                        padding: "16px 24px 16px 24px",
                                        letterSpacing: "2%",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                        borderRadius: "8px",
                                        outline: "none",
                                        border: "none",
                                        minHeight: "46px",
                                    }}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InternalTransfer;
