import React, { useState } from "react";
import { AddIcon, CloseIcon, DocsFilledIcon, SearchIcon } from "asset/icons";
import Dropdown from "component/form/dropDown";
import Input from "component/form/Input";
import SupportDocs from "component/form/supportDocs";
import { ADD_SHIPMENT_DETAILS } from "constants/global";

const AddItemDetails = ({
    addDocs,
    setAddDocs,
    file,
    setFile,
    firstStepDetails,
    handleFirstFormChange,
    submitFirstForm,
}) => {
    const {
        itemName,
        containerType,
        loadType,
        packageType,
        quantity,
        weight,
        length,
        width,
        height,
        description,
    } = firstStepDetails;

    return (
        <div className="ml-48 w-[610px]">
            <h3 className="text-main-dark font-[450] text-2xl !mb-6">Add item details</h3>

            <Input
                label="Name your shipment"
                placeholder="TSH-8239"
                value={itemName}
                name="itemName"
                onChange={(e) => {
                    handleFirstFormChange({ name: "itemName", value: e });
                }}
            />

            <div className="grid grid-cols-2 !gap-3.5">
                <div>
                    <Dropdown
                        value={containerType}
                        name="containerType"
                        options={[
                            "20 Foot",
                            "40 Foot",
                            "40-Foot High Cube",
                            "45-Foot High Cube",
                        ].map((option) => ({ label: option, value: option }))}
                        onChange={(e) => {
                            handleFirstFormChange({ name: "containerType", value: e });
                        }}
                        label="Container Type"
                        labelClassName="!text-[#475467]"
                        placeholder="Choose container type"
                    />
                </div>
                <div>
                    <Dropdown
                        value={loadType}
                        name="loadType"
                        options={[
                            "Full Container Load (FCL)",
                            "Less Container Load (LCL)",
                            "Full Truckload (FTL)",
                            "Less Than Truckload (LTL)",
                        ].map((option) => ({ label: option, value: option }))}
                        onChange={(e) => handleFirstFormChange({ name: "loadType", value: e })}
                        label="Load Type"
                        labelClassName="!text-[#475467]"
                        placeholder="Choose Load type"
                    />
                </div>
            </div>

            <div className="grid grid-cols-4 !gap-3.5">
                <div className="col-span-2">
                    <Dropdown
                        value={packageType}
                        name="packageType"
                        options={[
                            "Carton/Box",
                            "Crate",
                            "Pallet",
                            "Bag/Sack",
                            "Bundle",
                            "Container/Loose",
                        ].map((option) => ({ label: option, value: option }))}
                        onChange={(e) => handleFirstFormChange({ name: "packageType", value: e })}
                        label="Package Type"
                        labelClassName="!text-[#475467]"
                        placeholder="Choose package type"
                    />
                </div>

                <div>
                    <Input
                        label="Total Weight"
                        onChange={(e) => handleFirstFormChange({ name: "weight", value: e })}
                        name="weight"
                        value={weight}
                        placeholder="E.g. 100"
                        unit="Kg"
                    />
                </div>
                <div>
                    <Input
                        name="quantity"
                        value={quantity}
                        onChange={(e) => handleFirstFormChange({ name: "quantity", value: e })}
                        label="Total Quantity"
                        placeholder="No of units"
                    />
                </div>
            </div>
            <>
                <p className="font-medium text-sm text-left text-[#475467] !mb-2 !mt-5">
                    Package Dimensions (Optional)
                </p>
                <div className="flex items-center">
                    <Input
                        placeholder="Length"
                        unit="cm"
                        name="length"
                        value={length}
                        onChange={(e) => handleFirstFormChange({ name: "length", value: e })}
                    />
                    <span className="font-medium text-[#475467] text-sm !mx-2.5">X</span>
                    <Input
                        placeholder="Width"
                        unit="cm"
                        onChange={(e) => handleFirstFormChange({ name: "width", value: e })}
                        name="width"
                        value={width}
                    />
                    <span className="font-medium text-[#475467] text-sm !mx-2.5">X</span>
                    <Input
                        placeholder="Height"
                        unit="cm"
                        name="height"
                        value={height}
                        onChange={(e) => handleFirstFormChange({ name: "height", value: e })}
                    />
                </div>
            </>

            <Input
                label="Describe Items"
                type="textarea"
                placeholder="E.g. salmon fishes, bluetooth speakers"
                name="description"
                value={description}
                onChange={(e) => handleFirstFormChange({ name: "description", value: e })}
            />

            <div className="!mb-5">
                <p className="font-medium text-sm text-left text-main-dark !mb-2 !mt-[18px]">
                    Add Purchase Orders Dimensions (Optional)
                </p>
                <PurchaseOrdersSelect />
            </div>

            <SupportDocs
                addDocs={addDocs}
                setAddDocs={setAddDocs}
                file={file}
                setFile={setFile}
                showDelete={true}
            />

            <div className="flex !gap-9 justify-end !mt-6">
                <button type="button" className="button text-[#1D2939] font-[450] !py-2 !px-6">
                    Back
                </button>
                <button
                    onClick={() => submitFirstForm(ADD_SHIPMENT_DETAILS)}
                    className={`!py-2 !px-6 text-white rounded-lg font-[450] ${
                        true ? "bg-[#D9CDFF]" : "bg-secondary"
                    }`}
                    type="submit"
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

const PurchaseOrdersSelect = ({ purchaseOrders }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <p
                className="flex items-center !px-3.5 h-16 bg-white rounded-lg text-main-dark font-[450] cursor-pointer"
                onClick={() => setOpenModal(true)}
            >
                <DocsFilledIcon className="!mr-2" />
                Select Purchase Order
                <AddIcon className="ml-auto" />
            </p>

            {openModal && (
                <div className="w-screen h-screen fixed left-0 top-0 z-[2500] bg-[#1D29394D] grid place-items-center">
                    <div className="bg-white rounded-2xl w-[560px] text-main-dark">
                        <h3 className="flex justify-between items-center !py-4 !px-6 font-semibold text-xl">
                            Choose purchase order{" "}
                            <CloseIcon
                                onClick={() => setOpenModal((p) => !p)}
                                className="size-[25px] cursor-pointer rounded-full *:stroke-main-dark"
                            />
                        </h3>

                        <div className="!px-6">
                            <Input
                                placeholder="Search by item, email or customer"
                                unit={<SearchIcon className="-order-1 !ml-4 !size-6" />}
                                className="!shadow-[inset_0_0_0_1000px_#EAECF0] !pl-3 placeholder:text-base placeholder:font-normal"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddItemDetails;
