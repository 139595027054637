import React from "react";
import { ADD_PAYMENT_INFO, SHIPMENT_SUMMARY } from "constants/global";
import { AddIcon, UserIcon } from "asset/icons";

const AddReceiverInfo = ({ submitFirstForm, goToStep }) => {
    return (
        <form onSubmit={(evt) => evt.preventDefault()} className="ml-48 w-[610px]">
            <h3 className="text-main-dark font-[450] text-2xl !mb-6">Add receivers information</h3>
            <div className="p-[5px] h-[56px] bg-white rounded-[8px]">
                <div className="hover:bg-[#BFA6FF]/30 duration-200 items-center cursor-pointer flex w-full h-full rounded-[8px]">
                    <div className="flex items-center justify-center rounded-full size-10 bg-gray-30">
                        <UserIcon />
                    </div>
                    <div className="w-full flex justify-between items-center text-md pl-[8px]">
                        <p className="text-[#101828]">Add recipient details</p>
                        <div className="w-[30px] h-full flex items-center justify-center">
                            <AddIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex !gap-9 justify-end !mt-6">
                <button
                    onClick={() => goToStep(ADD_PAYMENT_INFO)}
                    type="button"
                    className="button text-[#1D2939] font-[450] !py-2 !px-6"
                >
                    Back
                </button>
                <button
                    onClick={() => submitFirstForm(SHIPMENT_SUMMARY)}
                    className={`!py-2 !px-6 text-white rounded-lg font-[450] ${
                        true ? "bg-[#D9CDFF]" : "bg-secondary"
                    }`}
                    type="submit"
                >
                    Continue
                </button>
            </div>
        </form>
    );
};

export default AddReceiverInfo;
