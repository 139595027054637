import {
    Arrow2Icon,
    ArrowBackRoundIcon,
    CheckCircleIcon,
    ClockIcon,
    DocumentTextIcon,
    DocumentUploadIcon,
} from "asset/icons";
import Avatar from "component/avatar";
import useGetRequest from "hooks/useGetRequest";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import currencyPairCheck from "utilities/currencyPairCheck";
import capitalizeWords from "utilities/capitalizeWords";
import Status from "./component/status";
import { formatMoney } from "utilities/formatMoney";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import capitalizeFirstLetter from "utilities/capitalizeFirstLetter";
import { businessToUse } from "flex/utils";
import MoreActions, { RecordPayment } from "./component/moreActions";
import { Bars } from "react-loader-spinner";
import { errorAlert, successAlert } from "utilities/alerts";
import axios from "axios";
import EmptyList from "component/emptyList";
import Messages from "./messages/Messages";
import UploadDocModal from "component/form/uploadDocModal";
import Loading from "component/loading";
import UpdateStatusModal from "./component/updateStatusModal";
import NotificationModal from "./component/NotificationModal";
dayjs.extend(advancedFormat);

const ManagePo = () => {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId"); //po id

    const type = searchParams.get("type");
    const { business } = useSelector((state) => state); //current business

    const navigate = useNavigate();
    const [tabIdx, setTabIdx] = useState(0);
    const [orderMessages, setOrderMessages] = useState([]);
    const [addDocModal, setAddDocModal] = useState(false);
    const [updateStatusLoading, setUpdateStatusLoading] = useState(null);
    const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);

    const [fetchOrder, order, orderLoading, _, setOrder] = useGetRequest();

    const [fetchActivities, activities, activitiesLoading] = useGetRequest();
    const [recordPaymentModal, setRecordPaymentModal] = useState(false);
    const [fetchWallets, wallets] = useGetRequest();
    const paymentPercentRef = useRef();
    const bizInfo = businessToUse(order, business, type);

    const typesTitle = { inbound: "Buyer", outbound: "Supplier", completed: "Partner" };

    function sortByDate(arr) {
        return arr?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    sortByDate(activities);

    const acceptOrRejectOrder = async (type) => {
        try {
            setUpdateStatusLoading(type);
            const res = await axios.put(`/v1/flex/order/${orderId}`, { status: type });
            successAlert(`Order ${type === "decline" ? "declined" : type}.`);
            await fetchActivities(`/v1/activity/${orderId}`, {}, false);
            await fetchOrder(`/v1/flex/order/${orderId}`);
            await fetchActivities(`/v1/activity/${orderId}`, {}, false);
            await fetchOrder(`/v1/flex/order/${orderId}`);
        } catch (error) {
            errorAlert(error);
        } finally {
            setUpdateStatusLoading(null);
        }
    };

    const handleMarkAsPaidAndCompleted = async () => {
        try {
            console.log("Marking order as paid and completed...");

            // Close modals if applicable
            setShowPendingPaymentModal(false);
            setUpdateStatusModal(false);

            // Calculate the payment details
            const paymentMethod = "Automatic";
            const currency = order?.currency;
            const amount = (order.totalAmount - order.paidAmount).toFixed(2);
            const paymentDate = new Date().toISOString().split("T")[0];

            // Validate inputs
            if (!currency) {
                return errorAlert("Currency is missing. Please verify the order details.");
            }
            if (amount <= 0) {
                return errorAlert("Invalid amount. Please check the payment details.");
            }

            // Record the payment
            await axios.post(`/v1/flex/order/payment/${orderId}`, {
                paymentMethod,
                currency,
                amount,
                paymentDate,
            });

            console.log("Payment recorded successfully.");

            // Update the order status to "COMPLETED"
            console.log("Updating order status...");
            await axios.put(`/v1/flex/order/update/${orderId}`, { orderStatus: "COMPLETED" });

            // Update local state after successful backend updates
            setOrder((prev) => ({
                ...prev,
                orderStatus: "COMPLETED",
            }));

            console.log("Order marked as completed with payment successfully updated.");
            await fetchActivities(`/v1/activity/${orderId}`, {}, false);
            await fetchOrder(`/v1/flex/order/${orderId}`);
            await fetchActivities(`/v1/activity/${orderId}`, {}, false);
            await fetchOrder(`/v1/flex/order/${orderId}`);
        } catch (error) {
            console.error(
                "Error updating payment and status:",
                error.response?.data || error.message
            );
            errorAlert(
                error.response?.data?.message || "Failed to mark the order as paid and completed."
            );
        }
    };

    const uploadDocument = async (file, name) => {
        try {
            setUploadDocumentLoading(true);
            const formData = new FormData();
            formData.append("file", file, name);
            formData.append("name", name);
            const res = await axios.post(`/v1/flex/order/uploadDocument/${orderId}`, formData);
            setOrder(res.data.data);
            setUploadDocumentLoading(false);
            successAlert(`${name} uploaded successfully.`);
            setAddDocModal(false);
        } catch (error) {
            errorAlert(error);
            setUploadDocumentLoading(false);
        }
    };

    useEffect(() => {
        business?._id && fetchWallets("/v1/wallets/accounts");
    }, [business?._id]);

    useEffect(() => {
        !orderId && navigate("/flex/home");
        if (business?._id && orderId) {
            fetchOrder(`/v1/flex/order/${orderId}`);
            fetchActivities(`/v1/activity/${orderId}`, {}, false);
            fetchOrderMessages();
        }
    }, [business?._id, orderId]);

    useEffect(() => {
        if (order) {
            console.log("Order data:", order);
        }
    }, [order]);

    let percentPaid = ((86 / order?.totalAmount) * 100).toFixed(2);
    // percentPaid = percentPaid.length > 1 ? percentPaid : `0${percentPaid}`;
    // order?.paidAmount

    if (!orderLoading && !order) {
        errorAlert("Order does not exist.");
        return <Navigate to="/flex/home" replace />;
    }

    // Fetch messages for the order
    const fetchOrderMessages = async () => {
        try {
            const { data } = await axios.get(`/v1/message/flex-order/${orderId}`);
            setOrderMessages(data);
            console.log("Fetched messages:", data);
        } catch (error) {
            console.error("Error fetching messages:", error);
            errorAlert("Failed to fetch messages");
        }
    };

    const isTradevuUser = order?.outbound?.businessId;

    const isBuyerAndTradeVuUser = typesTitle[type] === "Buyer" && order?.outbound?.businessId;

    console.log(
        {
            isBuyerAndTradeVuUser,
            bId: business?._id,
            orderId: order?.inbound?.businessId,
            c: typesTitle[type],
            buyer: "Buyer",
            checkId: business?._id == order?.inbound?.businessId,
            checkBuyer: typesTitle[type] === "Buyer",
        },
        "===== || ===="
    );

    const splitPaymentTerms = !order?.paymentTerms
        ?.toLowerCase()
        ?.includes("full payment on completion")
        ? [
              {
                  key: "Payment method",
                  value: capitalizeWords(order?.paymentMethod),
              },
              {
                  key: "Next payment due",
                  value: `${currencyPairCheck(order?.currency)}${formatMoney(
                      order?.nextPayment.amountDue
                  )}`,
              },
              {
                  key: "Total paid",
                  value: `${currencyPairCheck(order?.currency)}${formatMoney(order?.paidAmount)}`,
              },
          ]
        : [
              {
                  key: "Payment method",
                  value: capitalizeWords(order?.paymentMethod),
              },
              {
                  key: "Payment terms",
                  value: capitalizeWords(order?.paymentTerms),
              },
              {
                  key: "Total paid",
                  value: `${currencyPairCheck(order?.currency)}${formatMoney(order?.paidAmount)}`,
              },
          ];

    return (
        <>
            {recordPaymentModal && (
                <RecordPayment
                    order={order}
                    orderId={orderId}
                    totalAmount={order?.totalAmount}
                    close={() => setRecordPaymentModal(false)}
                    refetchActivity={() => fetchOrder(`/v1/flex/order/${orderId}`)}
                />
            )}
            {orderLoading && (
                <div className="fixed left-0 top-0 h-screen w-screen z-[1000000] opacity-65">
                    <Loading />
                </div>
            )}

            <div className="!px-7 !py-8">
                <h5 className="flex !gap-3.5 font-medium text-sm text-main-grey items-center">
                    <ArrowBackRoundIcon
                        className="bg-white rounded-full size-6 !p-1 cursor-pointer"
                        onClick={() => navigate("/flex/home")}
                    />{" "}
                    Back to orders
                </h5>

                <div className="flex justify-between !mt-5">
                    <h2 className="font-[450] text-2xl text-main-dark basis-1/3 truncate">
                        {order?.title}
                        <Status
                            status={order?.orderStatus?.toLowerCase()}
                            className={`!mt-2 transition-opacity ${!order?.status && "opacity-0"} ${
                                order?.orderStatus === "PENDING" && "flex items-center !gap-2"
                            }`}
                            preview
                        >
                            {order?.orderStatus === "PENDING" ? (
                                <>
                                    <ClockIcon /> Pending acceptance
                                </>
                            ) : (
                                capitalizeWords(order?.orderStatus)
                            )}
                        </Status>
                    </h2>

                    {order?.orderStatus !== "CANCELLED" &&
                        order?.orderStatus !== "REJECTED" &&
                        order?.orderStatus !== "COMPLETED" && (
                            <div
                                className={`flex items-center self-end !gap-5 ${
                                    orderLoading && "opacity-0"
                                }`}
                            >
                                {order?.status === "pending" &&
                                order?.orderStatus === "PENDING" &&
                                type ? (
                                    <>
                                        {isBuyerAndTradeVuUser ? (
                                            <>
                                                <button
                                                    className="rounded-lg !py-2 !px-4 flex items-center gap-3 !bg-secondary text-[#FBFAFF] text-[15px]"
                                                    onClick={() => acceptOrRejectOrder("accepted")}
                                                    disabled={updateStatusLoading}
                                                >
                                                    Accept order{" "}
                                                    {updateStatusLoading === "accepted" && (
                                                        <Bars color="#fff" height={16} width={16} />
                                                    )}
                                                </button>
                                                <span
                                                    className="rounded-lg !py-2 !px-2 !bg-transparent text-[15px] cursor-pointer flex items-center gap-3"
                                                    onClick={() => acceptOrRejectOrder("decline")}
                                                    disabled={updateStatusLoading}
                                                >
                                                    Reject order{" "}
                                                    {updateStatusLoading === "decline" && (
                                                        <Bars
                                                            color="#6f00ff"
                                                            height={16}
                                                            width={16}
                                                        />
                                                    )}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    className="rounded-lg !py-2 !px-4 flex items-center gap-3 !bg-secondary text-[#FBFAFF] text-[15px]"
                                                    onClick={() => setUpdateStatusModal(true)}
                                                    disabled={updateStatusLoading}
                                                >
                                                    Update Status
                                                </button>
                                                <MoreActions
                                                    business={business}
                                                    order={order}
                                                    dropClassName="!top-[121%]"
                                                    className="rotate-90 !size-[30px]"
                                                    orderId={orderId}
                                                    tab={type}
                                                    updateOrder={(status) =>
                                                        setOrder((prev) => ({
                                                            ...prev,
                                                            orderStatus: status,
                                                        }))
                                                    }
                                                    status={order?.orderStatus?.toLowerCase()}
                                                    disableCancel={
                                                        order?.orderStatus === "CANCELLED" ||
                                                        type !== "outbound"
                                                    }
                                                    currency={order?.currency}
                                                    wallets={wallets}
                                                    totalAmount={order?.totalAmount}
                                                    isTradevuUser={isTradevuUser}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="rounded-lg !py-2 !px-4 !bg-secondary text-[#FBFAFF] text-[15px]"
                                            onClick={() =>
                                                order?.orderStatus === "CONFIRMED" &&
                                                setUpdateStatusModal(true)
                                            }
                                        >
                                            {order?.orderStatus === "CONFIRMED" && "Update status"}
                                        </button>
                                        <MoreActions
                                            business={business}
                                            order={order}
                                            dropClassName="!top-[121%]"
                                            className="rotate-90 !size-[30px]"
                                            orderId={orderId}
                                            tab={type}
                                            updateOrder={(status) =>
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    orderStatus: status,
                                                }))
                                            }
                                            status={order?.orderStatus?.toLowerCase()}
                                            disableCancel={
                                                order?.orderStatus === "CANCELLED" ||
                                                type !== "outbound"
                                            }
                                            currency={order?.currency}
                                            wallets={wallets}
                                            totalAmount={order?.totalAmount}
                                            isTradevuUser={isTradevuUser}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                </div>

                <section className="grid grid-cols-2 !gap-6 !mt-6">
                    <div>
                        <p className="font-[460] text-sm text-main-grey !mb-2">
                            {typesTitle[type]}
                        </p>
                        <div className="bg-white rounded-lg flex items-center !px-3.5 !py-3">
                            <Avatar
                                fullName={bizInfo.bizName}
                                className={`!bg-[#F6F6F6] border-white ${
                                    bizInfo.bizName > 3 && "text-xs overflow-hidden"
                                }`}
                            />
                            <h3 className="font-[450] text-base text-main-dark">
                                {capitalizeWords(bizInfo.bizName)}
                                <span className="block text-main-grey font-normal text-xs mt-[1px]">
                                    {capitalizeFirstLetter(bizInfo.email)}
                                </span>
                            </h3>
                        </div>

                        <>
                            <p className={`font-[460] text-sm text-main-grey !mb-2 !mt-8`}>
                                Order details
                            </p>
                            <div className="bg-white rounded-lg items-center !p-4">
                                {[
                                    {
                                        key: "Order value",
                                        value: `${currencyPairCheck(
                                            order?.currency ?? ""
                                        )} ${formatMoney(order?.totalAmount ?? 0)}`,
                                    },
                                    { key: "Items", value: order?.orderItems?.length },
                                    {
                                        key: "Due date",
                                        value: dayjs(order?.deliveryDate).format(
                                            "Do of MMMM, YYYY"
                                        ),
                                    },
                                    {
                                        key: "Created on",
                                        value: dayjs(order?.createdAt).format("Do of MMMM, YYYY"),
                                    },
                                ].map(({ key, value }) => (
                                    <p
                                        className={`grid grid-cols-[172px_1fr] text-sm text-[#475467] w-full !mb-4`}
                                        key={key}
                                    >
                                        {key}
                                        <span className="font-medium text-main-dark">{value}</span>
                                    </p>
                                ))}

                                <div className="!border border-[#E7E9FB] rounded-lg">
                                    {!order?.paymentTerms?.includes("Full") && (
                                        <div className="!p-5 border-b border-[#E7E9FB]">
                                            <p className="font-medium text-[#475467] text-sm">
                                                Split payment{" "}
                                                <span className="!ml-2 font-normal">
                                                    ({order?.paymentOne}-{order?.paymentTwo})
                                                </span>
                                            </p>
                                            <div
                                                className="bg-[#EAECF0] rounded-[20px] h-2 !my-3.5 relative"
                                                ref={paymentPercentRef}
                                            >
                                                <div
                                                    className="bg-[#11A811] absolute left-0 top-0 h-full rounded-[20px] transition-[width]"
                                                    style={{
                                                        width: `${Math.min(
                                                            (order?.paidAmount /
                                                                order?.totalAmount) *
                                                                100 || 0,
                                                            100
                                                        )}%`,
                                                    }}
                                                />
                                                {/* {console.log(
                                            (paymentPercentRef?.current?.offsetWidth ?? 0) *
                                                `.${percentPaid}`,
                                            "tester",
                                            paymentPercentRef?.current?.offsetWidth,
                                            Math.min(+percentPaid, 100)
                                        )} */}
                                            </div>
                                            <p className="font-medium text-sm flex text-[#475467]">
                                                {currencyPairCheck(order?.currency)}
                                                {formatMoney(order?.paidAmount ?? 0)} /{" "}
                                                {formatMoney(order?.totalAmount ?? 0)}
                                                <span className="ml-auto font-normal text-main-grey">
                                                    {(
                                                        ((order?.paidAmount ?? 0) /
                                                            (order?.totalAmount ?? 0)) *
                                                        100
                                                    ).toFixed(2)}
                                                    % complete
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                    <div className="flex justify-between gap-2 !p-5">
                                        {splitPaymentTerms?.map(({ key, value }) => (
                                            <p
                                                className="w-full text-sm last:w-1/2 text-main-grey"
                                                key={key}
                                            >
                                                {key}
                                                <span className="block !mt-2 font-medium text-xs text-main-dark">
                                                    {value}
                                                </span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                        <>
                            <div className="flex !mt-7 !gap-8 !px-6">
                                {["Order items", "Messages", "Documents"].map((tab, idx) => (
                                    <span
                                        className={`!pb-2 border-b-2 cursor-pointer transition-all duration-200 ${
                                            idx === 1 && "flex items-center !gap-2"
                                        } ${
                                            idx === tabIdx
                                                ? "font-[450] !border-secondary text-main-dark"
                                                : "!border-transparent text-main-grey"
                                        } ${idx === 3 && "!pointer-events-none !text-[#BCC2CC]"}`}
                                        key={tab}
                                        onClick={() => setTabIdx(idx)}
                                    >
                                        {tab}
                                        {/* {idx === 1 && (
                                        <span className="size-4 rounded-full bg-[#11A811] text-xs text-[#FCFCFD] grid place-items-center">
                                            4
                                        </span>
                                    )} */}
                                    </span>
                                ))}
                                <button
                                    className="flex !gap-2 text-sm !text-secondary items-center ml-auto -translate-y-[1px]"
                                    onClick={() => {
                                        setTabIdx(2);
                                        setAddDocModal(true);
                                    }}
                                >
                                    <DocumentUploadIcon className="size-5" /> Add document
                                </button>
                            </div>
                            {!tabIdx && (
                                <div className="bg-white rounded-lg !p-6 flex flex-col !gap-2">
                                    {order?.orderItems?.map(
                                        ({ itemName, quantity, pricePerUnit, ...rest }) => (
                                            <div
                                                className="!border rounded-lg justify-between !border-[#E7E9FB] !p-4 flex"
                                                key={itemName + pricePerUnit + quantity}
                                            >
                                                <p className="font-[450] text-main-dark">
                                                    {capitalizeWords(itemName)}
                                                    <span className="block font-normal text-xs text-main-grey mt-[1px]">
                                                        {currencyPairCheck(order.currency)}{" "}
                                                        {formatMoney(pricePerUnit)}
                                                    </span>
                                                </p>
                                                <p className="font-[450] text-main-dark text-right">
                                                    {currencyPairCheck(order.currency)}{" "}
                                                    {formatMoney(pricePerUnit * quantity)}
                                                    <span className="block font-normal text-xs text-main-grey mt-[1px]">
                                                        {quantity} unit{+quantity > 1 && "s"}
                                                    </span>
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}

                            {/* Render Messages component when "Messages" tab is selected */}
                            {tabIdx === 1 && (
                                <Messages
                                    messages={orderMessages}
                                    orderId={orderId}
                                    onSendMessage={setOrderMessages}
                                    businessName={business.name}
                                />
                            )}

                            {tabIdx === 2 && (
                                <div className="bg-white rounded-lg !p-6 flex flex-col">
                                    {!order?.document?.length ? (
                                        <EmptyList message="No documents available" />
                                    ) : (
                                        <>
                                            {order.document.map((doc, idx) => (
                                                <span
                                                    className={`flex items-center !p-5 border-x border-b !border-[#E7E9FB] text-main-dark font-[450] ${
                                                        !idx && "border-t rounded-t-lg"
                                                    }`}
                                                    key={idx}
                                                >
                                                    <DocumentTextIcon className="size-6 !mr-2" />{" "}
                                                    {doc.name}{" "}
                                                    <span className="font-[350] text-main-grey ml-auto !mr-[22px]">
                                                        {doc.ext}
                                                    </span>
                                                    <Arrow2Icon />
                                                </span>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </div>
                    <div>
                        {!!activities?.length && (
                            <>
                                <p className="font-[460] text-sm text-main-grey !mb-2.5">
                                    Activity log
                                </p>

                                <div className="bg-white rounded-lg !px-3.5 !py-3 flex flex-col ">
                                    {activities?.map((activity, index) => {
                                        const isLastItem = index === activities.length - 1;
                                        const declinedActivity = activity.description
                                            .toLowerCase()
                                            .includes("decline");
                                        return (
                                            <div className="!gap-3.5 flex group" key={activity._id}>
                                                <div className="flex flex-col items-center justify-start">
                                                    {declinedActivity ? (
                                                        <CheckCircleIcon className="*:fill-[#F73C3C]" />
                                                    ) : (
                                                        <CheckCircleIcon className="*:fill-[#11A811]" />
                                                    )}
                                                    <div
                                                        className={`!group-last:hidden h-full text-xs  ${
                                                            isLastItem
                                                                ? ""
                                                                : declinedActivity
                                                                ? "!border-[#FFE1E1] border-[1px]"
                                                                : "!border-[#DCFDDB] border-[1px]"
                                                        }`}
                                                    />
                                                </div>
                                                <h3 className="font-medium text-sm text-[#475467]">
                                                    {activity.description}
                                                    <span className="flex last:pb-0 pb-2 items-center text-main-grey font-normal text-xs mt-[1px]">
                                                        {false && (
                                                            <span className="!text-secondary">
                                                                Transaction #23098
                                                            </span>
                                                        )}
                                                        <span className="size-1 rounded-full bg-main-grey !mx-2" />{" "}
                                                        {dayjs(activity.createdAt).format(
                                                            "Do of MMMM, YYYY"
                                                        )}
                                                    </span>
                                                </h3>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </div>
            {addDocModal && (
                <UploadDocModal
                    close={() => setAddDocModal(false)}
                    loading={uploadDocumentLoading}
                    uploadFunc={uploadDocument}
                />
            )}
            {/* Render UpdateStatusModal */}
            {updateStatusModal && (
                <UpdateStatusModal
                    currentStatus={order?.orderStatus}
                    orderId={orderId}
                    close={(status) => {
                        if (
                            status === "COMPLETED" &&
                            (order?.paymentStatus === "UNPAID" ||
                                order?.paidAmount !== order?.totalAmount)
                        ) {
                            // Close the UpdateStatusModal and open NotificationModal
                            setUpdateStatusModal(false);
                            setShowPendingPaymentModal(true);
                        } else {
                            setUpdateStatusModal(false);
                            fetchActivities(`/v1/activity/${orderId}`, {}, false);
                        }
                    }}
                    refetchActivity={() => fetchActivities(`/v1/activity/${orderId}`, {}, false)}
                    paymentStatus={order?.paymentStatus}
                    paidAmount={order?.paidAmount}
                    totalAmount={order?.totalAmount}
                    setShowPendingPaymentModal={setShowPendingPaymentModal}
                    setUpdateStatusModal={setUpdateStatusModal}
                    setOrder={setOrder}
                />
            )}

            {/* Render Pending Payment Modal */}
            {showPendingPaymentModal && (
                <NotificationModal
                    title="Order has pending payments"
                    description="Completing the order will mark all payments as settled. Please review the payment status before proceeding."
                    onClose={() => {
                        setShowPendingPaymentModal(false);
                        setUpdateStatusModal(true);
                    }}
                    actions={[
                        {
                            label: "Go back",
                            onClick: () => {
                                setShowPendingPaymentModal(false);
                                setUpdateStatusModal(true);
                            },
                            style: "secondary",
                        },
                        {
                            label: "Mark as paid and completed",
                            onClick: handleMarkAsPaidAndCompleted,
                            style: "primary",
                        },
                    ]}
                />
            )}
        </>
    );
};

export default ManagePo;
