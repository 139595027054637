import FailHandler from "component/failhandler";
import { DEFAULT_RATE, SEND_MONEY, SEND_MONEY_STEP_TWO, SHOW_COUNTRY_FLAG } from "constants/global";
import { SummaryBeneficiaryBox } from "fx/_components/SendMoneySummary";
import React, { useState } from "react";
import edit from "../../asset/images/edit-2.svg";
import EnterPinModal from "fx/modals/EnterPinModal";
import SuccessfulTrxnModal from "fx/modals/SuccessfulTrxnModal";
import currencyPairCheck from "utilities/currencyPairCheck";

const FxSendMoneySummary = ({
    fail,
    message,
    sendMoneyStep,
    state,
    handleBack,
    handleSetStep,
    selectedBeneficiary,
    supportingFile,
    isDisable,
    setShowBeneficiary,
    invoiceFile,
    amount,
    summaryMemo,
    setSummaryMemo,
    transferFee,
    callBack,
    convertedAmount,
    rate,
    selectedCurrency,
    showSuccessModal,
    setShowSuccessModal,
    setFail,
    setMessage,
}) => {
    const [showPinModal, setShowPinModal] = useState(false);

    return (
        <div
            className="form-general-layout !mt-[50px]"
            style={{
                position: "relative",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    position: "relative",
                }}
            >
                <FailHandler success={fail} message={message} />

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                >
                    <div>
                        <h6
                            style={{
                                color: "#101828",
                                fontWeight: "500",
                                fontSize: "24px",
                                lineHeight: "32.4px",
                            }}
                        >
                            Summary
                        </h6>
                        <p className="text-sm font-light text-[#475467]">
                            Please review your payment details before you continue
                        </p>
                    </div>
                </div>
                <div className="flex flex-col gap-[24px]">
                    {/* Transaction details */}
                    <div className="flex flex-col w-full gap-2 mt-4">
                        <div className="flex items-center justify-between">
                            <label htmlFor="" className="text-sm font-medium text-main-grey">
                                Transaction Details
                            </label>
                            <div
                                className="flex items-center gap-1 cursor-pointer"
                                onClick={() => {
                                    handleSetStep(SEND_MONEY);
                                }}
                            >
                                <img src={edit} alt="Edit Icon" className="size-4" />
                                <span className="text-xs font-normal cursor-pointer !text-secondary">
                                    Edit
                                </span>
                            </div>
                        </div>

                        <div className="p-3 space-y-4 bg-white rounded-lg cursor-pointer">
                            <div className="flex items-center justify-between w-full">
                                <p className="text-base text-gray-10">Recipient receives</p>
                                <p className="flex items-center gap-2 font-medium text-main-dark">
                                    <img
                                        src={SHOW_COUNTRY_FLAG[selectedCurrency]}
                                        alt={selectedCurrency}
                                        className="border rounded-full size-6"
                                    ></img>{" "}
                                    {convertedAmount} {selectedCurrency}
                                </p>
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <p className="text-base text-gray-10">Exchange Rate</p>
                                <p className="font-medium text-main-dark">
                                    {rate || rate === 0 ? rate.toFixed(6) : DEFAULT_RATE}
                                </p>
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <p className="text-base text-gray-10">Transfer fee</p>
                                <p className="font-medium text-main-dark">{transferFee}</p>
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <p className="text-base font-normal text-gray-10">You Send</p>
                                <p className="flex items-center gap-2 font-medium text-main-dark ">
                                    <img
                                        src={SHOW_COUNTRY_FLAG[state?.default]}
                                        alt={selectedCurrency}
                                        className="border rounded-full size-6"
                                    ></img>{" "}
                                    {currencyPairCheck(state?.default)} {amount}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col w-full gap-2">
                        <div className="flex items-center justify-between">
                            <label htmlFor="" className="text-sm font-medium text-main-grey">
                                Recipient details
                            </label>
                            <div
                                className="flex items-center gap-1 cursor-pointer"
                                onClick={() => {
                                    handleSetStep(SEND_MONEY_STEP_TWO);
                                }}
                            >
                                <img src={edit} alt="Edit Icon" className="size-4" />
                                <span className="text-xs font-normal cursor-pointer !text-secondary">
                                    Edit
                                </span>
                            </div>
                        </div>

                        {/* Render the selected beneficiary if beneficiary is selected else render the add beneficiary button */}

                        <SummaryBeneficiaryBox
                            selectedBeneficiary={selectedBeneficiary}
                            setShowBeneficiary={setShowBeneficiary}
                        />
                    </div>

                    {/* Payment information */}
                    <div className="flex flex-col w-full gap-2 mt-4">
                        <div className="flex items-center justify-between">
                            <label htmlFor="" className="text-sm font-medium text-main-grey">
                                Payment information
                            </label>
                            <div
                                className="flex items-center gap-1 cursor-pointer"
                                onClick={() => {
                                    handleSetStep(SEND_MONEY_STEP_TWO);
                                }}
                            >
                                <img src={edit} alt="Edit Icon" className="size-4" />
                                <span className="text-xs font-normal cursor-pointer !text-secondary">
                                    Edit
                                </span>
                            </div>
                        </div>

                        <div className="p-3 space-y-4 bg-white rounded-lg cursor-pointer">
                            <div className="flex items-center justify-between w-full text-base text-gray-10">
                                <p className="">Invoice</p>
                                <p className="font-light ">
                                    {invoiceFile?.name ?? "No File Selected"}
                                </p>
                            </div>
                            <div className="flex items-center justify-between w-full text-base text-gray-10">
                                <p className="">Supporting document</p>
                                <p className="font-light">
                                    {supportingFile?.name ?? "No File Selected"}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px] w-full">
                        <label
                            htmlFor=""
                            className="text-[14px] leading-[19.6px] font-medium	text-[#101828]"
                        >
                            Add memo
                        </label>
                        <div className="p-[3px] h-[120px] rounded-[8px]">
                            <textarea
                                className="rounded-[8px] p-[16px] w-full h-full outline-none bg-[#EAECF0] resize-none shadow-none"
                                placeholder="Write a note here"
                                value={summaryMemo}
                                required
                                onChange={(e) => {
                                    setSummaryMemo(e);
                                }}
                            ></textarea>
                        </div>
                    </div>

                    <div className="flex items-center justify-end">
                        <div className="flex">
                            <button
                                onClick={() =>
                                    sendMoneyStep === SEND_MONEY
                                        ? handleBack
                                        : handleSetStep(SEND_MONEY)
                                }
                                type="button"
                                className={`flex px-[24px] py-[12px] h-[40px] mr-2 text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-[#1D2939]`}
                            >
                                Back
                            </button>
                            <button
                                disabled={isDisable || !selectedBeneficiary}
                                className={`flex px-[24px] py-[12px] h-[40px] text-[16px] rounded-[8px] items-center justify-center border-none outline-none text-white ${
                                    isDisable || !selectedBeneficiary
                                        ? "bg-[#EBE4FF]"
                                        : "bg-[#6F00FF]"
                                }`}
                                onClick={() => {
                                    setShowPinModal(true);
                                }}
                                type="button"
                            >
                                Send {currencyPairCheck(state?.default)}
                                {amount}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <EnterPinModal
                showPinModal={showPinModal}
                setShowPinModal={() => setShowPinModal(false)}
                callBack={callBack}
            />
            <SuccessfulTrxnModal
                showSuccessModal={showSuccessModal}
                amount={amount}
                selectedBeneficiary={selectedBeneficiary}
                setShowSuccessModal={() => setShowSuccessModal(false)}
            />
        </div>
    );
};

export default FxSendMoneySummary;
