import React from "react";
import { ADD_RECEIVER_INFO, ADD_SHIPMENT_DETAILS } from "constants/global";
import boat from "../../../asset/images/boat.svg";
import truck from "../../../asset/icons/truck.svg";
import Input from "component/form/Input";
import Dropdown from "component/form/dropDown";

const AddPaymentInfo = ({ submitFirstForm, goToStep }) => {
    return (
        <form onSubmit={(evt) => evt.preventDefault()} className="ml-48 w-[610px]">
            <h3 className="text-main-dark font-[450] text-2xl !mb-6">Add payment information</h3>

            <div>
                <div className="flex flex-col gap-[8px] w-full my-3">
                    <p className="text-[14px] leading-[19.6px] font-medium	text-gray-10">
                        Shipment Route
                    </p>
                    <div className="flex mt-2 rounded-md gap-x-2">
                        <div className="flex items-center w-1/2 h-auto gap-2 px-3 py-2 rounded-md bg-gray-30">
                            <div className="flex items-center">
                                <span className="mr-2 text-base balance_number text-gray-10">
                                    <img src={boat} alt="boat" className="size-6" />
                                </span>

                                <p className="text-main-grey">Clearing fee</p>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                placeholder="$ 46"
                                name="clearingFee"
                                onChange={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex mt-2 rounded-md gap-x-2">
                        <div className="flex items-center w-1/2 h-auto gap-2 px-3 py-2 rounded-md bg-gray-30">
                            <div className="flex items-center">
                                <span className="mr-2 text-base balance_number text-gray-10">
                                    <img src={truck} alt="truck" className="size-6" />
                                </span>

                                <p className="text-main-grey">Trucking fee</p>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                placeholder="$ 46"
                                name="clearingFee"
                                onChange={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-[8px] w-full my-3">
                    <p className="text-[14px] leading-[19.6px] font-medium	text-gray-10">
                        Additional costs (optional)
                    </p>
                    <div className="flex mt-2 rounded-md gap-x-2">
                        <div className="flex items-center w-1/2">
                            <Dropdown
                                value={""}
                                name="selectShippingMethod"
                                options={[
                                    "Shipping fee",
                                    "Handling fee",
                                    "Insurance",
                                    "Packaging fee",
                                    "Warehousing fee",
                                    "Tax and duty",
                                    "Miscellaneous fee",
                                ].map((option) => ({
                                    label: option,
                                    value: option,
                                }))}
                                onChange={(e) => {
                                    console.log(e);
                                }}
                                labelClassName="!text-[#475467]"
                                placeholder="Select shipping method"
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                placeholder="$ 46"
                                name="clearingFee"
                                onChange={(e) => {
                                    console.log(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <button className="!text-secondary">+ Add another</button>

                <div className="p-2 mt-5 text-right border-t border-dashed">
                    <p>
                        <span>Total Shipping costs</span> : $ 580
                    </p>
                </div>

                <Dropdown
                    value={""}
                    name="containerType"
                    options={[
                        "Payment before shipment",
                        "Payment on delivery",
                        "Split payment",
                    ].map((option) => ({ label: option, value: option }))}
                    onChange={(e) => {
                        console.log(e);
                    }}
                    label="Payment terms"
                    labelClassName="!text-[#475467]"
                    placeholder="Payment on deliveries"
                />
            </div>
            <div className="flex !gap-9 justify-end !mt-6">
                <button
                    onClick={() => {
                        goToStep(ADD_SHIPMENT_DETAILS);
                    }}
                    type="button"
                    className="button text-[#1D2939] font-[450] !py-2 !px-6"
                >
                    Back
                </button>
                <button
                    onClick={() => submitFirstForm(ADD_RECEIVER_INFO)}
                    className={`!py-2 !px-6 text-white rounded-lg font-[450] ${
                        true ? "bg-[#D9CDFF]" : "bg-secondary"
                    }`}
                    type="submit"
                >
                    Continue
                </button>
            </div>
        </form>
    );
};

export default AddPaymentInfo;
