import React, { useState, useRef, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { EmojiPicker, FileAttach, NoMessages, SendMessage } from "asset/icons";
import Picker from "@emoji-mart/react";
import axios from "axios";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { LuDot } from "react-icons/lu";

const getInitials = (name) => {
    if (typeof name === "string") {
        const nameParts = name.split(" ");
        return nameParts.length === 1
            ? nameParts[0][0].toUpperCase()
            : (nameParts[0][0] + nameParts[nameParts.length - 1][0]).toUpperCase();
    } else if (name && name.fullName) {
        const nameParts = name.fullName.split(" ");
        return nameParts.length === 1
            ? nameParts[0][0].toUpperCase()
            : (nameParts[0][0] + nameParts[nameParts.length - 1][0]).toUpperCase();
    }
    return "";
};

const formatDate = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
        return "just now";
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 172800) {
        return "yesterday";
    } else {
        return date.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }
};

const InputSection = ({
    value,
    onChange,
    onSend,
    inputId,
    uploadedFile,
    onFileChange,
    onFileRemove,
    fileInputRef,
}) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const emojiPickerRef = useRef(null);

    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className="rounded-lg bg-[#EAECF0] px-3 py-2 relative">
                <input
                    type="text"
                    placeholder="Type here..."
                    className="flex-grow !shadow-none w-full py-2 outline-none pb-3 placeholder-gray-500"
                    value={value}
                    onChange={onChange}
                />

                {uploadedFile && (
                    <div className="flex items-center px-4 py-2 mt-2 bg-white rounded-lg w-72">
                        <span className="mr-2 text-sm font-semibold">{uploadedFile.name}</span>
                        <button onClick={() => onFileRemove(inputId)} className="ml-auto">
                            <AiOutlineClose size={18} className="text-gray-600" />
                        </button>
                    </div>
                )}

                <div className="flex items-center justify-between pt-3">
                    <div className="flex items-center gap-3">
                        <label htmlFor={`file-upload-${inputId}`} className="cursor-pointer">
                            <FileAttach size={22} />
                        </label>
                        <input
                            id={`file-upload-${inputId}`}
                            type="file"
                            accept=".pdf,.doc,.docx,.jpg,.png"
                            className="hidden"
                            onChange={(e) => onFileChange(inputId, e)}
                            ref={fileInputRef}
                        />
                        <button className="mr-3" onClick={toggleEmojiPicker}>
                            <EmojiPicker size={22} />
                        </button>
                    </div>
                    <button
                        type="button"
                        onClick={onSend}
                        disabled={!value.trim() && !uploadedFile}
                        className="rounded-full p-2 bg-[#6F00FF]"
                    >
                        <SendMessage color="white" />
                    </button>
                </div>
                {showEmojiPicker && (
                    <div ref={emojiPickerRef} className="absolute z-50 bottom-28 left-4">
                        <Picker
                            onEmojiSelect={(emoji) =>
                                onChange({ target: { value: value + emoji.native } })
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const Messages = ({ messages = [], onSendMessage, orderId, businessName }) => {
    const [message, setMessage] = useState("");
    const [visibleReplies, setVisibleReplies] = useState(null);
    const [repliesData, setRepliesData] = useState({});
    const [replyInputData, setReplyInputData] = useState({});
    const [fileData, setFileData] = useState({});
    const fileInputRefs = useRef({});

    const handleFileChange = (inputId, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("File selected:", file); // Check if this logs when you select a file
            setFileData((prevFiles) => ({
                ...prevFiles,
                [inputId]: file,
            }));
        }
    };

    const handleFileRemove = (inputId) => {
        setFileData((prevFiles) => ({
            ...prevFiles,
            [inputId]: null,
        }));
    };

    const handleSendMessage = async () => {
        // Ensure at least one of message or attachment is present
        if (message.trim() === "" && !fileData["main"]) {
            return; // Do nothing if both are empty
        }

        const formData = new FormData();

        // Append message if it exists
        if (message.trim() !== "") {
            formData.append("message", message);
        }

        // Append file if it exists
        if (fileData["main"]) {
            formData.append("file", fileData["main"]);
        }

        try {
            const response = await axios.post(
                `/v1/message/flex/create-order/${orderId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data) {
                onSendMessage((prevMessages) => [...prevMessages, response.data]);
            }

            setMessage("");
            handleFileRemove("main");
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const handleAttachClick = (messageId) => {
        // If the reply input is not visible, set it to visible
        if (visibleReplies !== messageId) {
            setVisibleReplies(messageId);

            // Use a short delay to ensure rendering completes before triggering the file input click
            setTimeout(() => {
                if (fileInputRefs.current[messageId]) {
                    fileInputRefs.current[messageId].click();
                } else {
                    console.error(
                        `File input reference for message ${messageId} is not available.`
                    );
                }
            }, 0);
        } else if (fileInputRefs.current[messageId]) {
            // If reply input is already visible, trigger file input directly
            fileInputRefs.current[messageId].click();
        } else {
            console.error(`File input reference for message ${messageId} is not available.`);
        }
    };

    const fetchReplies = async (messageId) => {
        try {
            const response = await axios.get(`/v1/message/replies/${messageId}`);
            setRepliesData((prevReplies) => ({ ...prevReplies, [messageId]: response.data }));
        } catch (error) {
            console.error("Error fetching replies:", error);
        }
    };

    const toggleReplies = (messageId) => {
        if (visibleReplies === messageId) {
            setVisibleReplies(null);
        } else {
            setVisibleReplies(messageId);
            if (!repliesData[messageId]) {
                fetchReplies(messageId);
            }
        }
    };

    const handleReplyInputChange = (messageId, e) => {
        setReplyInputData({ ...replyInputData, [messageId]: e.target.value });
    };

    const handleSendReply = async (messageId) => {
        const replyMessage = replyInputData[messageId];
        if (!replyMessage || replyMessage.trim() === "") return;

        const formData = new FormData();
        formData.append("message", replyMessage);
        if (fileData[messageId]) {
            formData.append("file", fileData[messageId]);
        }

        try {
            const response = await axios.post(`/v1/message/reply/${messageId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data) {
                setRepliesData((prevReplies) => ({
                    ...prevReplies,
                    [messageId]: [...(prevReplies[messageId] || []), response.data],
                }));
                onSendMessage((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg._id === messageId
                            ? { ...msg, replies: [...(msg.replies || []), response.data] }
                            : msg
                    )
                );
            }

            setReplyInputData((prevInputData) => ({
                ...prevInputData,
                [messageId]: "",
            }));
            handleFileRemove(messageId);
            await fetchReplies(messageId);
        } catch (error) {
            console.error("Error sending reply:", error);
        }
    };

    return (
        <div className="relative flex flex-col gap-4 p-6 bg-white rounded-lg">
            <div className="flex items-start gap-2 rounded-lg">
                <div className="flex items-center justify-center w-10 h-10 font-semibold text-white bg-gray-300 rounded-full">
                    {getInitials(businessName)}
                </div>

                <div className="flex-1">
                    <InputSection
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onSend={handleSendMessage}
                        inputId="main"
                        uploadedFile={fileData["main"]}
                        onFileChange={handleFileChange}
                        onFileRemove={handleFileRemove}
                    />
                </div>
            </div>

            <div className="flex flex-col gap-6">
                {messages.length > 0 ? (
                    messages
                        .slice()
                        .reverse()
                        .map((msg, index, reversedMessages) => (
                            <div
                                key={msg._id}
                                className={`flex flex-col gap-2 p-2 pb-4 rounded-lg ${
                                    index !== reversedMessages.length - 1 ? "border-b" : ""
                                }`}
                            >
                                <div className="flex gap-4">
                                    <div className="flex items-center justify-center w-10 h-10 font-semibold text-white bg-gray-300 rounded-full">
                                        {getInitials(msg.senderId.name)}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex items-center gap-2">
                                            <div className="text-sm font-semibold text-gray-700">
                                                {msg.senderId.fullName ||
                                                    msg.senderId.name ||
                                                    "Unknown"}
                                            </div>
                                            <div className="text-xs text-gray-500">
                                                {formatDate(new Date(msg.created_at))}
                                            </div>
                                        </div>
                                        <div className="mt-1 mb-2 text-sm text-gray-700">
                                            <p className="pb-3">{msg.message}</p>
                                            {msg.attachmentUrl && (
                                                <a
                                                    href={msg.attachmentUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="px-2 py-2 mt-2 text-blue-500 underline border-2 rounded-lg"
                                                >
                                                    {msg.attachmentName}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between gap-4 pl-16">
                                    {msg.replies && msg.replies.length > 0 && (
                                        <div
                                            className="text-[#667085] cursor-pointer flex items-center gap-1"
                                            onClick={() => toggleReplies(msg._id)}
                                        >
                                            {msg.replies.length}{" "}
                                            {msg.replies.length > 1 ? "Replies" : "Reply"}
                                            {visibleReplies === msg._id ? (
                                                <FaChevronUp size={12} />
                                            ) : (
                                                <FaChevronDown size={12} />
                                            )}
                                        </div>
                                    )}
                                    <div className="flex items-center gap-1">
                                        <div
                                            className="text-[#1D2939] cursor-pointer"
                                            onClick={() => toggleReplies(msg._id)}
                                        >
                                            Reply
                                        </div>
                                        <LuDot />
                                        <div
                                            className="text-[#1D2939] cursor-pointer"
                                            onClick={() => handleAttachClick(msg._id)}
                                        >
                                            Attach
                                        </div>
                                    </div>
                                </div>

                                {/* Replies Section */}
                                {visibleReplies === msg._id && (
                                    <div className="relative flex flex-col gap-4 pt-2 mt-2">
                                        {/* Vertical Line */}
                                        {repliesData[msg._id]?.length > 0 && (
                                            <div className="absolute left-4 top-[-110px] bottom-48 w-px bg-gray-300"></div>
                                        )}
                                        {repliesData[msg._id]?.map((reply) => (
                                            <div
                                                key={reply._id}
                                                className="relative flex items-start gap-4"
                                            >
                                                <div className="flex items-center justify-center w-8 h-8 font-semibold text-white bg-gray-200 rounded-full">
                                                    {getInitials(reply.senderId.name)}
                                                </div>
                                                <div className="flex flex-col">
                                                    <div>
                                                        <span className="text-xs font-semibold text-gray-700">
                                                            {reply.senderId.fullName ||
                                                                reply.senderId.name ||
                                                                "Unknown"}
                                                        </span>
                                                        <span className="pl-3 text-xs text-gray-500">
                                                            {formatDate(new Date(reply.created_at))}
                                                        </span>
                                                    </div>
                                                    <p className="py-2 text-sm text-gray-700">
                                                        {reply.message}
                                                    </p>
                                                    {reply.attachmentUrl && (
                                                        <a
                                                            href={reply.attachmentUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="px-2 py-1 mt-2 text-blue-500 underline border-2 rounded-lg"
                                                        >
                                                            {reply.attachmentName}
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        {/* Input Section for Reply */}
                                        <div className="flex items-start gap-2 rounded-lg">
                                            <div className="flex items-center justify-center w-10 h-10 font-semibold text-white bg-gray-300 rounded-full">
                                                {getInitials(businessName)}
                                            </div>
                                            <div className="flex-1">
                                                <InputSection
                                                    value={replyInputData[msg._id] || ""}
                                                    onChange={(e) =>
                                                        handleReplyInputChange(msg._id, e)
                                                    }
                                                    onSend={() => handleSendReply(msg._id)}
                                                    inputId={msg._id}
                                                    uploadedFile={fileData[msg._id]}
                                                    onFileChange={handleFileChange}
                                                    onFileRemove={handleFileRemove}
                                                    fileInputRef={(el) =>
                                                        (fileInputRefs.current[msg._id] = el)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                ) : (
                    <div className="flex flex-col items-center mt-4 text-center text-gray-500">
                        <NoMessages />
                        <p>No messages here</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Messages;
