import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
    ADD_ITEM_DETAILS,
    ADD_PAYMENT_INFO,
    ADD_RECEIVER_INFO,
    ADD_SHIPMENT_DETAILS,
} from "constants/global";

const FormSteps = ({ currentStep, setShippingDetailsStep }) => {
    const navigate = useNavigate();
    const steps = [
        {
            title: "Add Item Details",
            path: ADD_ITEM_DETAILS,
        },
        {
            title: "Add Shipment Details",
            path: ADD_SHIPMENT_DETAILS,
        },
        {
            title: "Add Payment Info",
            path: ADD_PAYMENT_INFO,
        },
        {
            title: "Add Receiver Info",
            path: ADD_RECEIVER_INFO,
        },
    ];
    return (
        <div className={`hidden md:flex gap-4 min-w-[177px]`}>
            <div className="flex flex-col items-center">
                {steps.map(({ path }, index) => (
                    <Fragment key={index}>
                        <span
                            className={`${
                                false ? "!bg-secondary" : "!bg-[#98A2B3]"
                            } rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]`}
                        >
                            {index + 1}
                        </span>
                        {index + 1 !== steps.length && (
                            <span className="!bg-[#C0CAD8] w-[2px] h-5" />
                        )}
                    </Fragment>
                ))}
            </div>
            {/* <div className="flex flex-col items-center">
                <span className="!bg-secondary rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                    1
                </span>
                <span className="!bg-[#C0CAD8] w-[2px] h-5" />
                <span className="!bg-[#98A2B3] rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                    2
                </span>
                <span className="!bg-[#C0CAD8] w-[2px] h-5" />
                <span className="!bg-[#98A2B3] rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                    3
                </span>
                <span className="!bg-[#C0CAD8] w-[2px] h-5" />
                <span className="!bg-[#98A2B3] rounded-full size-6 font-[450] text-base text-center text-[#F4F1FF]">
                    4
                </span>
            </div> */}
            <div className="flex flex-col !gap-5">
                {steps.map((step, index) => (
                    <p
                        key={index}
                        className={`font-medium cursor-pointer ${
                            currentStep === step.path ? "!text-secondary" : "!text-main"
                        }`}
                        onClick={() => setShippingDetailsStep(step.path)}
                    >
                        {step.title}
                    </p>
                ))}
            </div>
            {/* <div className="flex flex-col !gap-5">
                <p
                    className={`font-medium !text-main`}
                    // onClick={() => navigate("/flex/shipments/create")}
                    onClick={() => setShippingDetailsStep(ADD_ITEM_DETAILS)}
                >
                    Add item details
                </p>
                <p
                    // onClick={() => navigate("/flex/shipments/add-details")}
                    onClick={() => setShippingDetailsStep(ADD_SHIPMENT_DETAILS)}
                    className={`font-medium !text-secondary`}
                >
                    Add shipping details
                </p>
                <p
                    className={`font-medium text-gray-400`}
                    onClick={() => setShippingDetailsStep(ADD_PAYMENT_INFO)}
                >
                    Add payment information
                </p>
                <p
                    className={`font-medium text-gray-400`}
                    onClick={() => setShippingDetailsStep(ADD_RECEIVER_INFO)}
                >
                    Enter receiver info
                </p>
            </div> */}
        </div>
    );
};

export default FormSteps;
