import { ArrowSquaredIcon } from "asset/icons";
import useScreenSize from "hooks/useScreenSize";
import { useMemo } from "react";

const Pagination = ({ currentPage, setCurrentPage, totalPages = 0 }) => {
    const { screenWidth } = useScreenSize();
    const midScreen = screenWidth < 768;
    const pages = Array.from({ length: totalPages }, (_, idx) => idx + 1);

    const pagesData = useMemo(() => {
        if (pages.length < 6)
            return {
                pages,
                gridTemplateColumns: midScreen
                    ? `repeat(${2 + pages.length},40px)`
                    : `74px repeat(${pages.length},40px) 78px`,
            };

        const getFirstAndLast2 = (arr) =>
            midScreen
                ? [arr[0], arr[1], "...", arr.slice(-2)[0], arr.slice(-1)[0]]
                : arr.length % 2 || arr.length >= 8
                ? [
                      arr[0],
                      arr[1],
                      arr[2],
                      "...",
                      arr.slice(-3)[0],
                      arr.slice(-2)[0],
                      arr.slice(-1)[0],
                  ]
                : arr;
        return {
            pages: getFirstAndLast2(pages),
            gridTemplateColumns: midScreen
                ? "repeat(7,40px)"
                : `74px repeat(${pages.length === 6 ? 6 : 7},40px) 50px`,
        };
    }, [pages, midScreen]);

    if (totalPages < 2) return null;

    return (
        <div
            className="h-10 rounded-lg overflow-hidden !border !border-[#D0D5DD] w-max mx-auto !mt-3 !mb-4 !text-main text-sm"
            style={{
                display: "grid",
                gridTemplateColumns: pagesData.gridTemplateColumns,
            }}
        >
            <span
                className={`!border-r grid place-items-center  ${
                    currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => currentPage !== 1 && setCurrentPage((prev) => prev - 1)}
            >
                {midScreen ? <ArrowSquaredIcon /> : "Previous"}
            </span>
            {pagesData.pages.map((page) => (
                <span
                    key={page}
                    className={`!border-r grid place-items-center transition-colors duration-200 ${
                        page === "..." ? "cursor-default pointer-events-none" : "cursor-pointer"
                    } ${currentPage === page && "bg-grey-bg"}`}
                >
                    {page}
                </span>
            ))}
            <span
                className={`grid place-items-center  ${
                    currentPage === pages.length ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => currentPage !== pages.length && setCurrentPage((prev) => prev + 1)}
            >
                {midScreen ? <ArrowSquaredIcon className="rotate-180" /> : "Next"}
            </span>
        </div>
    );
};

export default Pagination;
