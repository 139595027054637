import { useEffect, useRef, useState } from "react";
import ArrowBack from "../component/arrowback";
import LoginHeader from "../component/loginheader";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_USER } from "../store/action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loaders from "../component/loader";
import { useDispatch } from "react-redux";
import SuccessHodler from "../component/successholder";
import FailHandler from "../component/failhandler";

const VerifyNumber = () => {
    const [pins, setPins] = useState(["", "", "", ""]);
    const { state } = useLocation();

    const inputs = useRef([]);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [message, setMessage] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (success || fail) {
            const timer = setTimeout(() => {
                setSuccess(false);
                setFail(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [success, fail]);

    useEffect(() => {
        const sendOtp = async () => {
            const data = { phoneNumber: state.phoneNumber };
            try {
                const res = await axios.post(`/v1/users/send-verification-sms`, data);
                setMessage(res.data.message);
                setSuccess(true);
            } catch (e) {
                setMessage(e.response?.data?.message || "An error occurred while sending the OTP.");
                setFail(true);
            } finally {
                setLoader(false);
            }
        };
        sendOtp();
    }, [state.phoneNumber]);

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        if (/^\d$/.test(value)) {
            const newPins = [...pins];
            newPins[index] = value;
            setPins(newPins);

            if (index < pins.length - 1) {
                inputs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "ArrowRight" && index < pins.length - 1) {
            inputs.current[index + 1]?.focus();
        } else if (event.key === "ArrowLeft" && index > 0) {
            inputs.current[index - 1]?.focus();
        } else if (event.key === "Backspace") {
            if (pins[index] === "") {
                if (index > 0) {
                    inputs.current[index - 1]?.focus();
                }
            } else {
                const newPins = [...pins];
                newPins[index] = "";
                setPins(newPins);
            }
        } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            event.preventDefault();
        }
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        setLoader(true);
        const data = {
            phoneNumber: state.phoneNumber,
            code: pins.join(""),
        };
        try {
            await axios.post("/v1/users/verify-sms", data);
            await reDirect();
        } catch (e) {
            setMessage(e.response?.data?.message || "An error occurred during verification.");
            setFail(true);
        } finally {
            setLoader(false);
        }
    };

    const ResendLink = async (e) => {
        e.preventDefault();
        setLoader(true);
        const data = { phoneNumber: state.phoneNumber };
        try {
            const res = await axios.post(`/v1/users/send-verification-sms`, data);
            setMessage(res.data.message);
            setSuccess(true);
        } catch (e) {
            setMessage(e.response?.data?.message || "An error occurred while resending the code.");
            setFail(true);
        } finally {
            setLoader(false);
        }
    };

    const reDirect = async () => {
        const data = {
            email: state.email,
            password: state.password,
        };
        LOGIN_USER(data, dispatch)
            .then(() => {
                navigate("/business/document", { state: { ...state } });
            })
            .catch(console.error);
    };

    return (
        <div
            className=""
            style={{
                background: "#F6F8FA",
                paddingRight: "0px",
                maxWidth: "2000px",
                width: "100%",
                margin: "0px auto",
            }}
        >
            <LoginHeader width={55} />
            <div
                className="p-2 justify-self-center align-items-center d-flex"
                style={{ height: "calc(100% - 200px)" }}
            >
                <div
                    className="form-general-layout mt-20"
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        position: "relative",
                    }}
                >
                    <SuccessHodler message={message} success={success} />
                    <FailHandler message={message} success={fail} />
                    <div className="card-body">
                        <ArrowBack />
                        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                            <div
                                style={{
                                    fontWeight: "500",
                                    color: "#101828",
                                    fontSize: "32px",
                                    lineHeight: "40px",
                                }}
                                className="mt-3"
                            >
                                Verify Phone Number
                            </div>
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#667085",
                                    letterSpacing: "2%",
                                    lineHeight: "24px",
                                    fontWeight: "400",
                                }}
                            >
                                Enter the 4-digit code sent to:
                                <span
                                    style={{
                                        color: "#6F00FF",
                                        fontWeight: "600",
                                        letterSpacing: "2%",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {state.phoneNumber}
                                </span>
                            </div>
                        </div>
                        <form
                            onSubmit={handleVerify}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                            }}
                        >
                            <div
                                className="row mt-4"
                                style={{
                                    maxWidth: "350px",
                                    width: "100%",
                                }}
                            >
                                {pins.map((pin, index) => (
                                    <div className="col" key={index}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control border-0"
                                                style={{
                                                    background: "#ECEFF3",
                                                    color: "#667085",
                                                    padding: "8px 16px",
                                                    textAlign: "center",
                                                    height: "53px",
                                                    minWidth: "56px",
                                                    fontWeight: "400",
                                                }}
                                                maxLength="1"
                                                placeholder="-"
                                                value={pin}
                                                ref={(el) => (inputs.current[index] = el)}
                                                onChange={(event) =>
                                                    handleInputChange(event, index)
                                                }
                                                onKeyDown={(event) => handleKeyDown(event, index)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className=""
                                style={{
                                    fontSize: "14px",
                                    color: "#344054",
                                    fontWeight: "400",
                                    lineHeight: "19.6px",
                                }}
                            >
                                <span>
                                    Can’t find the code?{" "}
                                    <span
                                        type="button"
                                        style={{
                                            color: "#6F00FF",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "19.6px",
                                            marginLeft: "5px",
                                        }}
                                        onClick={ResendLink}
                                    >
                                        Resend
                                    </span>
                                </span>
                            </div>
                            <button
                                disabled={loader}
                                type="submit"
                                className="btn mr-2"
                                style={{
                                    background: "#6F00FF",
                                    color: "#fff",
                                    width: "50%",
                                    fontSize: "1rem",
                                    borderRadius: "8px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "2%",
                                    padding: "16px 24px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                {loader ? <Loaders data={loader} /> : "Verify Number"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyNumber;
