import Modal from "react-bootstrap/Modal";
import person from "../asset/images/usertradevu.png";

import { useEffect, useState } from "react";
import BuyerDetail from "./buyerdetail";
import axios from "axios";
import Loader from "./loader";
import edit from "../asset/images/edit-2.svg";
import "../style/main.css";
import bank from "../asset/images/bankicon.svg";
import ActivePartner from "./ActivePartner";
import { useLocation, useNavigate } from "react-router-dom";
function AddPaymentRecipient(props) {
  const [modalShow, setModalShow] = useState(false);
  const [buyerdetailsinfo, setbuyerdetailsinfo] = useState([]);
  const [firstName, setfirstName] = useState("");
  const [lasttName, setlastName] = useState("");
  const [businessName, setBusinesssName] = useState("");
  const [iban, setiban] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [accountName, setaccountName] = useState("");
  const [means, setmeans] = useState("Individual");
  const [loader, setloader] = useState(false);
  const types = ["Individual", "Business"];
  const [bankCode, setbankCode] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [swift, setswift] = useState("");
  // const [sesid, setsesid] = useState('')
  const navigate = useNavigate();
  const { state } = useLocation();
  //console.log(props.selectedbank);
  useEffect(() => {
      const getBankDetail = async () => {
          if (accountNumber.length === 10 && props.selectedbank.name !== "")
              await axios
                  .post(`/v1/wallets/verifyBankDetail`, {
                      accountNumber: accountNumber,
                      bankCode: props.selectedbank.bankCode,
                  })
                  .then((res) => {
                      // console.log(res);
                      setaccountName(res.data.data.accountName);
                      setiban(res.data.data.sessionId);
                  })
                  .catch((e) => {
                      console.log(e);
                  });
      };
      getBankDetail();
  }, [accountNumber, props.selectedbank.name]);
  // console.log(props.selectedbank);

  const submitBuyerDetails = async (e) => {
      e.preventDefault();
      if (iban && accountNumber && props.selectedbank.length !== 0) {
          setloader(true);

          const data = {
              IBAN: iban,
              accountNumber: accountNumber,
              ...props.state,
              // firstName: means === "Individual" && firstName,
              //  lastName: means === "Individual" && lasttName,
              //  businessName: means === "Business" && businessName,
              currency: props.currency,
              //   invoiceId: id,
              name: props.currency !== "NGN" ? props.selectedbank.name : props.selectedbank.name,
              amount: props.amount,
              sortCode: bankCode !== "" ? bankCode : props.selectedbank.code,
              routingNumber: routingNumber,
              recipientType: means,
              SWIFT: swift,
              accountName:
                  accountName !== ""
                      ? accountName
                      : means === "Individual"
                      ? firstName + " " + lasttName
                      : businessName,
              typeOf: "not-tradevu",
          };
          navigate("/payment/document", {
              state: {
                  ...data,
              },
          });
      }
  };
  // console.log(props.selectedbank.name);

  return (
      <>
          <ActivePartner
              show={modalShow}
              onHide={() => setModalShow(false)}
              buyerdetail={buyerdetailsinfo}
              setbuyerdetailsinfo={setbuyerdetailsinfo}
              selecteditem={props.selecteditem}
              setselecteditem={props.setselecteditem}
          />

          <Modal
              show={props.show}
              backdrop="false"
              keyboard={false}
              onHide={() => props.setshow(false)}
          >
              <Modal.Body
                  className="p-0"
                  style={{
                      height: "100%",
                  }}
              >
                  <div
                      style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "24px",
                      }}
                  >
                      <div
                          className=""
                          style={{
                              borderBottom: "1px solid #E7E9FB",
                              padding: "8px 24px 8px 24px",
                          }}
                      >
                          <span
                              className="material-icons"
                              style={{
                                  color: "#667085",
                                  cursor: "pointer",
                              }}
                              onClick={() => {
                                  props.setshow(false);
                              }}
                          >
                              close
                          </span>
                      </div>
                      <div
                          style={{
                              padding: "8px 24px 8px 24px",
                          }}
                      >
                          <form
                              onSubmit={submitBuyerDetails}
                              style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "16px",
                              }}
                          >
                              <div className="top-buyer-add">
                                  <h3
                                      className="my-titile-add-new-buyer"
                                      style={{
                                          fontWeight: "600",
                                      }}
                                  >
                                      {" "}
                                      Enter Recipient Details
                                  </h3>
                              </div>
                              <div
                                  className="search-tradevu-user"
                                  // onClick={() => props.setshowbank(true)}
                              >
                                  {props.selectedbank.length === 0 && (
                                      <div
                                          className="bord-trade-log"
                                          style={{
                                              minHeight: "56px",
                                          }}
                                          onClick={() => {
                                              //alert()
                                              props.setshowbank(true);
                                          }}
                                      >
                                          <div className="lg-mod-gl">
                                              <div className="lg-mf-lg">
                                                  <img src={bank} alt="" height={20} />
                                                  <span>Choose Recipient Bank</span>
                                              </div>

                                              <span className="material-icons">chevron_right</span>
                                          </div>
                                      </div>
                                  )}
                                  {props.selectedbank.length === undefined && (
                                      <div
                                          className="main-each-lg"
                                          style={{
                                              background: "#fff",
                                              alignItems: "center",
                                          }}
                                          onClick={() => {
                                              props.setshowbank(true);
                                          }}
                                      >
                                          <div className="lg-sie-avater-lg">
                                              <div
                                                  className=""
                                                  style={{
                                                      gap: "0px",

                                                      display: "flex",
                                                      gap: "8px",
                                                      alignItems: "center",
                                                  }}
                                              >
                                                  <img src={bank} alt="" height={20} />
                                                  {props.currency !== "NGN"
                                                      ? props.selectedbank.name
                                                      : props.selectedbank?.name}
                                              </div>
                                          </div>
                                          <img src={edit} alt="" />
                                      </div>
                                  )}
                              </div>
                              <div className="form-select-group">
                                  {props?.currency !== "NGN" && (
                                      <input
                                          type="text"
                                          className="each-form-group"
                                          style={{
                                              textTransform: "none",
                                          }}
                                          placeholder="IBAN"
                                          value={iban}
                                          required
                                          onChange={(e) => {
                                              setiban(e.target.value);
                                          }}
                                      />
                                  )}

                                  <input
                                      type="text"
                                      className="each-form-group"
                                      placeholder="Account Number"
                                      value={accountNumber}
                                      style={{
                                          textTransform: "none",
                                      }}
                                      required
                                      onChange={(e) => {
                                          setaccountNumber(e.target.value);
                                      }}
                                  />
                                  {props.currency === "NGN" && accountName !== "" ? (
                                      <div
                                          className="white-bh-lg"
                                          style={{
                                              minHeight: "56px",
                                          }}
                                      >
                                          <div className="transfer-bank-logo">
                                              <div className="intial">
                                                  {props.currency === "NGN"
                                                      ? accountName
                                                            .match(/(\b\S)?/g)
                                                            .join("")
                                                            .match(/(^\S|\S$)?/g)
                                                            .join("")
                                                            .toUpperCase()
                                                      : ""}
                                              </div>
                                              <div className="name-bank">
                                                  <span
                                                      className="name-link"
                                                      style={{
                                                          fontWeight: "500",
                                                      }}
                                                  >
                                                      {props.currency === "NGN" && accountName}
                                                  </span>
                                                  <span
                                                      className="bank-link"
                                                      style={{
                                                          fontWeight: "400",
                                                          // border: "1px solid red",
                                                          textAlign: "left !important",
                                                      }}
                                                  >
                                                      {props.currency === "NGN" &&
                                                          props.selectedbank.name}{" "}
                                                      . {props.currency === "NGN" && accountNumber}
                                                  </span>
                                              </div>
                                          </div>
                                          <img src={edit} alt="" />
                                      </div>
                                  ) : (
                                      ""
                                  )}
                                  {props?.currency !== "NGN" && (
                                      <>
                                          <label htmlFor="">Recipient</label>
                                          <select
                                              className="each-form-group"
                                              placeholder="Select type"
                                              value={means}
                                              required={props.selecteditems.length === 0}
                                              onChange={(e) => {
                                                  setmeans(e.target.value);
                                              }}
                                              style={{
                                                  textTransform: "none",
                                              }}
                                          >
                                              {types.map((typ, index) => (
                                                  <option value={typ} key={index}>
                                                      {typ}
                                                  </option>
                                              ))}
                                          </select>
                                          {means === "Business" && (
                                              <input
                                                  type="text"
                                                  className="each-form-group"
                                                  placeholder="Business name"
                                                  value={businessName}
                                                  required
                                                  onChange={(e) => {
                                                      setBusinesssName(e.target.value);
                                                  }}
                                                  style={{
                                                      textTransform: "none",
                                                  }}
                                              />
                                          )}
                                          {means === "Individual" && (
                                              <>
                                                  <input
                                                      type="text"
                                                      className="each-form-group"
                                                      placeholder="First name"
                                                      value={firstName}
                                                      required
                                                      onChange={(e) => {
                                                          setfirstName(e.target.value);
                                                      }}
                                                      style={{
                                                          textTransform: "none",
                                                      }}
                                                  />
                                                  <input
                                                      type="text"
                                                      className="each-form-group"
                                                      placeholder="Last name"
                                                      value={lasttName}
                                                      required
                                                      onChange={(e) => {
                                                          setlastName(e.target.value);
                                                      }}
                                                      style={{
                                                          textTransform: "none",
                                                      }}
                                                  />
                                              </>
                                          )}
                                      </>
                                  )}
                              </div>
                              <button
                                  type="submit"
                                  className="btn-send-request"
                                  disabled={loader}
                                  style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      justifyContent: "center",
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "20px",
                                  }}
                              >
                                  {loader ? <Loader /> : "Continue"}
                              </button>
                          </form>
                      </div>
                  </div>
              </Modal.Body>
          </Modal>
      </>
  );
}

export default AddPaymentRecipient;
