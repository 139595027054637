import Modal from "react-bootstrap/Modal";
import currencys from "../asset/images/GB.svg";
import { currencies } from "../constants/currency";

import checkbox from "../asset/images/Checkbox.svg";
import { Fragment } from "react";
import { SHOW_COUNTRY_FLAG } from "constants/global";
function PayCurrencyModal(props) {
    // console.log(props);
    function checkCurrencyAndRemove() {
        return currencies?.filter((item) => item.title !== props.default);
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="mycenteredlarge"
            style={{
                // transform: "translate(30%, 0%)",
                margin: "0px auto",
                inset: "0% 30%",
            }}
        >
            <Modal.Header closeButton className="border-0">
                <div
                    style={{
                        color: "#667085",
                        fontSize: "12px",
                        lineHeight: "24px",
                        fontWeight: "500",
                        float: "right",
                        width: "100%",
                        maxWidth: "320px",
                        textAlign: "center",
                        textTransform: "uppercase",
                    }}
                >
                    Switch Currency
                </div>
            </Modal.Header>
            <div
                style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#ffff",
                            borderRadius: "8px",
                        }}
                    >
                        {checkCurrencyAndRemove()?.map((wallet, index) => (
                            <Fragment key={index + wallet.title}>
                                <div
                                    className="col"
                                    onClick={() => {
                                        //  props.setdefault(wallet.title);
                                        //  props?.getWallet(wallet.title);
                                        props.setshow(false);
                                        props.setSelectedCurrency(wallet.title);
                                        // sessionStorage.setItem("default", wallet.currency);
                                        //localStorage.setItem("default", wallet.currency);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div
                                        className=""
                                        style={{
                                            padding: "4px",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                padding: "8px",
                                                position: "relative",
                                                background:
                                                    props.default === wallet.title && "#F4F0FF",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <div
                                                className="d-flex"
                                                style={{
                                                    gap: "10px",
                                                    position: "relative",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {" "}
                                                {props?.default}
                                                <img
                                                    src={
                                                        // wallet.title === "NGN"
                                                        //     ? NGNcurrency
                                                        //     : wallet.title === "EUR"
                                                        //     ? currencyss
                                                        //     : wallet.title === "USD"
                                                        //     ? US
                                                        //     : currencys
                                                        SHOW_COUNTRY_FLAG[wallet.title] || currencys
                                                    }
                                                    alt=""
                                                    className="h-[40px]"
                                                />
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div className="d-flex" style={{ gap: "8px" }}>
                                                        <span
                                                            style={{
                                                                color: "#101828",
                                                                lineHeight: "24px",
                                                                fontWeight: "700",
                                                                textAlign: "center",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            {wallet.title}{" "}
                                                            {wallet.title === "EUR"
                                                                ? "Euro"
                                                                : wallet.title === "NGN"
                                                                ? "Naira"
                                                                : wallet.title === "USD"
                                                                ? "Dollar"
                                                                : "Pounds"}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span
                                                            style={{
                                                                color: "#98A2B3",
                                                                lineHeight: "24px",
                                                                fontWeight: "400",
                                                                textAlign: "center",
                                                                letterSpacing: "2%",
                                                            }}
                                                        >
                                                            {wallet.title}
                                                        </span>
                                                    </div>
                                                    {props.default === wallet.title && (
                                                        <img
                                                            src={checkbox}
                                                            alt=""
                                                            style={{
                                                                position: "absolute",
                                                                top: "20px",
                                                                right: "0",
                                                                bottom: "auto",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        {/**
                    <div className="col-md-4 col-lg-4">
                        <div className="border-0 card"
                            style={
                                {padding: '5px'}
                        }>
                            <div className="card-body"
                                style={
                                    {
                                        padding: '8px !important',
                                        position: 'relative'
                                    }
                            }>
                                <div className="d-flex"
                                    style={
                                        {
                                            gap: '10px',
                                            position: 'relative'
                                        }
                                }>
                                    <img src={currencys}
                                        alt=""/>
                                    <div className="d-flex"
                                        style={
                                            {
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }
                                    }>
                                        <div className="d-flex"
                                            style={
                                                {gap: '8px'}
                                        }>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    lineHeight: '24.3px',
                                                    fontWeight: '700px',
                                                    textAlign: 'center'

                                                }
                                            }>US Dollar</span>
                                            <span style={
                                                {
                                                    color: '#98A2B3',
                                                    lineHeight: '24px',
                                                    fontWeight: '500px',
                                                    textAlign: 'center',
                                                    letterSpacing: '2%'

                                                }
                                            }>USD</span>
                                        </div>
                                        <div>
                                            <sub style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>$</sub>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>3 545.</span>
                                            <sub style={
                                                {
                                                    color: '#667085',
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: '19.6px'
                                                }
                                            }>22</sub>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4">
                        <div className="border-0 card"
                            style={
                                {padding: '5px'}
                        }>
                            <div className="card-body"
                                style={
                                    {
                                        padding: '8px !important',
                                        position: 'relative'
                                    }
                            }>
                                <div className="d-flex"
                                    style={
                                        {
                                            gap: '10px',
                                            position: 'relative'
                                        }
                                }>
                                    <img src={currencyss}
                                        alt=""/>
                                    <div className="d-flex"
                                        style={
                                            {
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }
                                    }>
                                        <div className="d-flex"
                                            style={
                                                {gap: '8px'}
                                        }>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    lineHeight: '24.3px',
                                                    fontWeight: '700px',
                                                    textAlign: 'center'

                                                }
                                            }>US Dollar</span>
                                            <span style={
                                                {
                                                    color: '#98A2B3',
                                                    lineHeight: '24px',
                                                    fontWeight: '500px',
                                                    textAlign: 'center',
                                                    letterSpacing: '2%'

                                                }
                                            }>USD</span>
                                        </div>
                                        <div>
                                            <sub style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>$</sub>
                                            <span style={
                                                {
                                                    color: '#101828',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '32.4px'
                                                }
                                            }>3 545.</span>
                                            <sub style={
                                                {
                                                    color: '#667085',
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: '19.6px'
                                                }
                                            }>22</sub>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PayCurrencyModal;
