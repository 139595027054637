import { AddIcon, CloseIcon, DocsFilledIcon, SearchIcon } from "asset/icons";
import Dropdown from "component/form/dropDown";
import FormSteps from "component/form/formSteps";
import Input from "component/form/Input";
import SupportDocs from "component/form/supportDocs";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Index = () => {
    const [addDocs, setAddDocs] = useState(false);
    const [file, setFile] = useState();
    return (
        <div>
            <nav className="h-[72px] !m-0 !px-6 !py-0 flex items-center navbar-gradient">
                <Link to={-1}>
                    <CloseIcon className="*:stroke-[#1D2939] size-[25px] mr-2 -translate-y-[3px]" />
                </Link>

                <h2 className="font-[450] !text-lg text-main-dark !ml-3.5 mr-auto">
                    Create Shipment
                </h2>

                <button className="!text-secondary !mr-8">Save as Draft</button>
                <button
                    className={`text-[#FBFAFF] rounded-lg !px-6 !py-2 font-[450] ${
                        true ? "pointer-events-none !bg-[#D9CDFF]" : "!bg-secondary"
                    }`}
                    onClick={() => {}}
                >
                    Book Shipment
                </button>
            </nav>
            <div className="flex !px-[70px] !pt-11 !mb-20">
                <FormSteps />
                <form onSubmit={(evt) => evt.preventDefault()} className="ml-48 w-[610px]">
                    <h3 className="text-main-dark font-[450] text-2xl !mb-6">
                        Add shipping details
                    </h3>

                    <Input label="Name your shipment" placeholder="TSH-8239" />

                    <div className="grid grid-cols-2 !gap-3.5">
                        <div>
                            <Dropdown
                                // value=""
                                options={[
                                    "20 Foot",
                                    "40 Foot",
                                    "40-Foot High Cube",
                                    "45-Foot High Cube",
                                ].map((option) => ({ label: option, value: option }))}
                                onChange={(value) => ""}
                                label="Container Type"
                                labelClassName="!text-[#475467]"
                                placeholder="Choose container type"
                            />
                        </div>
                        <div>
                            <Dropdown
                                // value=""
                                options={[
                                    "Full Container Load (FCL)",
                                    "Less Container Load (LCL)",
                                    "Full Truckload (FTL)",
                                    "Less Than Truckload (LTL)",
                                ].map((option) => ({ label: option, value: option }))}
                                onChange={(value) => ""}
                                label="Load Type"
                                labelClassName="!text-[#475467]"
                                placeholder="Choose Load type"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-4 !gap-3.5">
                        <div className="col-span-2">
                            <Dropdown
                                // value=""
                                options={[
                                    "Carton/Box",
                                    "Crate",
                                    "Pallet",
                                    "Bag/Sack",
                                    "Bundle",
                                    "Container/Loose",
                                ].map((option) => ({ label: option, value: option }))}
                                onChange={(value) => ""}
                                label="Package Type"
                                labelClassName="!text-[#475467]"
                                placeholder="Choose package type"
                            />
                        </div>

                        <div>
                            <Input label="Total Weight" placeholder="E.g. 100" unit="Kg" />
                        </div>
                        <div>
                            <Input
                                onChange={(value) => ""}
                                label="Total Quantity"
                                placeholder="No of units"
                            />
                        </div>
                    </div>
                    <>
                        <p className="font-medium text-sm text-left text-[#475467] !mb-2 !mt-5">
                            Package Dimensions (Optional)
                        </p>
                        <div className="flex items-center">
                            <Input placeholder="Length" unit="cm" />
                            <span className="font-medium text-[#475467] text-sm !mx-2.5">X</span>
                            <Input placeholder="Width" unit="cm" />
                            <span className="font-medium text-[#475467] text-sm !mx-2.5">X</span>
                            <Input placeholder="Height" unit="cm" />
                        </div>
                    </>

                    <Input
                        label="Describe Items"
                        type="textarea"
                        placeholder="E.g. salmon fishes, bluetooth speakers"
                    />

                    <div className="!mb-5">
                        <p className="font-medium text-sm text-left text-main-dark !mb-2 !mt-[18px]">
                            Add Purchase Orders Dimensions (Optional)
                        </p>
                        <PurchaseOrdersSelect />
                    </div>

                    <SupportDocs
                        addDocs={addDocs}
                        setAddDocs={setAddDocs}
                        file={file}
                        setFile={setFile}
                        showDelete={true}
                    />

                    <div className="flex !gap-9 justify-end !mt-6">
                        <button type="button text-[#1D2939] font-[450] !py-2 !px-6">Back</button>
                        <button
                            className={`!py-2 !px-6 text-white rounded-lg font-[450] ${
                                true ? "bg-[#D9CDFF] pointer-events-none" : "bg-secondary"
                            }`}
                        >
                            Continue
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Index;

const PurchaseOrdersSelect = ({ purchaseOrders }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <p
                className="flex items-center !px-3.5 h-16 bg-white rounded-lg text-main-dark font-[450] cursor-pointer"
                onClick={() => setOpenModal(true)}
            >
                <DocsFilledIcon className="!mr-2" />
                Select Purchase Order
                <AddIcon className="ml-auto" />
            </p>

            {openModal && (
                <div className="w-screen h-screen fixed left-0 top-0 z-[2500] bg-[#1D29394D] grid place-items-center">
                    <div className="bg-white rounded-2xl w-[560px] text-main-dark">
                        <h3 className="flex justify-between items-center !py-4 !px-6 font-semibold text-xl">
                            Choose purchase order{" "}
                            <CloseIcon
                                onClick={() => setOpenModal((p) => !p)}
                                className="size-[25px] cursor-pointer rounded-full *:stroke-main-dark"
                            />
                        </h3>

                        <div className="!px-6">
                            <Input
                                placeholder="Search by item, email or customer"
                                unit={<SearchIcon className="-order-1 !ml-4 !size-6" />}
                                className="!shadow-[inset_0_0_0_1000px_#EAECF0] !pl-3 placeholder:text-base placeholder:font-normal"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
